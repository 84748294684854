import React from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import Conditional from 'components/Conditional/Conditional';
import './styles.scss';

type ConfirmationBackModalProps = {
  open: boolean;
  handleContinue: () => void;
  close: () => void;
};

function ConfirmationBackModal({
  open,
  handleContinue,
  close,
}: ConfirmationBackModalProps) {
  return (
    <Conditional if={open}>
      <Modal show centered dialogClassName="modal-container">
        <Modal.Body>
          <Row className="modal-with-confirmation">
            <Row className="icon p-0">
              <Icon name="triangleWarning" />
            </Row>
            <Row className="text p-0">
              <h2 className="title pe-0">Tem certeza que deseja sair?</h2>
              <h4 className="description pe-0">
                Seus dados não salvos podem ser descartados se você sair
              </h4>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer">
          <Row>
            <Col onClick={close} className="p-0">
              <span className="continue">Sair mesmo assim</span>
            </Col>
            <Col className="p-0">
              <Button onClick={handleContinue} className="cancel">
                Voltar ao formulário
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Conditional>
  );
}

export default ConfirmationBackModal;

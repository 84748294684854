import React, { useContext, useEffect, useState } from 'react';
import DependantService from 'services/dependantsService';
import { useForm, UseFormReturn } from 'react-hook-form';
import AuthContext from 'contexts/auth';
import { updateAdmissionForm } from 'store/user/actions';
import DependantsData from 'components/Forms/Shared/DependantsData';
import {
  checkSaveButtonDisabled,
  formChanged,
} from 'components/Forms/Shared/FormUtils';
import { formStatus } from 'types/common/FormStatus';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Dependants({
  handleNext,
  handlePrevious,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
}) {
  const [disable, setDisable] = useState(true);

  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const formMethods: UseFormReturn<FormModel.Dependants> =
    useForm<FormModel.Dependants>({
      mode: 'onBlur',
      defaultValues: {
        includeDependants: true,
      },
    });

  async function loadDependants(): Promise<void> {
    return DependantService.getDependants().then((response) => {
      setDisable(false);

      const { data, includeDependants, validationResults, validationStatus } =
        response.data;

      formMethods.reset({
        includeDependants,
        dependants:
          data ||
          (includeDependants != null && !includeDependants
            ? []
            : [
                {
                  includedIR: false,
                  CPF: '',
                  dateOfBirth: '',
                  name: '',
                  kinship: '',
                  nameDocument: '',
                  urlDocument: '',
                  id: 0,
                },
              ]),
      });

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.DEPENDANTS,
          status: (validationStatus as formStatus) || 'none',
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );
    });
  }

  useEffect(() => {
    loadDependants();
  }, []);

  function handleDisable() {
    return disable ? true : checkSaveButtonDisabled(formMethods, admissionForm);
  }

  return (
    <DependantsData
      dependantsDataMethods={formMethods}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      handleDisableNext={handleDisable}
      formChanged={formChanged}
    />
  );
}

export default Dependants;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseFormReturn } from 'react-hook-form';
import ProfileEmergencyContactName from '../ProfileEmergencyContactName';
import ProfileEmergencyPhoneNumber from '../ProfileEmergencyPhoneNumber';
import ProfileEmergencyContactKinship from '../ProfileEmergencyContactKinship';
import './styles.scss';

function ProfileEmergencyContact({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  return (
    <div className="profile-emergency-contact">
      <h3 className="title">Informe o seu contato de emergência</h3>
      <Row className="first-row">
        <Col sm={6} xs={12}>
          <ProfileEmergencyContactName profileMethods={profileMethods} />
        </Col>

        <Col sm={3} xs={12}>
          <ProfileEmergencyPhoneNumber profileMethods={profileMethods} />
        </Col>

        <Col sm={3} xs={12}>
          <ProfileEmergencyContactKinship profileMethods={profileMethods} />
        </Col>
      </Row>
    </div>
  );
}

export default ProfileEmergencyContact;

/* eslint-disable import/prefer-default-export */
export const AttachmentsTexts = {
  'pt-BR': {
    'Title RG': 'Carteira de Identidade',
    'Description RG': 'Frente e verso.',
    'Title RNE': 'RNE',
    'Description RNE': '',
    'Title CTPS': 'Carteira de Trabalho',
    'Description CTPS':
      'Página da foto, a página da Qualificação Civil e a página do último registro.',
    'Title ID': 'Carteira de Identidade',
    'Description ID': 'Frente e verso.',
    'Title CPF': 'Cópia do CPF',
    'Description CPF': 'Frente e verso.',
    'Title voteRegistration': 'Título de Eleitor',
    'Description voteRegistration': 'Frente e verso.',
    'Title registrationQualification': 'Consulta de Qualificação Cadastral',
    'Description registrationQualification': 'Anexe a consulta realizada.',
    'Title PIS': 'Comprovante do PIS',
    'Description PIS': 'Extrato emitido pela CEF ou cartão do PIS.',
    'Title address': 'Comprovante de Residência',
    'Description address': 'Que apresente seu CEP.',
    'Title reservist': 'Reservista',
    'Description reservist': 'Ou comprovante de dispensa militar.',
    'Title proofOfSchooling': 'Comprovante de Escolaridade',
    'Description proofOfSchooling': '',
    'Title courses': 'Certificado de Cursos Externos',
    'Description courses': '',
    'Title bankAccount': 'Comprovante de Dados Bancários',
    'Description bankAccount': '',
    'Title certification': 'Certidão de Nascimento ou Casamento',
    'Description certification': '',
    'Title underAgeBirthCertification': 'Certidão do Nascimento',
    'Description underAgeBirthCertification': 'Para filho menor de 14 anos.',
    'Title underAgeVaccineCertification': 'Cartão de Vacinação',
    'Description underAgeVaccineCertification': 'Para filho menor de 7 anos.',
    'Title underAgeSchoolAttendance': 'Comprovante de frequência escolar',
    'Description underAgeSchoolAttendance':
      'Para filho maior de 7 e menor de 14 anos.',
    'Title socialContract': 'Contrato Social ou Requerimento',
    'Description socialContract': '',
    'Title CNPJ': 'Cartão CNPJ',
    'Description CNPJ': '',
    'upload.title': 'Upload de documentos',
    'upload.instruction':
      'Realize o upload dos seus documentos escaneados para sua contratação. Pode adicionar nos formatos PDF, JPG ou PNG.',
    'upload.mandatory': '*Obrigatório',
    'back.button': 'Voltar',
    'save.continue.button': 'Salvar e continuar',
    'Something.went.wrong': 'Algo deu errado',
    'please.try.again': 'Por favor, tente novamente em alguns instantes.',
    'set.modal.data.title':
      'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
    'set.modal.data.modal.title': 'Atenção!',
  },
  en: {},
};

import React, { useState } from 'react';
import './style.scss';
import { Row, Col } from 'react-bootstrap';
import Icon from 'components/Icon';
import Button from 'components/Button';
import DownloadFileService from 'services/downloadFileService';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../GenericModal';

type BankinNoAccountProps = {
  isFormsValid: boolean;
};

function BankingAccountItau({ isFormsValid }: BankinNoAccountProps) {
  const { t } = useTranslation('banking');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    icon: '',
    title: '',
    description: '',
    modalTitle: 'Carta de Abertura',
  });

  function downloadBankLetter() {
    setOpen(true);
    setLoading(true);
    setData({
      icon: '',
      title: 'Realizando o download...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    DownloadFileService.downloadBankLetter()
      .then((response) => {
        setLoading(false);
        setData({
          icon: 'checkboxMarkIcon',
          title: 'Download disponibilizado com sucesso.',
          description: '',
          modalTitle: 'Sucesso na operação!',
        });

        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });

        const downloadLink = document.createElement('a');
        downloadLink.download = 'carta-banco.pdf';
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.onclick = () => document.body.removeChild(downloadLink);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
      .catch(() => {
        setLoading(false);
        setData({
          icon: 'cancelIconSmaller',
          title: 'Algo deu errado',
          description: 'Tente fazer o download novamente.',
          modalTitle: 'Falha na operação!',
        });
      });
  }

  return (
    <Row className="attention no-account">
      <Col xs={1} sm={1} className="icon mb-auto">
        <Icon name="attentionOrange" />
      </Col>
      <Col xs={11} sm={8} className="text">
        {isFormsValid ? (
          <span>{t('Account Letter Enabled')}</span>
        ) : (
          <span>{t('Account Letter Disabled')}</span>
        )}
      </Col>
      <Col xs={12} sm={3}>
        <Button
          className="float-end"
          disabled={!isFormsValid}
          onClick={() => downloadBankLetter()}
        >
          Download
          <Icon name="downloadIconWhite" />
        </Button>
      </Col>

      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={open}
        data={data}
        loading={loading}
        modalType="savingData"
        handleConfirm={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
        close={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
      />
    </Row>
  );
}

export default BankingAccountItau;

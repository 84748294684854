import { StoreContractType } from 'types/common/ContractType';
import { LoggedUser } from 'types/common/loggedUser';
import { SaveCredentials } from 'types/common/SaveCredentials';
import { StageAdmissionSteps } from 'types/common/StageAdmissionSteps';
import { UserSteps } from 'types/common/UserSteps';
import { AdmissionFormConfig, StoreAction, types } from './types';

export const login = (data: LoggedUser): StoreAction => ({
  type: types.LOGIN,
  payload: data,
});

export const credentials = (data: SaveCredentials): StoreAction => ({
  type: types.SAVE_CREDENTIALS,
  payload: data,
});

export const steps = (data: UserSteps): StoreAction => ({
  type: types.UPDATE_STEPS,
  payload: data,
});

export const contract = (data: StoreContractType): StoreAction => ({
  type: types.SAVE_CONTRACT_TYPE,
  payload: data,
});

export const medicalStep = (data: boolean): StoreAction => ({
  type: types.APPROVE_MEDICAL_STEP,
  payload: data,
});

export const name = (data: string): StoreAction => ({
  type: types.SAVE_NAME,
  payload: data,
});

export const stageAdmission = (data: StageAdmissionSteps[]): StoreAction => ({
  type: types.STAGE_ADMISSION,
  payload: data,
});

export const updateAdmissionForm = (
  data: AdmissionFormConfig
): StoreAction => ({
  type: types.FORM_ADMISSION,
  payload: data,
});

import React, { useContext, useState, useEffect } from 'react';
import {
  contract,
  name,
  medicalStep,
  steps as saveSteps,
} from 'store/user/actions';
import StepsController from 'components/StateController/StepsController';
import AdmissionService from 'services/admissionService';
import defaultSteps from 'constants/defaultStages';
import { ContractType } from 'types/common/ContractType';
import AuthContext from 'contexts/auth';
import './style.scss';
import HomeInfo from 'components/Home/HomeInfo';
import HomeProgress from 'components/Home/HomeProgress';
import HomeCards from 'components/Home/HomeCards';
import GenericModal from 'components/Forms/Shared/GenericModal';

function Home() {
  const stepsController = StepsController();
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [daysLeft, setDaysLeft] = useState(-1);
  const [isProfileFormFilled, setIsProfileFormFilled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const modalData = {
    icon: 'greenCheckCircle',
    title: 'Muito bem! A primeira fase foi concluída.',
    description:
      'Seus dados serão validados pelo nosso time e, em breve, você receberá os próximos passos.',
  };

  useEffect(() => {
    setLoading(true);
    AdmissionService.currentAdmission()
      .then((res) => {
        const { data } = res;

        if (data.approvedMedicalStep) {
          dispatch(medicalStep(data.approvedMedicalStep));
        }

        dispatch(name(data.name));

        if (data.contractType) {
          dispatch(contract(data.contractType));

          stepsController.dispatch(
            saveSteps(defaultSteps[data.contractType as ContractType])
          );
        }

        if (data.daysRemaining >= 0) {
          setDaysLeft(data.daysRemaining);
        }

        setIsProfileFormFilled(data.isProfileFormFilled);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="home-page">
      {loading ? (
        <div />
      ) : (
        <>
          <HomeInfo
            daysLeft={daysLeft}
            isProfileFormFilled={isProfileFormFilled}
            preventNavigation={setIsOpen}
          />
          <HomeProgress preventNavigation={setIsOpen} />
          <HomeCards />

          <GenericModal
            modalType="confirmation"
            data={modalData}
            showHeader={false}
            open={isOpen}
            close={() => {
              setIsOpen(false);
            }}
            handleConfirm={() => {
              setIsOpen(false);
            }}
          />
        </>
      )}
    </div>
  );
}

export default Home;

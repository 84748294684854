import React from 'react';
import Checkbox from 'components/Checkbox';
import Dependant from 'types/models/Dependant';
import { Table } from 'react-bootstrap';
import './style.scss';

export type BenefitsContainerProps = {
  dependants: Dependant[];
  checkDependantBenefit: (checked: boolean, dependantId: number) => void;
  checkedDependants: Array<number>;
};
function BenefitDependants({
  dependants,
  checkDependantBenefit,
  checkedDependants,
}: BenefitsContainerProps) {
  return (
    <div>
      {dependants && dependants.length !== 0 && (
        <>
          <h3 className="title-dependants col-12">Incluir Dependentes?</h3>
          <Table hover className="dependants-table">
            <tbody>
              {dependants.map(({ name, id }: Dependant) => (
                <tr key={id} className="dependant-row">
                  <td className="checkbox-cell" width="5%">
                    <Checkbox
                      isChecked={checkedDependants.includes(id)}
                      checkedValue={(checked: boolean) =>
                        checkDependantBenefit(checked, id)
                      }
                    />
                  </td>
                  <td className="name-cell">{name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

export default BenefitDependants;

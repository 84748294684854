import React from 'react';
import { Link } from 'react-router-dom';
import { BaseProps } from './ButtonProps';
import './style.scss';

export type ButtonLinkProps = JSX.IntrinsicElements['button'] &
  BaseProps & {
    url?: string;
    componentType?: 'button' | 'link';
    disabled?: boolean;
    loading?: boolean;
  };

function ButtonLink({
  url = '',
  outline = false,
  floating = false,
  code = false,
  block = false,
  onClick = () => {},
  componentType = 'button',
  disabled = false,
  children,
  loading = false,
  ...nativeProps
}: ButtonLinkProps) {
  const classes = `app-button${outline ? ' outline' : ''}${
    floating ? ' floating' : ''
  }${block ? ' block' : ''}${
    nativeProps.className ? ` ${nativeProps.className}` : ''
  }${code ? ' code' : ''}`;

  const renderLinkButton = () => {
    switch (componentType) {
      case 'button':
        return (
          <>
            {loading && (
              <button
                className={classes}
                type="button"
                disabled={disabled}
                onClick={onClick}
                id={nativeProps.id}
              >
                {children}

                <div className="load-container-button">
                  <div className="load-image-button" />
                </div>
              </button>
            )}

            {!loading && (
              <button
                className={classes}
                type="button"
                disabled={disabled}
                onClick={onClick}
                id={nativeProps.id}
              >
                {children}
              </button>
            )}
          </>
        );
      case 'link':
        return (
          <Link
            to={url}
            className={classes}
            onClick={onClick}
            id={nativeProps.id}
          >
            {children}
          </Link>
        );
      default:
        return <span />;
    }
  };

  return renderLinkButton();
}

export default ButtonLink;

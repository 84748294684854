/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  CUSTOM_ERROR_MESSAGE,
  getFormValidationProps,
} from 'components/Forms/Shared/FormUtils';
import AuthContext from 'contexts/auth';
import StepsController from 'components/StateController/StepsController';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

function ProfileCampus({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const stepsController = StepsController();
  const { errors } = profileMethods.formState;
  const maxLength = 50;

  const campusFormValidationProps = getFormValidationProps(
    'campus',
    admissionForm,
    profileMethods
  );

  return (
    <label id="campus" className="form-field" htmlFor="campus">
      <span className="label">Campus*</span>
      <Controller
        control={profileMethods.control}
        name="campus"
        rules={{ required: true, maxLength }}
        render={({ field: { onChange, onBlur } }) => (
          <input
            type="text"
            onBlur={onBlur}
            className={
              campusFormValidationProps.hasAnyError
                ? 'field -full-error'
                : 'field -full'
            }
            onChange={(e) => onChange(e.target.value)}
            value={profileMethods.getValues('campus') || ''}
            disabled={campusFormValidationProps.shouldDisable}
          />
        )}
      />
      {errors && errors.campus?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {errors && errors.campus?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {campusFormValidationProps.hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileCampus;

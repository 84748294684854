/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import FormAnswerType from 'types/enum/FormAnswerType';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../../FormUtils';
import ValidationUtils from '../../ValidationUtils';
import BankingSelect from './BankingSelect';
import './style.scss';

function BankingAccount({
  bankingDataMethods,
  type,
}: {
  bankingDataMethods: UseFormReturn<any>;
  type: FormAnswerType;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = bankingDataMethods.formState;
  const maxLength = { agency: 10, digit: 3 };

  const agencyFormValidationProps = getFormValidationProps(
    'agency',
    admissionForm,
    bankingDataMethods
  );

  const accountFormValidationProps = getFormValidationProps(
    'account',
    admissionForm,
    bankingDataMethods
  );

  const digitFormValidationProps = getFormValidationProps(
    'digit',
    admissionForm,
    bankingDataMethods
  );

  return (
    <div className="banking-account">
      <Row>
        <Col xs={12} sm={6}>
          <BankingSelect
            isDisabled={agencyFormValidationProps.shouldDisable}
            type={type}
            bankingDataMethods={bankingDataMethods}
          />
        </Col>
        {type === 'BANKING_PJ' && (
          <>
            <Col xs={12} sm={2}>
              <label id="agency" htmlFor="agency" className="form-field">
                <span className="label">Agência*</span>
                <Controller
                  control={bankingDataMethods.control}
                  name="agency"
                  rules={{ required: true, maxLength: maxLength.agency }}
                  render={({ field: { onChange, onBlur, name } }) => (
                    <input
                      type="text"
                      onBlur={onBlur}
                      className={ValidationUtils.errorClass(
                        errors,
                        agencyFormValidationProps.hasAnyError
                      )}
                      onChange={(e) =>
                        onChange(
                          masks.numberAndLettersAndHyphen(e.target.value)
                        )
                      }
                      value={bankingDataMethods.getValues('agency')}
                      disabled={agencyFormValidationProps.shouldDisable}
                    />
                  )}
                />
                {ValidationUtils.errorViewValidator(errors, errors.agency)}
                {errors && errors.agency?.type === 'maxLength' && (
                  <span className="error-text">
                    Precisa ter até {maxLength.agency} caracteres.
                  </span>
                )}
                {ValidationUtils.errorCustomValidator(
                  agencyFormValidationProps.hasCustomError
                )}
              </label>
            </Col>

            <Col xs={12} sm={2}>
              <label id="account" htmlFor="account" className="form-field">
                <span className="label">Conta Corrente*</span>
                <Controller
                  control={bankingDataMethods.control}
                  name="account"
                  rules={{ required: true, maxLength: maxLength.agency }}
                  render={({ field: { onChange, onBlur } }) => (
                    <input
                      type="text"
                      onBlur={onBlur}
                      className={ValidationUtils.errorClass(
                        errors,
                        accountFormValidationProps.hasAnyError
                      )}
                      onChange={(e) =>
                        onChange(
                          masks.numberAndLettersAndHyphen(e.target.value)
                        )
                      }
                      value={bankingDataMethods.getValues('account') || ''}
                      disabled={accountFormValidationProps.shouldDisable}
                    />
                  )}
                />
                {ValidationUtils.errorViewValidator(errors, errors.account)}
                {errors && errors.account?.type === 'maxLength' && (
                  <span className="error-text">
                    Precisa ter até {maxLength.agency} caracteres.
                  </span>
                )}
                {ValidationUtils.errorCustomValidator(
                  accountFormValidationProps.hasCustomError
                )}
              </label>
            </Col>
          </>
        )}

        {type === 'BANKING_INTERNSHIP' && (
          <>
            <Col xs={12} sm={2}>
              <label id="agency" htmlFor="agency" className="form-field">
                <span className="label">Agência</span>
                <Controller
                  control={bankingDataMethods.control}
                  name="agency"
                  rules={{ maxLength: maxLength.agency }}
                  render={({ field: { onChange, onBlur, name } }) => (
                    <input
                      type="text"
                      onBlur={onBlur}
                      className={ValidationUtils.errorClass(
                        errors,
                        agencyFormValidationProps.hasAnyError
                      )}
                      onChange={(e) =>
                        onChange(
                          masks.numberAndLettersAndHyphen(e.target.value)
                        )
                      }
                      value={bankingDataMethods.getValues('agency') || ''}
                      disabled={agencyFormValidationProps.shouldDisable}
                    />
                  )}
                />
                {errors && errors.agency?.type === 'maxLength' && (
                  <span className="error-text">
                    Precisa ter até {maxLength.agency} caracteres.
                  </span>
                )}
                {ValidationUtils.errorCustomValidator(
                  agencyFormValidationProps.hasCustomError
                )}
              </label>
            </Col>

            <Col xs={12} sm={2}>
              <label id="account" htmlFor="account" className="form-field">
                <span className="label">Conta Corrente</span>
                <Controller
                  control={bankingDataMethods.control}
                  name="account"
                  rules={{ maxLength: maxLength.agency }}
                  render={({ field: { onChange, onBlur } }) => (
                    <input
                      type="text"
                      onBlur={onBlur}
                      className={ValidationUtils.errorClass(
                        errors,
                        accountFormValidationProps.hasAnyError
                      )}
                      onChange={(e) =>
                        onChange(
                          masks.numberAndLettersAndHyphen(e.target.value)
                        )
                      }
                      value={bankingDataMethods.getValues('account') || ''}
                      disabled={accountFormValidationProps.shouldDisable}
                    />
                  )}
                />
                {errors && errors.account?.type === 'maxLength' && (
                  <span className="error-text">
                    Precisa ter até {maxLength.agency} caracteres.
                  </span>
                )}
                {ValidationUtils.errorCustomValidator(
                  accountFormValidationProps.hasCustomError
                )}
              </label>
            </Col>
          </>
        )}

        <Col xs={12} sm={2}>
          <label id="digit" htmlFor="digit" className="form-field">
            <span className="label">Dígito</span>
            <Controller
              control={bankingDataMethods.control}
              name="digit"
              rules={{ maxLength: maxLength.digit }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={
                    digitFormValidationProps.hasAnyError
                      ? 'field -full-error'
                      : 'field -full'
                  }
                  onChange={(e) => onChange(e.target.value)}
                  value={bankingDataMethods.getValues('digit') || ''}
                  disabled={digitFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.digit?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.digit} caracteres.
              </span>
            )}
            {ValidationUtils.errorCustomValidator(
              digitFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
    </div>
  );
}

export default BankingAccount;

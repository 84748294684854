import api from 'configs/api';
import DependantsAnswersResponse, {
  DependantsAnswersRequest,
} from 'types/models/DependantsAnswersResponse';

async function saveDependants(dependants: DependantsAnswersRequest) {
  const request = {
    dependants: dependants.dependants,
    includeDependants: dependants.includeDependants,
  };

  return api.post('/dependant', request);
}

async function removeAllDependants() {
  return api.delete('/dependant');
}

async function removeDependantById(id: number) {
  return api.delete(`/dependant/${id}`);
}

async function getDependants() {
  return api.get<DependantsAnswersResponse>('/dependant');
}

async function removeDocument(id: number) {
  return api.delete(`/dependant/document/${id}`);
}

const DependantService = {
  saveDependants,
  removeAllDependants,
  removeDependantById,
  getDependants,
  removeDocument,
};
export default DependantService;

/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

const civilStatus = [
  { value: 'Solteiro', label: 'Solteiro' },
  { value: 'Casado', label: 'Casado' },
  { value: 'Divorciado', label: 'Divorciado' },
  { value: 'União Estável', label: 'União Estável' },
  { value: 'Viúvo', label: 'Viúvo' },
  { value: 'Separado Judicialmente', label: 'Separado Judicialmente' },
];

function ProfileCivilStatus({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'civilStatus',
    admissionForm,
    profileMethods
  );

  return (
    <label id="civilStatus" className="form-field" htmlFor="civilStatus">
      <span className="label">Estado Civil*</span>
      <Controller
        name="civilStatus"
        control={profileMethods.control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name, ref, onBlur } }) => (
          <Select
            placeholder=""
            styles={customStyles(hasAnyError, shouldDisable)}
            options={civilStatus}
            name={name}
            value={civilStatus.find((r) => r.value === value)}
            onChange={(selectedOption: any) => {
              onChange(selectedOption.value);
            }}
            onBlur={onBlur}
            isSearchable={!shouldDisable}
            menuIsOpen={shouldDisable ? false : undefined}
          />
        )}
      />
      {errors && errors.civilStatus?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileCivilStatus;

import React, { useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import AuthContext from 'contexts/auth';
import { updateAdmissionForm } from 'store/user/actions';
import FormAnswerType from 'types/enum/FormAnswerType';
import DocumentAttachment from 'components/Forms/Shared/DocumentAttachment';
import { checkFinishButtonDisabled } from 'components/Forms/Shared/FormUtils';
import AttachmentPJAnswersResponse from 'types/models/AttachmentPJAnswersResponse';
import { formStatus } from 'types/common/FormStatus';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Attachments({
  handleConfirm,
  handlePrevious,
}: {
  handleConfirm: () => void;
  handlePrevious: () => void;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  const formMethods: UseFormReturn<FormModel.DocumentAttachment> = useForm<
    FormModel.DocumentAttachment,
    any
  >({
    mode: 'onChange',
  });

  async function loadAttachmentPJ(): Promise<void> {
    return FormAnswerService.getAllAnswers<AttachmentPJAnswersResponse>(
      FormAnswerType.ATTACHMENT_PJ
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        const EMPTY_DOC = [{ name: '', url: '' }];

        formMethods.reset({
          ID: data.ID || [...EMPTY_DOC],
          CPF: data.CPF || [...EMPTY_DOC],
          address: data.address || [...EMPTY_DOC],
          socialContract: data.socialContract || [...EMPTY_DOC],
          CNPJ: data.CNPJ || [...EMPTY_DOC],
          bankAccount: data.bankAccount || [...EMPTY_DOC],
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.ATTACHMENTS,
          status: data ? (validationStatus as formStatus) : 'none',
          isEditing: !!data,
          validationResults: validationResults || [],
          modifiedAttachments: [],
        })
      );

      setLoading(false);
    });
  }

  function handleDisable() {
    return checkFinishButtonDisabled(formMethods, admissionForm) || loading;
  }

  useEffect(() => {
    loadAttachmentPJ();
  }, []);

  return (
    <DocumentAttachment
      type="PJ"
      formAnswerType={FormAnswerType.ATTACHMENT_PJ}
      documentAttachmentMethods={formMethods}
      handlePrevious={handlePrevious}
      handleConfirm={handleConfirm}
      handleDisableConfirm={handleDisable}
    />
  );
}

export default Attachments;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import l from 'lodash';
import BenefitsConfirmation from 'components/Benefits/BenefitsConfirmation';
import BenefitsContainer from 'components/Benefits/BenefitsContainer/BenefitsContainer';
import StepsController from 'components/StateController/StepsController';
import AppModal from 'components/AppModal';
import masks from 'utils/MasksUtils';
import Benefit from 'types/models/Benefit';
import BenefitsService from 'services/benefitsService';
import Dependant from 'types/models/Dependant';
import BenefitsErrorsType from 'types/enum/BenefitsErrorsType';
import BenefitConfirmation from 'types/models/BenefitConfirmation';
import ValidateBenefits from 'types/models/ValidateBenefits';
import ButtonLink from 'components/Button/ButtonLink';
import AuthContext from 'contexts/auth';
import StageAdmissionStatus from 'types/enum/StageAdmissionStatus';
import StageAdmissionType from 'types/enum/StageAdmissionType';
import WarningButton from 'components/Button/WarningButton';
import FormSteps from 'components/Forms/Shared/FormSteps';
import { BenefitsSteps } from 'constants/texts/BenefitsSteps';
import GenericModal from 'components/Forms/Shared/GenericModal';
import StepsHeader from 'components/Header/StepsHeader';
import { StrMap, Nullable } from 'types/utils';
import './styles.scss';

export type DynamicBenefit = {
  value?: number;
  idBenefitOption?: number;
  idDependants?: Array<number>;
  idBenefit: number;
};

export type SelectedBenefitsResponse = {
  allocatedAmount: number;
  idBenefit: number;
  idBenefitOption: number;
  idDependants: Array<number>;
};

function Benefits() {
  const navigate = useNavigate();
  const stepsController = StepsController();
  const [open, setOpen] = useState(false);
  const [locked, setLocked] = useState(false);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [dependants, setDependants] = useState<Dependant[]>([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [chosenValue, setChosenValue] = useState(-500);
  const [dynamicBenefits, setDynamicBenefits] = useState<StrMap<Benefit>>({});
  const [dynamicValues, setDynamicValues] = useState<StrMap<DynamicBenefit>>(
    {}
  );
  const [snapshot, setSnapshot] =
    useState<Nullable<StrMap<DynamicBenefit>>>(null);

  function updateDynamicValues(v: DynamicBenefit) {
    setDynamicValues({ ...dynamicValues, [v.idBenefit]: { ...v } });
  }
  const [modalData, setModalData] = useState({
    modalTitle: 'Atenção!',
    icon: 'triangleWarning',
    title: '',
    description: '',
  });

  const [openModalError, setOpenModalError] = useState(false);
  const [benefitConfirmation, setBenefitConfirmation] =
    useState<BenefitConfirmation>({
      benefits: [
        {
          name: '',
          idBenefit: 0,
          amountTotal: 0,
        },
      ],
      payrollDiscount: 0,
    });
  const spentValue = (valueChosen: number) => {
    setChosenValue(valueChosen);
  };
  const [loadingValidate, setLoadingValidate] = useState(false);
  const { state } = useContext(AuthContext);

  function showWarningButton() {
    const stage = state.stageAdmission.find(
      (e) => e.name === 'BENEFITS' && e.status === 'ERROR'
    );

    const text = stage?.observation;

    return text || '';
  }

  const updateSelected = (benefit: Benefit) => {
    if (locked) return;

    setBenefits((savedBenefits) =>
      savedBenefits.map((b) => {
        if (b.idBenefit === benefit.idBenefit) {
          return {
            ...b,
            selected: !b.selected,
          };
        }
        return b;
      })
    );

    updateDynamicValues(createDynamicBenefit(benefit));
  };

  const createDynamicBenefit = (benefit: Benefit) => {
    const { idBenefit } = benefit;
    const current = benefit.selected;

    const dynamicBenefit: DynamicBenefit = {
      idBenefit,
    };

    if (!current) {
      if (benefit.flagBenefitValue) {
        dynamicBenefit.value = 0;
      }

      if (benefit.flagBenefitOption) {
        dynamicBenefit.idBenefitOption =
          benefit.options[0]?.idBenefitOption || 0;
        dynamicBenefit.idDependants = [];
      }
    } else {
      if (benefit.flagBenefitValue) {
        dynamicBenefit.value = 0;
      }

      if (benefit.flagBenefitOption) {
        dynamicBenefit.idBenefitOption = -1;
        dynamicBenefit.idDependants = [];
      }
    }

    return dynamicBenefit;
  };

  const isValidBenefit = (benefit: DynamicBenefit) => {
    if (benefit.idBenefitOption != null) {
      return benefit.idBenefitOption !== -1;
    }

    if (benefit.value != null) {
      return benefit.value > 0;
    }

    return false;
  };

  const isSameSelection = (
    obj1: Nullable<StrMap<DynamicBenefit>>,
    obj2: Nullable<StrMap<DynamicBenefit>>
  ) => {
    if (!obj1 || !obj2) return false;

    const cleanObj1 = l.pickBy(obj1, (v) => isValidBenefit(v));
    const cleanObj2 = l.pickBy(obj2, (v) => isValidBenefit(v));

    return l.isEqual(cleanObj1, cleanObj2);
  };

  const shouldDisable = () =>
    loadingValidate || isSameSelection(snapshot, dynamicValues);

  async function handleValidate() {
    if (locked) {
      navigate('/mais-beneficios');
      return;
    }

    const requestsValidate: ValidateBenefits[] = [];
    Object.keys(dynamicValues).forEach((k) => {
      let request: ValidateBenefits;
      if (dynamicValues[k].value && dynamicValues[k].value !== 0) {
        const amount = (dynamicValues[k].value || 0) / 100;
        request = { idBenefit: dynamicValues[k].idBenefit, amount };
        requestsValidate.push(request);
      }
      if (
        dynamicValues[k].idBenefitOption &&
        dynamicValues[k].idBenefitOption !== -1
      ) {
        request = {
          idBenefit: dynamicValues[k].idBenefit,
          idBenefitOption: dynamicValues[k].idBenefitOption,
        };

        if ((dynamicValues[k].idDependants || []).length > 0)
          request = {
            ...request,
            idsDependants: dynamicValues[k].idDependants,
          };
        requestsValidate.push(request);
      }
    });

    setLoadingValidate(true);
    await BenefitsService.validateBenefits({
      benefits: requestsValidate,
    })
      .then((response) => {
        setBenefitConfirmation(response.data);
        setOpen(true);
      })
      .catch((err) => {
        const errorCatch = err.response.data.errors[0];

        const validationErrorCodes: StrMap<string> = {
          913: BenefitsErrorsType.TOTAL_AMOUNT_BASKET_GREATER_THAN_ALLOWED_WITH_DEPENDANTS,
          919: BenefitsErrorsType.MINIMUM_BENEFIT_AMOUNT_LESS_THAN_UNION_MINIMUM_AMOUNT,
          921: BenefitsErrorsType.TOTAL_AMOUNT_BASKET_GREATER_THAN_ALLOWED,
          924: BenefitsErrorsType.TOTAL_AMOUNT_BASKET_LESS_THAN_ALLOWED,
        };

        const errorMessage =
          validationErrorCodes[errorCatch.code] ||
          BenefitsErrorsType.GENERIC_ERROR;

        setOpenModalError(true);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            errorMessage ||
            'Algo deu errado ao tentar validar os benefícios selecionados.',
        });
      })
      .finally(() => setLoadingValidate(false));
  }

  const confirmButton = async () => {
    setLoadingConfirmation(true);
    await BenefitsService.sendBenefits({
      benefits: benefitConfirmation.benefits.map(({ name, ...rest }) => rest),
      payrollDiscount: benefitConfirmation.payrollDiscount,
    })
      .then(() => {
        stepsController.dispatch(
          stepsController.updateStageAdmissionStatus(
            StageAdmissionType.BENEFITS,
            StageAdmissionStatus.COMPLETE
          )
        );
        setLoadingConfirmation(false);
        navigate('/mais-beneficios');
      })
      .catch(() => {
        setOpenModalError(true);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([BenefitsService.getCurrentBenefit()])
      .then((response) => {
        const [{ data }] = response;

        const formatted = data.benefits.reduce(
          (acc: StrMap<Benefit>, b: Benefit) => ({
            ...acc,
            [b.idBenefit]: { ...b },
          }),
          {}
        );

        data.benefits
          .filter((b: Benefit) => b.mandatory)
          .forEach((b: Benefit) => {
            updateDynamicValues(createDynamicBenefit(b));
          });

        const selected: Record<number, boolean> = {};
        if (data.payrollDiscount != null && data.selectedBenefits) {
          const selectedFromResponse: StrMap<DynamicBenefit> = {};

          data.selectedBenefits.forEach((b: SelectedBenefitsResponse) => {
            const dynamicBenefit: DynamicBenefit = {
              idBenefit: b.idBenefit,
            };

            if (b.idBenefitOption) {
              dynamicBenefit.idBenefitOption = b.idBenefitOption;
              dynamicBenefit.idDependants = b.idDependants
                ? [...b.idDependants]
                : [];
            } else {
              dynamicBenefit.value = b.allocatedAmount * 100;
            }

            selectedFromResponse[b.idBenefit] = dynamicBenefit;
            selected[b.idBenefit] = true;
          });

          setDynamicValues(selectedFromResponse);
          const stageAdmissionBenefit = state.stageAdmission.find(
            (element) => element.name === StageAdmissionType.BENEFITS
          );

          if (stageAdmissionBenefit?.status !== StageAdmissionStatus.ERROR) {
            setLocked(true);
          } else {
            setSnapshot(selectedFromResponse);
          }
        }

        setDynamicBenefits(formatted);

        setBenefits(
          data.benefits.map((b: Benefit) => ({
            ...b,
            selected: selected[b.idBenefit] || !!b.mandatory,
          }))
        );
        setDependants(data.dependants || []);
        setValue(data.value);
      })
      .finally(() => setLoading(false));
  }, []);

  const calculateBasket = () => {
    let sum = 0;
    Object.keys(dynamicBenefits).forEach((k) => {
      const benefit = dynamicValues[k];
      if (dynamicBenefits[k].flagBenefitValue && benefit) {
        sum += benefit.value || 0;
      }
      if (dynamicBenefits[k].flagBenefitOption && benefit) {
        const options = dynamicBenefits[k].options.filter(
          (o) => o.idBenefitOption === benefit.idBenefitOption
        );
        const valuePerDependant = options.map((x) => x.valueDependant)[0] || 0;
        const valueBeneficiary = options.map((x) => x.valueBeneficiary)[0] || 0;
        benefit.idDependants?.forEach((_) => {
          sum += valuePerDependant * 100;
        });

        sum += valueBeneficiary * 100;
      }
    });

    return (value - sum / 100) * 100;
  };

  if (loading) {
    return <div />;
  }

  return (
    <div className="benefits-main-container">
      <div className="steps-benetifs">
        <StepsHeader
          route={state.approvedMedicalStep ? 'exam' : ''}
          type="benefits"
        />
        <FormSteps steps={BenefitsSteps} active="chooseBenefits" />
      </div>
      {!loading && (
        <div className="benefits-form">
          <Row>
            <Col className="benefit-total">
              <div
                className={`benefits-value ${
                  calculateBasket() < 0 ? 'benefits-value-error' : ''
                }`}
              >
                <span>
                  Valor disponível: {masks.currency(calculateBasket())}
                </span>
              </div>
            </Col>
          </Row>
          <BenefitsContainer
            selectedValues={dynamicValues}
            updateDynamic={updateDynamicValues}
            updatedValue={calculateBasket()}
            value={value}
            benefits={benefits}
            dependants={dependants}
            selectBenefit={updateSelected}
            lockBenefits={locked}
          />
          <section className="page-footer">
            <ButtonLink
              disabled={shouldDisable()}
              componentType="button"
              className="ms-auto mt-4 d-flex benefit-button"
              onClick={() => {
                handleValidate();
              }}
              loading={loadingValidate}
            >
              Próximo
            </ButtonLink>
          </section>
          <AppModal
            disableConfirm={loadingConfirmation}
            title="Confirmação da Carteira de Benefícios"
            open={open}
            className="modal-benefit"
            close={() => {
              setOpen(false);
            }}
            confirm={confirmButton}
          >
            <BenefitsConfirmation
              spentValue={spentValue}
              defaultValue={value}
              benefitConfirmation={benefitConfirmation}
            />
          </AppModal>

          <GenericModal
            modalType="confirmation"
            data={modalData}
            open={openModalError}
            close={() => {
              setOpenModalError(false);
            }}
            handleConfirm={() => {
              setOpenModalError(false);
            }}
          />
        </div>
      )}
      {showWarningButton().length > 0 && (
        <WarningButton step="2" observationText={showWarningButton()} />
      )}
    </div>
  );
}

export default Benefits;

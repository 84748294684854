import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'components/Button';
import MultipleDocument from 'components/Dropzone/MultipleDocument/MultipleDocument';
import AuthContext from 'contexts/auth';
import { ContractType } from 'types/common/ContractType';
import FormAnswerType from 'types/enum/FormAnswerType';
import DocumentsAttachmentsFields from 'types/models/DocumentsAttachmentsFields';
import { isErrorStatus, isNoneStatus } from '../FormUtils';
import GenericModal from '../GenericModal';
import './styles.scss';

function getAttachmentFields(contract: ContractType) {
  const formMap: Record<ContractType, any> = {
    CLT: DocumentsAttachmentsFields.CLT,
    PJ: DocumentsAttachmentsFields.PJ,
    FOREIGN_CLT: DocumentsAttachmentsFields.FOREIGN_CLT,
    INTERNSHIP: DocumentsAttachmentsFields.INTERNSHIP,
  };
  return formMap[contract];
}

function DocumentAttachment({
  documentAttachmentMethods,
  type,
  formAnswerType,
  handleConfirm,
  handlePrevious,
  handleDisableConfirm,
}: {
  documentAttachmentMethods: UseFormReturn<any>;
  type: ContractType;
  formAnswerType: FormAnswerType;
  handleConfirm: () => void;
  handlePrevious: () => void;
  handleDisableConfirm: () => boolean;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);
  const { t } = useTranslation('attachments');
  const formFields = getAttachmentFields(type);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: t('Something.went.wrong'),
    description: t('please.try.again'),
    modalTitle: '',
  });

  const handleButton = () => {
    if (
      isErrorStatus(admissionForm.status) ||
      isNoneStatus(admissionForm.status)
    ) {
      setOpenModal(true);
      setModalData({
        icon: 'triangleWarning',
        title: t('set.modal.data.title'),
        description: '',
        modalTitle: t('set.modal.data.modal.title'),
      });
    } else {
      documentAttachmentMethods.handleSubmit(handleConfirm)();
    }
  };

  return (
    <Container className="attachment-container">
      <Row className="header-form-info">
        <Col>
          <h1>{t('upload.title')}</h1>
          <p>
            {`${t('upload.instruction')} `} <span>{t('upload.mandatory')}</span>
          </p>
        </Col>
      </Row>

      {formFields.map((field: any, idx: number) => (
        <Row className="file-container" key={idx.valueOf()}>
          <Col xs={12} sm={6}>
            <div className="texts">
              <span className="title">
                {t(`Title ${field.name}`)}
                {field.required ? ' *' : ''}
              </span>
              <span className="subtitle">{t(`Description ${field.name}`)}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <MultipleDocument
                documentPlaceholderText={t(`Title ${field.name}`)}
                fieldName={field.name}
                documentAttachmentMethods={documentAttachmentMethods}
                formAnswerType={formAnswerType}
                isRequired={field.required}
                loading={loading}
                setLoading={setLoading}
              />
            </Row>
          </Col>
        </Row>
      ))}

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <Button onClick={handlePrevious}>{t('back.button')}</Button>
          <Button
            disabled={handleDisableConfirm()}
            className="ms-3"
            onClick={handleButton}
          >
            {t('save.continue.button')}
          </Button>
        </Col>
      </Row>

      <GenericModal
        open={openModal}
        data={modalData}
        modalType="savingData"
        handleConfirm={() => {
          setOpenModal(false);
          documentAttachmentMethods.handleSubmit(handleConfirm)();
        }}
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default DocumentAttachment;

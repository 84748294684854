import React, { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import Icon from 'components/Icon';
import LoginService from 'services/loginService';
import CodeCard from 'components/LoginCard/Code';
import EmailCard from 'components/LoginCard/Email';
import StorageTokens from 'constants/storage';
import AuthContext from 'contexts/auth';
import { login as Logged } from 'store/user/actions';
import Storage from 'services/storage';
import { httpStatus } from 'constants/httpStatus';
import PrivacyPolicyModal from 'components/Forms/Shared/PrivacyPolicyModal';
import './style.scss';

function Login() {
  const [errors, setErrors] = useState({ login: '', email: false });
  const [changeScreen, setChangeScreen] = useState(true);
  const [savedEmail, setSavedEmail] = useState('');
  const { state, dispatch } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);

  const login = async (password: string) => {
    LoginService.login({
      username: savedEmail,
      password,
    })
      .then((response) => {
        setOpenModal(true);

        const token = response.data?.access_token || '';

        sessionStorage.setItem(StorageTokens.ACCESS_TOKEN, token);

        if (state.saveCredentials.saveCredential) {
          Storage.setToken(token);
          localStorage.setItem('@App:user', savedEmail);
        }

        setErrors((e) => ({ ...e, login: '' }));
      })
      .catch((error: any) => {
        const err = httpStatus[error?.response?.status] || httpStatus.default;

        setErrors((e) => ({ ...e, login: err }));
      });
  };

  const validateEmail = async (email: string) => {
    try {
      const validation = await LoginService.validateEmail({ email });
      IsEmailValid();
      setSavedEmail(email);
      setErrors((e) => ({ ...e, email: false }));
    } catch (err) {
      setErrors((e) => ({ ...e, email: true }));
    }
  };

  const resendCode = async (email: string) => {
    try {
      const validation = await LoginService.validateEmail({ email });
      setSavedEmail(email);
      setErrors({ login: '', email: false });
    } catch {
      setErrors((e) => ({ ...e, email: true }));
    }
  };

  const resendButton = async () => {
    await resendCode(savedEmail);
  };

  const IsEmailValid = () => {
    setChangeScreen(!changeScreen);
  };

  return (
    <Container fluid className={`login-page${changeScreen ? '' : '-code'}`}>
      <div className="right-side">
        <div className="iteris-logo">
          <Icon name="logoAndName" />
        </div>
        <div className="login-form">
          {changeScreen ? (
            <EmailCard handleEmail={validateEmail} emailError={errors.email} />
          ) : (
            <CodeCard
              handleCodeNumber={login}
              codeError={errors.login}
              resendCode={resendButton}
            />
          )}
        </div>
      </div>
      <PrivacyPolicyModal
        open={openModal}
        close={() => {
          setOpenModal(false);
          dispatch(Logged({ name: 'login', email: savedEmail }));
        }}
        openLink={() => {
          window.open(
            'https://www.iteris.com.br/politica-de-privacidade/',
            '_blank'
          );
        }}
      />
    </Container>
  );
}

export default Login;

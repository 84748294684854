/* eslint-disable no-shadow */
enum StageAdmissionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

export default StageAdmissionStatus;

import AppModal from 'components/AppModal';
import { DynamicBenefit } from 'pages/Benefits/Benefits';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Benefit from 'types/models/Benefit';
import Dependant from 'types/models/Dependant';
import BenefitDescription from '../BenefitDescription';
import BenefitCard from './BenefitCard';
import BenefitSelect from './BenefitSelect';
import './styles.scss';

type BenefitsContainerProps = {
  value: number;
  dependants: Dependant[];
  benefits: Benefit[];
  updatedValue: number;
  updateDynamic: (v: DynamicBenefit) => void;
  selectedValues: Record<string, DynamicBenefit>;
  selectBenefit: (benefit: Benefit) => void;
  lockBenefits: boolean;
};

export type SelectBenefit = {
  label: string;
  idBenefitOption: number;
};

function BenefitsContainer({
  value,
  dependants,
  benefits,
  updatedValue,
  updateDynamic,
  selectedValues,
  selectBenefit,
  lockBenefits,
}: BenefitsContainerProps) {
  const [open, setOpen] = useState(false);
  const [selectedBenefitValue, setSelectedBenefitValue] = useState(0);
  const [idDependantsList, setIdDependantsList] = useState<Array<number>>([]);
  const [selected, setSelected] = useState<Benefit>({
    idBenefit: 0,
    name: '',
    short: '',
    selected: false,
    dependant: false,
    text: '',
    urlImage: '',
    urlCardImage: '',
    flagBenefitOption: false,
    flagBenefitValue: true,
    flagBenefitMinimum: true,
    flagBenefitDependant: false,
    mandatory: false,
    description: '',
    options: [
      {
        idBenefitOption: 0,
        name: '',
        valueDependant: 0,
        valueBeneficiary: 0,
      },
    ],
  });

  const [selectedOption, setSelectedOption] = useState({
    label: '',
    idBenefitOption: 0,
  });

  const checkDependantBenefit = (check: boolean, id: number) => {
    const isOnList = idDependantsList.filter((d) => d === id).length > 0;

    if (isOnList) {
      setIdDependantsList((l) => l.filter((x) => x !== id));
    } else {
      setIdDependantsList([...idDependantsList, id]);
    }
  };

  const confirmBenefitModalButton = () => {
    const dynamicBenefit: DynamicBenefit = {
      idBenefit: selected.idBenefit,
    };

    if (selected.flagBenefitValue) {
      dynamicBenefit.value = selectedBenefitValue;
    }

    if (selected.flagBenefitOption) {
      dynamicBenefit.idBenefitOption = selectedOption.idBenefitOption;
      dynamicBenefit.idDependants = idDependantsList;
    }

    updateDynamic(dynamicBenefit);

    setIdDependantsList([]);
    setOpen(false);
  };

  const benefitValue = useCallback(
    (id: number) => {
      const current = selectedValues[id];
      const cardValue = current?.value || 0;

      return cardValue;
    },
    [selectedValues]
  );

  const benefitOption = (id: number): SelectBenefit => {
    const current = selectedValues[id];
    const currentSelected = current?.idBenefitOption || 0;

    const options = benefits
      .filter((b) => b.idBenefit === id)
      .map((x) => x.options)[0];

    if (!options) {
      return {
        label: '',
        idBenefitOption: -1,
      };
    }

    const selecteBenefit = options.filter(
      (o) => o.idBenefitOption === currentSelected
    )[0];

    const returnObject = {
      label: selecteBenefit?.name || '',
      idBenefitOption: currentSelected,
    };

    return returnObject;
  };

  const handleSelect = (benefit: Benefit) => {
    selectBenefit(benefit);
  };

  const shouldDisableConfirmButton = useMemo(() => {
    const availableAmount = updatedValue + benefitValue(selected.idBenefit);
    const availableAmountFixed = Math.round(availableAmount * 10) / 10;

    return (
      selected.flagBenefitValue && selectedBenefitValue > availableAmountFixed
    );
  }, [
    benefitValue,
    selected.flagBenefitValue,
    selected.idBenefit,
    selectedBenefitValue,
    updatedValue,
  ]);

  return (
    <Row className="benefits-container">
      <Col xs={12} sm={4}>
        <Row>
          <Col>
            <h1 className="title mobile">Selecione:</h1>
          </Col>
        </Row>
        <Row className="row-benefits-select">
          <Col>
            {benefits.map((benefit) => (
              <BenefitSelect
                key={benefit.name}
                name={benefit.name}
                benefit={benefit}
                handleClick={handleSelect}
              />
            ))}
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={8}>
        <Row className="slides">
          {benefits
            .filter((benefit) => benefit.selected)
            .map((benefit) => (
              <BenefitCard
                locked={lockBenefits}
                key={benefit.name}
                benefit={benefit}
                dependants={
                  selectedValues[benefit.idBenefit]?.idDependants?.length || 0
                }
                value={benefitValue(benefit.idBenefit)}
                selectedOption={benefitOption(benefit.idBenefit)}
                onClick={() => {
                  if (!lockBenefits) {
                    setSelected(benefit);
                    setSelectedOption(benefitOption(benefit.idBenefit));
                    setIdDependantsList(
                      selectedValues[benefit.idBenefit]?.idDependants || []
                    );
                    setOpen(true);
                  }
                }}
              />
            ))}
        </Row>
      </Col>

      <AppModal
        title={selected ? selected.name : ''}
        open={open}
        className="modal-benefit"
        close={() => {
          setOpen(false);
        }}
        disableConfirm={shouldDisableConfirmButton}
        confirm={() => {
          confirmBenefitModalButton();
        }}
      >
        <BenefitDescription
          checkDependantBenefit={checkDependantBenefit}
          setSelectedOption={setSelectedOption}
          checkedDependants={idDependantsList}
          updatedValue={updatedValue}
          initialValue={benefitValue(selected.idBenefit)}
          selectedOption={benefitOption(selected.idBenefit)}
          close={() => {
            setOpen(false);
          }}
          selectedValue={(e: number) => setSelectedBenefitValue(e)}
          value={value}
          benefit={selected}
          dependants={dependants}
        />
      </AppModal>
    </Row>
  );
}

export default BenefitsContainer;

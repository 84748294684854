import StorageTokens from 'constants/storage';

function setToken(token: string) {
  localStorage.setItem(StorageTokens.ACCESS_TOKEN, token);
}

function getToken() {
  return localStorage.getItem(StorageTokens.ACCESS_TOKEN);
}

function setUser(email: string) {
  localStorage.setItem(StorageTokens.LOGGED_USER, email);
}

function getUser() {
  return localStorage.getItem(StorageTokens.LOGGED_USER);
}

const Storage = { setToken, getToken, setUser, getUser };

export default Storage;

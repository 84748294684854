import ValidationFormType from 'types/enum/ValidationFormType';
import { AdmissionFormConfig, UserStore } from './user/types';

const createInitialStore = (): AdmissionFormConfig => ({
  name: ValidationFormType.NONE,
  isEditing: false,
  status: 'none',
  validationResults: [],
  modifiedAttachments: [],
});

const store: UserStore = {
  loggedUser: {
    name: '',
    email: '',
  },
  saveCredentials: {
    saveCredential: false,
  },
  steps: {},
  contractType: '',
  approvedMedicalStep: false,
  name: '',
  stageAdmission: [],
  admissionForm: createInitialStore(),
};

export default store;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { customStyles } from 'styles/select/customStyles';
import { Row, Col } from 'react-bootstrap';
import masks from 'utils/MasksUtils';
import {
  CUSTOM_ERROR_MESSAGE,
  getFormValidationProps,
} from 'components/Forms/Shared/FormUtils';
import AuthContext from 'contexts/auth';

const choices = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não', label: 'Não' },
];

const classSchedule = [
  { value: 'Manhã', label: 'Manhã' },
  { value: 'Tarde', label: 'Tarde' },
  { value: 'Noite', label: 'Noite' },
];

function ProfileInternshipEducation({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const maxLength = 50;

  const educationalInstitutionFormValidationProps = getFormValidationProps(
    'educationalInstitution',
    admissionForm,
    profileMethods
  );

  const mandatoryInternshipFormValidationProps = getFormValidationProps(
    'mandatoryInternship',
    admissionForm,
    profileMethods
  );

  const yearCurrentSemesterFormValidationProps = getFormValidationProps(
    'yearCurrentSemester',
    admissionForm,
    profileMethods
  );

  const courseFormValidationProps = getFormValidationProps(
    'course',
    admissionForm,
    profileMethods
  );

  const registrationFormValidationProps = getFormValidationProps(
    'registration',
    admissionForm,
    profileMethods
  );

  const classScheduleFormValidationProps = getFormValidationProps(
    'classSchedule',
    admissionForm,
    profileMethods
  );

  return (
    <>
      <Row>
        <Col sm={6}>
          <label
            id="educationalInstitution"
            className="form-field"
            htmlFor="educationalInstitution"
          >
            <span className="label">Instituição de Ensino*</span>
            <Controller
              control={profileMethods.control}
              name="educationalInstitution"
              rules={{ required: true, maxLength }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={
                    educationalInstitutionFormValidationProps.hasAnyError
                      ? 'field -full-error'
                      : 'field -full'
                  }
                  onChange={(e) => onChange(e.target.value)}
                  value={
                    profileMethods.getValues('educationalInstitution') || ''
                  }
                  disabled={
                    educationalInstitutionFormValidationProps.shouldDisable
                  }
                />
              )}
            />
            {errors && errors.educationalInstitution?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength} caracteres.
              </span>
            )}
            {errors && errors.educationalInstitution?.type === 'required' && (
              <span className="error-text">Esse campo é obrigatório.</span>
            )}
            {educationalInstitutionFormValidationProps.hasCustomError && (
              <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
            )}
          </label>
        </Col>

        <Col sm={3}>
          <label
            id="mandatoryInternship"
            className="form-field"
            htmlFor="mandatoryInternship"
          >
            <span className="label">Estágio Obrigatório*</span>
            <Controller
              name="mandatoryInternship"
              control={profileMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStyles(
                    mandatoryInternshipFormValidationProps.hasAnyError,
                    mandatoryInternshipFormValidationProps.shouldDisable
                  )}
                  options={choices}
                  name={name}
                  value={choices.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isSearchable={
                    !mandatoryInternshipFormValidationProps.shouldDisable
                  }
                  menuIsOpen={
                    mandatoryInternshipFormValidationProps.shouldDisable
                      ? false
                      : undefined
                  }
                />
              )}
            />
            {errors && errors.mandatoryInternship?.type === 'required' ? (
              <span className="error-text">Esse campo é obrigatório.</span>
            ) : null}
            {mandatoryInternshipFormValidationProps.hasCustomError && (
              <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
            )}
          </label>
        </Col>

        <Col sm={3}>
          <label
            id="yearCurrentSemester"
            className="form-field"
            htmlFor="yearCurrentSemester"
          >
            <span className="label">Ano/Semestre Atual*</span>
            <input
              type="text"
              className={
                yearCurrentSemesterFormValidationProps.hasAnyError
                  ? 'field -full-error'
                  : 'field -full'
              }
              placeholder=""
              {...profileMethods.register('yearCurrentSemester', {
                required: true,
              })}
              disabled={yearCurrentSemesterFormValidationProps.shouldDisable}
            />
            {errors && errors.yearCurrentSemester?.type === 'required' && (
              <span className="error-text">Esse campo é obrigatório.</span>
            )}
            {yearCurrentSemesterFormValidationProps.hasCustomError && (
              <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
            )}
          </label>
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <label id="course" className="form-field" htmlFor="course">
            <span className="label">Curso*</span>
            <Controller
              control={profileMethods.control}
              name="course"
              rules={{ required: true, maxLength }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={
                    courseFormValidationProps.hasAnyError
                      ? 'field -full-error'
                      : 'field -full'
                  }
                  onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
                  value={profileMethods.getValues('course') || ''}
                  disabled={courseFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.course?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength} caracteres.
              </span>
            )}
            {errors && errors.course?.type === 'required' && (
              <span className="error-text">Esse campo é obrigatório.</span>
            )}
            {courseFormValidationProps.hasCustomError && (
              <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
            )}
          </label>
        </Col>

        <Col sm={3}>
          <label
            id="registration"
            className="form-field"
            htmlFor="registration"
          >
            <span className="label">Matrícula*</span>
            <Controller
              control={profileMethods.control}
              name="registration"
              rules={{ required: true, maxLength }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={
                    registrationFormValidationProps.hasAnyError
                      ? 'field -full-error'
                      : 'field -full'
                  }
                  onChange={(e) => onChange(e.target.value)}
                  value={profileMethods.getValues('registration') || ''}
                  disabled={registrationFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.registration?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength} caracteres.
              </span>
            )}
            {errors && errors.registration?.type === 'required' && (
              <span className="error-text">Esse campo é obrigatório.</span>
            )}
            {registrationFormValidationProps.hasCustomError && (
              <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
            )}
          </label>
        </Col>

        <Col sm={3}>
          <label
            id="classSchedule"
            className="form-field"
            htmlFor="classSchedule"
          >
            <span className="label">Horário das Aulas*</span>
            <Controller
              name="classSchedule"
              control={profileMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStyles(
                    classScheduleFormValidationProps.hasAnyError,
                    classScheduleFormValidationProps.shouldDisable
                  )}
                  options={classSchedule}
                  name={name}
                  value={classSchedule.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isSearchable={!classScheduleFormValidationProps.shouldDisable}
                  menuIsOpen={
                    classScheduleFormValidationProps.shouldDisable
                      ? false
                      : undefined
                  }
                />
              )}
            />
            {errors && errors.classSchedule?.type === 'required' && (
              <span className="error-text">Esse campo é obrigatório.</span>
            )}
            {classScheduleFormValidationProps.hasCustomError && (
              <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
            )}
          </label>
        </Col>
      </Row>
    </>
  );
}

export default ProfileInternshipEducation;

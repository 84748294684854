/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function DocumentPIS({
  documentsDataMethods,
}: {
  documentsDataMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;
  const maxLength = 15;
  const target = useRef(null);
  const [openOverlay, setOpenOverlay] = useState(false);
  const { t } = useTranslation('admission');

  const { hasAnyError, hasCustomError, shouldDisable } = getFormValidationProps(
    'PIS',
    admissionForm,
    documentsDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorViewValidator(errorValidation: FieldError | undefined) {
    return errors && errorValidation?.type === 'required' ? (
      <span className="error-text">Esse campo é obrigatório.</span>
    ) : null;
  }

  return (
    <label id="PISNumber" className="form-field" htmlFor="PIS.number">
      <span className="label">Número do PIS*</span>
      <Controller
        control={documentsDataMethods.control}
        name="PIS"
        rules={{ required: true, maxLength }}
        render={({ field: { onChange, onBlur } }) => (
          <>
            <input
              onMouseLeave={() => setOpenOverlay(false)}
              onClick={() => setOpenOverlay(true)}
              type="text"
              ref={target}
              onBlur={onBlur}
              onChange={(e) => onChange(masks.docNumber(e.target.value))}
              className={errorClass(hasAnyError)}
              value={documentsDataMethods.getValues('PIS') || ''}
              disabled={shouldDisable}
            />
            <Overlay
              target={target.current}
              show={openOverlay}
              placement="top-end"
            >
              {(props) => (
                <Tooltip id="overlay-tooltip" {...props}>
                  {t('PIS Number Info')}
                </Tooltip>
              )}
            </Overlay>
          </>
        )}
      />
      {errors && errors.PIS?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {errorViewValidator(errors.PIS)}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default DocumentPIS;

import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import { Types } from 'components/NavigationState/types';
import StepsController from 'components/StateController/StepsController';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import AuthContext from 'contexts/auth';

import './styles.scss';

export type StepsProps = {
  type: string;
  route?: string;
  name?: string;
};

function StepsHeader({ type, route, name }: StepsProps) {
  const stepsController = StepsController();
  const navigate = useNavigate();
  const { t } = useTranslation(type);
  const [openBackModal, setOpenBackModal] = useState(false);
  const { state } = useContext(AuthContext);

  const getStatus = () => stepsController.steps[type]?.status;

  const verifyGoHome = () => {
    if (type === 'admission') {
      setOpenBackModal(true);
    } else {
      navigate('/');
    }
  };
  const handleGoHome = () => {
    setOpenBackModal(false);
    navigate('/');
  };

  function getNextStage(r: string) {
    const routeMap: Record<string, string> = {
      beneficios: 'benefits',
      exam: 'health',
      about: 'profile',
    };

    return routeMap[r] || '';
  }

  const nextStep = () => {
    const next = getNextStage(route || '');

    if (stepsController.steps[next]?.status === 'Complete') return;

    if (getStatus() === 'Pending' || getStatus() === 'Complete')
      navigate(`/${route}`);
  };

  return (
    <div className="steps">
      <Row className="stage-navigation">
        <Col className="back" onClick={verifyGoHome}>
          <Icon name="closeIconBlack" />
          <span className="ms-2 hide-back-button-label">{t('Back Home')}</span>
        </Col>
        {route && (
          <Col className="next" onClick={() => nextStep()}>
            <span className="me-2">
              {state.contractType === 'PJ'
                ? t('Go LastPage')
                : t('Go NextPage')}
            </span>
            <Icon name="chevronRight" />
          </Col>
        )}
      </Row>
      <hr />
      <section className="page-info">
        <div className="main-header-title">
          <span className={`step ${getStatus()?.toLowerCase()}`}>
            <Icon name={Types[type]?.[getStatus()]} />
          </span>
          <h1>{t('Main Title')}</h1>
        </div>
        <div className="main-header-description">
          <span className="status-text">
            {t(`Status ${getStatus()?.toLowerCase()}`).toUpperCase()}
          </span>
          <span className="bullet">{'\u2B24'}</span>
          <div className="step-description">
            {name && <span>{name},</span>}
            <p>{t('Main Description')}</p>
          </div>
        </div>
      </section>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoHome();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />
    </div>
  );
}

export default StepsHeader;

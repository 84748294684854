import { FormCategories } from 'types/common/FormCategories';
import ValidationFormType from 'types/enum/ValidationFormType';

/* eslint-disable import/prefer-default-export */
export const infoCLT: FormCategories = [
  {
    name: ValidationFormType.PROFILE,
    title: 'Informações Pessoais',
  },
  {
    name: ValidationFormType.RESIDENTIAL,
    title: 'Dados Residenciais',
  },
  {
    name: ValidationFormType.DOCUMENTS,
    title: 'Preencher documentação',
  },
  {
    name: ValidationFormType.BANKING,
    title: 'Dados Bancários',
  },
  {
    name: ValidationFormType.DEPENDANTS,
    title: 'Inclusão de Dependentes',
  },
  {
    name: ValidationFormType.ATTACHMENTS,
    title: 'Upload de Documentos',
  },
];

import React from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Conditional from 'components/Conditional/Conditional';
import './styles.scss';

export type PrivacyPolicyModalProps = {
  open: boolean;
  close: () => void;
  openLink: () => void;
};

function PrivacyPolicyModal({
  open,
  close,
  openLink,
}: PrivacyPolicyModalProps) {
  const { t } = useTranslation('loginEmail');

  return (
    <Conditional if={open}>
      <Modal show centered dialogClassName="privacy-policy-modal">
        <Modal.Body>
          <Row>
            <Row className="icon">
              <Icon name="triangleWarning" />
            </Row>
            <Row className="text">
              <h2 className="title">{t('Privacy warning title')}</h2>
              <h4 className="description">
                {t('Privacy warning description')}
              </h4>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer">
          <Row>
            <Col onClick={openLink} className="button-link">
              <span className="link">
                {window.innerWidth > 768
                  ? t('Privacy policy')
                  : t('Privacy policy short')}
              </span>
              <Icon name="openLink" />
            </Col>
            <Col className="button-close">
              <Button onClick={close} className="close">
                {t('Continue')}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Conditional>
  );
}

export default PrivacyPolicyModal;

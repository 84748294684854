import React, { useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import AuthContext from 'contexts/auth';
import { updateAdmissionForm } from 'store/user/actions';
import AttachmentForeignCLTAnswersResponse from 'types/models/AttachmentForeignCLTAnswersResponse';
import FormAnswerType from 'types/enum/FormAnswerType';
import DocumentAttachment from 'components/Forms/Shared/DocumentAttachment';
import { checkFinishButtonDisabled } from 'components/Forms/Shared/FormUtils';
import { formStatus } from 'types/common/FormStatus';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Attachments({
  handleConfirm,
  handlePrevious,
}: {
  handleConfirm: () => void;
  handlePrevious: () => void;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  const formMethods: UseFormReturn<FormModel.DocumentAttachment> = useForm<
    FormModel.DocumentAttachment,
    any
  >({
    mode: 'onChange',
  });

  async function loadAttachmentForeignCLT(): Promise<void> {
    return FormAnswerService.getAllAnswers<AttachmentForeignCLTAnswersResponse>(
      FormAnswerType.ATTACHMENT_FOREIGN_CLT
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        const EMPTY_DOC = [{ name: '', url: '' }];

        formMethods.reset({
          RNE: data.RNE || [...EMPTY_DOC],
          CTPS: data.CTPS || [...EMPTY_DOC],
          ID: data.ID || [...EMPTY_DOC],
          CPF: data.CPF || [...EMPTY_DOC],
          registrationQualification: data.registrationQualification || [
            ...EMPTY_DOC,
          ],
          PIS: data.PIS || [...EMPTY_DOC],
          address: data.address || [...EMPTY_DOC],
          proofOfSchooling: data.proofOfSchooling || [...EMPTY_DOC],
          courses: data.courses || [...EMPTY_DOC],
          bankAccount: data.bankAccount || [...EMPTY_DOC],
          certification: data.certification || [...EMPTY_DOC],
          underAgeBirthCertification: data.underAgeBirthCertification || [
            ...EMPTY_DOC,
          ],
          underAgeVaccineCertification: data.underAgeVaccineCertification || [
            ...EMPTY_DOC,
          ],
          underAgeSchoolAttendance: data.underAgeSchoolAttendance || [
            ...EMPTY_DOC,
          ],
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.ATTACHMENTS,
          status: data ? (validationStatus as formStatus) : 'none',
          isEditing: !!data,
          validationResults: validationResults || [],
          modifiedAttachments: [],
        })
      );

      setLoading(false);
    });
  }

  function handleDisable() {
    return checkFinishButtonDisabled(formMethods, admissionForm) || loading;
  }

  useEffect(() => {
    loadAttachmentForeignCLT();
  }, []);

  return (
    <DocumentAttachment
      type="FOREIGN_CLT"
      formAnswerType={FormAnswerType.ATTACHMENT_CLT}
      documentAttachmentMethods={formMethods}
      handlePrevious={handlePrevious}
      handleConfirm={handleConfirm}
      handleDisableConfirm={handleDisable}
    />
  );
}

export default Attachments;

/* eslint-disable import/prefer-default-export */
export const PresentationTexts = {
  'pt-BR': {
    'Title Question': 'Nos conte uma curiosidade sobre você',
    'Description CNPJ': '',
    'Main Title': 'Apresente-se',
    'Main Description':
      ' queremos saber um pouco mais de você. Pode contar para a gente?',
    'Status inactive': 'Não iniciado',
    'Status active': 'Etapa iniciada',
    'Status complete': 'Etapa concluída',
    'Status error': 'Há erros nesta etapa',
    'Back Home': 'Voltar para home',
  },
  en: {},
};

/* eslint-disable import/prefer-default-export */
export const AdmissionFormTexts = {
  'pt-BR': {
    'Back Home': 'Voltar para home',
    'Go NextPage': 'Ir para benefícios',
    'Go LastPage': 'Ir para apresente-se',
    'Main Title': 'Formulário Admissional',
    'Main Description':
      'Etapa para você apresentar seus dados pessoais e documentos para sua admissão.',
    'Status inactive': 'Não iniciado',
    'Status active': 'Etapa iniciada',
    'Status pending': 'Em validação pelo RH',
    'Status complete': 'Etapa concluída',
    'Status error': 'Há erros nesta etapa',
    'Profile Picture Title': 'Edição da foto de perfil',
    'Profile Picture Description':
      'Clique para selecionar uma foto de perfil. Essa deve ser recente, individual e com visão clara do seu rosto (tipo selfie).',
    'Profile Picture Format Error':
      'Adicione um formato de imagem válido: jpg ou png',
    'Profile Picture Size Error':
      'A imagem de perfil não pode ser maior que 5Mb',
    'Preferred Name Info': 'Não colocar apelidos.',
    'PIS Number Info': 'Não esquecer de incluir o dígito.',
  },
  en: {},
};

const CLT: any[] = [
  { name: 'CTPS', required: true },
  { name: 'ID', required: true },
  { name: 'CPF', required: true },
  { name: 'voteRegistration', required: true },
  { name: 'registrationQualification', required: true },
  { name: 'PIS', required: true },
  { name: 'address', required: true },
  { name: 'reservist', required: false },
  { name: 'proofOfSchooling', required: true },
  { name: 'courses', required: false },
  { name: 'bankAccount', required: false },
  { name: 'certification', required: true },
  { name: 'underAgeBirthCertification', required: false },
  { name: 'underAgeVaccineCertification', required: false },
  { name: 'underAgeSchoolAttendance', required: false },
];

const FOREIGN_CLT: any[] = [
  { name: 'CTPS', required: true },
  { name: 'RNE', required: true },
  { name: 'ID', required: false },
  { name: 'CPF', required: true },
  { name: 'registrationQualification', required: true },
  { name: 'PIS', required: true },
  { name: 'address', required: true },
  { name: 'proofOfSchooling', required: true },
  { name: 'courses', required: false },
  { name: 'bankAccount', required: false },
  { name: 'certification', required: true },
  { name: 'underAgeBirthCertification', required: false },
  { name: 'underAgeVaccineCertification', required: false },
  { name: 'underAgeSchoolAttendance', required: false },
];

const INTERNSHIP: any[] = [
  { name: 'ID', required: true },
  { name: 'CPF', required: true },
  { name: 'address', required: true },
  { name: 'reservist', required: false },
  { name: 'proofOfSchooling', required: true },
  { name: 'bankAccount', required: false },
];

const PJ: any[] = [
  { name: 'ID', required: true },
  { name: 'CPF', required: true },
  { name: 'address', required: true },
  { name: 'socialContract', required: true },
  { name: 'CNPJ', required: true },
  { name: 'bankAccount', required: true },
];

export default {
  CLT,
  FOREIGN_CLT,
  INTERNSHIP,
  PJ,
};

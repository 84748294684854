import CitySelect from 'components/Forms/Shared/CitySelect/CitySelect';
import StateSelect from 'components/Forms/Shared/StateSelect/StateSelect';
import Icon from 'components/Icon';
import AuthContext from 'contexts/auth';
import React, { useState } from 'react';
import {
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import AddressService from 'services/addressService';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import { CEP as CEPResponse } from 'types/models/CEP';
import masks from 'utils/MasksUtils';
import Button from 'components/Button';
import { getFormValidationProps, isErrorStatus } from '../FormUtils';
import * as FormModel from './model';
import { residentialDataAdapter } from './ResidentialDataAdapter';
import './styles.scss';
import ValidationUtils from '../ValidationUtils';
import ConfirmationBackModal from '../ConfirmationModal/ConfirmationBackModal';

import GenericModal from '../GenericModal';

type AutoFillFields = {
  neighborhood: string;
  streetName: string;
  state: string;
  city: string;
};

function ResidentialData({
  formMethods,
  handlePrevious,
  handleNext,
  handleDisableNext,
  formChanged,
}: {
  formMethods: UseFormReturn<FormModel.ResidentialData>;
  handlePrevious: () => void;
  handleNext: () => void;
  handleDisableNext: () => boolean;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [autoFill, setAutoFill] = useState<AutoFillFields>({
    neighborhood: '',
    streetName: '',
    state: '',
    city: '',
  });
  const [openBackModal, setOpenBackModal] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Revise seus dados.',
    description: 'Algo deu errado ao tentar avançar para o próximo passo.',
    modalTitle: '',
  });

  const { errors } = formMethods.formState;
  const maxLength = {
    maxLengthNumber: 8,
    maxLengthName: 50,
  };

  const CEPFormValidationProps = getFormValidationProps(
    'CEP',
    admissionForm,
    formMethods
  );

  const streetNameFormValidationProps = getFormValidationProps(
    'streetName',
    admissionForm,
    formMethods
  );

  const streetNumberFormValidationProps = getFormValidationProps(
    'streetNumber',
    admissionForm,
    formMethods
  );

  const complementFormValidationProps = getFormValidationProps(
    'complement',
    admissionForm,
    formMethods
  );

  const neighborhoodFormValidationProps = getFormValidationProps(
    'neighborhood',
    admissionForm,
    formMethods
  );

  const stateFormValidationProps = getFormValidationProps(
    'state',
    admissionForm,
    formMethods
  );

  const cityFormValidationProps = getFormValidationProps(
    'city',
    admissionForm,
    formMethods
  );

  const convertResponseToState = (data: CEPResponse) => {
    const stateFromAPI: Record<string, string> = {};

    Object.keys(residentialDataAdapter).forEach((k) => {
      stateFromAPI[residentialDataAdapter[k]] = data[k as keyof CEPResponse];
    });

    setAutoFill(stateFromAPI as AutoFillFields);

    formMethods.setValue('neighborhood', stateFromAPI?.neighborhood || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    formMethods.setValue('streetName', stateFromAPI?.streetName || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    formMethods.setValue('state', stateFromAPI?.state || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    formMethods.setValue('city', stateFromAPI?.city || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const verifyGoBack = () => {
    if (formChanged(formMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  const addressHandle = (cep: string) => {
    const cleanText = cep.replace('-', '');

    if (cleanText.length === 8) {
      setLoading(true);
      AddressService.getFullAddress(cleanText)
        .then((response) => {
          const res: CEPResponse = response.data;
          convertResponseToState(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          formMethods.setError('CEP', {
            type: 'timeout',
          });
        });
    }
  };

  function errorClassCEP(errorValidation: FieldError | undefined) {
    if (CEPFormValidationProps.shouldDisable) return 'field -input disabled';
    return errors && errorValidation ? 'field -error' : 'field -input';
  }

  const handleConfirm = async (dataRequest: FormModel.ResidentialData) => {
    setOpenModal(true);
    setLoadingModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.RESIDENTIAL_DATA,
    })
      .then(() => {
        setLoadingModal(false);
        handleNext();
      })
      .catch(() => {
        setLoadingModal(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : formMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="address-container">
      <Row className="header-form-info">
        <Col>
          <h1>Dados residenciais</h1>
          <p>
            Informe os dados do endereço da sua moradia atual.{' '}
            <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <label id="addressZipCode" className="form-field" htmlFor="CEP">
            <span className="label">
              CEP*
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="cep-spinner"
                />
              ) : null}
            </span>
            <Controller
              control={formMethods.control}
              name="CEP"
              rules={{
                required: true,
                pattern: {
                  value: /[0-9]{5}-[0-9]{3}/,
                  message: 'CEP inválido',
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <InputGroup>
                  <FormControl
                    type="text"
                    className={errorClassCEP(
                      CEPFormValidationProps.hasAnyError
                    )}
                    placeholder=""
                    onChange={(e) => {
                      onChange(masks.cep(e.target.value));
                      addressHandle(e.target.value);
                    }}
                    value={formMethods.getValues('CEP')}
                    onBlur={onBlur}
                    readOnly={CEPFormValidationProps.shouldDisable}
                  />
                  <InputGroup.Text className={errorClassCEP(errors.CEP)}>
                    <Icon name="searchField" />
                  </InputGroup.Text>
                </InputGroup>
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.CEP)}
            {errors && errors.CEP?.type === 'pattern' && (
              <span className="error-text">
                O CEP é inválido, por favor revise.
              </span>
            )}
            {errors && errors.CEP?.type === 'timeout' && (
              <span className="error-text">
                Não foi possível realizar a consulta com o CEP informado. Por
                favor, preencha os demais dados residenciais.
              </span>
            )}
            {ValidationUtils.errorCustomValidator(
              CEPFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <label id="addressStreet" className="form-field" htmlFor="streetName">
            <span className="label">Rua*</span>
            <Controller
              control={formMethods.control}
              rules={{
                required: true,
                maxLength: maxLength.maxLengthName,
              }}
              name="streetName"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input
                  onBlur={onBlur}
                  value={formMethods.getValues('streetName') || ''}
                  type="text"
                  className={ValidationUtils.errorClass(
                    errors,
                    streetNameFormValidationProps.hasAnyError
                  )}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setAutoFill((s) => ({
                      ...s,
                      streetName: e.target.value,
                    }));
                  }}
                  readOnly={streetNameFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.streetName)}
            {ValidationUtils.errorMaxLengthValidator(
              errors,
              errors.streetName,
              maxLength
            )}
            {ValidationUtils.errorCustomValidator(
              streetNameFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
        <Col>
          <label
            id="addressNumber"
            className="form-field"
            htmlFor="streetNumber"
          >
            <span className="label">Nº*</span>
            <Controller
              control={formMethods.control}
              name="streetNumber"
              rules={{
                maxLength: maxLength.maxLengthNumber,
                required: true,
              }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={ValidationUtils.errorClass(
                    errors,
                    streetNumberFormValidationProps.hasAnyError
                  )}
                  value={formMethods.getValues('streetNumber') || ''}
                  onChange={(e) => onChange(masks.houseNumber(e.target.value))}
                  readOnly={streetNumberFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.streetNumber)}
            {errors && errors.streetNumber?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.maxLengthNumber} caracteres.
              </span>
            )}
            {ValidationUtils.errorCustomValidator(
              streetNumberFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
        <Col>
          <label
            id="addressComplement"
            className="form-field"
            htmlFor="complement"
          >
            <span className="label">Complemento</span>
            <Controller
              control={formMethods.control}
              name="complement"
              rules={{ maxLength: maxLength.maxLengthName }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  className={ValidationUtils.errorClass(
                    errors,
                    complementFormValidationProps.hasAnyError
                  )}
                  value={formMethods.getValues('complement') || ''}
                  readOnly={complementFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorMaxLengthValidator(
              errors,
              errors.complement,
              maxLength
            )}
            {ValidationUtils.errorCustomValidator(
              complementFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            id="addressDistrict"
            className="form-field"
            htmlFor="neighborhood"
          >
            <span className="label">Bairro*</span>
            <Controller
              rules={{
                required: true,
                maxLength: maxLength.maxLengthName,
              }}
              control={formMethods.control}
              name="neighborhood"
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={ValidationUtils.errorClass(
                    errors,
                    neighborhoodFormValidationProps.hasAnyError
                  )}
                  value={formMethods.getValues('neighborhood') || ''}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setAutoFill((s) => ({
                      ...s,
                      neighborhood: e.target.value,
                    }));
                  }}
                  readOnly={neighborhoodFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.neighborhood)}
            {ValidationUtils.errorMaxLengthValidator(
              errors,
              errors.neighborhood,
              maxLength
            )}
            {ValidationUtils.errorCustomValidator(
              neighborhoodFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
        <Col>
          <div id="addressState">
            <StateSelect
              selected={formMethods.getValues('state')}
              control={formMethods.control}
              errors={errors}
              formValidationProps={stateFormValidationProps}
              controlerName="state"
              titleText="Estado*"
              onChangeState={(selected: { sigla: string; label: string }) => {
                setAutoFill((s) => ({
                  ...s,
                  state: selected.sigla,
                }));
                if (formMethods.getValues('city')) {
                  formMethods.setValue('city', '', {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>
        </Col>
        <Col>
          <div id="addressCity">
            <CitySelect
              selected={formMethods.getValues('city')}
              initials={formMethods.getValues('state')}
              formValidationProps={cityFormValidationProps}
              controlerName="city"
              titleText="Cidade*"
              onChangeCity={(selected: { label: string }) => {
                setAutoFill((s) => ({
                  ...s,
                  city: selected.label,
                }));
              }}
              control={formMethods.control}
              errors={errors}
            />
          </div>
        </Col>
      </Row>
      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <Button onClick={verifyGoBack}>Voltar</Button>
          <Button
            disabled={handleDisableNext()}
            className="ms-3"
            onClick={handleButton()}
          >
            Salvar e continuar
          </Button>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />

      <GenericModal
        showFooter={!loadingModal}
        showHeader={!loadingModal}
        open={openModal}
        data={modalData}
        loading={loadingModal}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : formMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default ResidentialData;

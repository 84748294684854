import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import AdmissionForm from 'pages/AdmissionForm';
import Benefits from 'pages/Benefits';
import Presentation from 'pages/Presentation';
import EntranceExam from 'pages/EntranceExam';
import StaticBenefits from 'pages/StaticBenefits';

function OtherRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="onboarding" element={<AdmissionForm />} />
      <Route path="beneficios" element={<Benefits />} />
      <Route path="mais-beneficios" element={<StaticBenefits />} />
      <Route path="exam" element={<EntranceExam />} />
      <Route path="about" element={<Presentation />} />
    </Routes>
  );
}

export default OtherRoutes;

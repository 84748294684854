import { SaveCredentials } from 'types/common/SaveCredentials';
import { LoggedUser } from 'types/common/loggedUser';
import { UserSteps } from 'types/common/UserSteps';
import { StoreContractType } from 'types/common/ContractType';
import { formStatus } from 'types/common/FormStatus';
import { StageAdmissionSteps } from 'types/common/StageAdmissionSteps';
import ValidationFormType from 'types/enum/ValidationFormType';

export type UserStore = {
  loggedUser: LoggedUser;
  saveCredentials: SaveCredentials;
  steps: UserSteps;
  contractType: StoreContractType;
  approvedMedicalStep: boolean;
  name: string;
  admissionForm: AdmissionFormConfig;
  stageAdmission: StageAdmissionSteps[];
};

export type AdmissionFormConfig = {
  name: ValidationFormType;
  isEditing: boolean;
  status: formStatus;
  validationResults: string[];
  modifiedAttachments?: string[];
};

export type StoreAction = {
  type: string;
  payload?:
    | LoggedUser
    | SaveCredentials
    | UserSteps
    | StoreContractType
    | boolean
    | string
    | StageAdmissionSteps[]
    | AdmissionFormConfig;
};

export const types = {
  LOGIN: 'LOGIN',
  SAVE_CREDENTIALS: 'SAVE_CREDENTIALS',
  UPDATE_STEPS: 'UPDATE_STEPS',
  SAVE_CONTRACT_TYPE: 'SAVE_CONTRACT_TYPE',
  APPROVE_MEDICAL_STEP: 'APPROVE_MEDICAL_STEP',
  SAVE_NAME: 'SAVE_NAME',
  STAGE_ADMISSION: 'STAGE_ADMISSION',
  FORM_ADMISSION: 'FORM_ADMISSION',
};

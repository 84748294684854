export interface Bank {
  readonly code: string;
  readonly value: string;
  readonly label: string;
}

export const banks: Bank[] = [
  { code: '121', value: 'Banco Agiplan', label: 'Banco Agiplan' },
  { code: '237', value: 'Banco Bradesco S.A', label: 'Banco Bradesco S.A' },
  { code: '218', value: 'Banco BS2 S.A.', label: 'Banco BS2 S.A' },
  { code: '336', value: 'Banco C6', label: 'Banco C6' },
  {
    code: '002',
    value: 'Banco Central do Brasil',
    label: 'Banco Central do Brasil',
  },
  { code: '745', value: 'Banco Citibank S.A', label: 'Banco Citibank S.A' },
  { code: '403', value: 'Banco Cora SCD', label: 'Banco Cora SCD' },
  {
    code: '505',
    value: 'Banco Credit Suisse First Boston Garantia S.A',
    label: 'Banco Credit Suisse First Boston Garantia S.A',
  },
  { code: '001', value: 'Banco do Brasil S.A', label: 'Banco do Brasil S.A' },
  { code: '224', value: 'Banco Fibra S.A', label: 'Banco Fibra S.A' },
  { code: '626', value: 'Banco Ficsa S.A', label: 'Banco Ficsa S.A' },
  {
    code: '604',
    value: 'Banco Industria do Brasil S.A',
    label: 'Banco Industria do Brasil S.A',
  },
  { code: '077', value: 'Banco Inter S.A', label: 'Banco Inter S.A' },
  { code: '341', value: 'Banco Itaú S.A', label: 'Banco Itaú S.A' },
  {
    code: '755',
    value: 'Banco Merryl Lynch S.A',
    label: 'Banco Merryl Lynch S.A',
  },
  { code: '746', value: 'Banco Modal S.A', label: 'Banco Modal S.A' },
  { code: '735', value: 'Banco Neon', label: 'Banco Neon' },
  { code: '212', value: 'Banco Original', label: 'Banco Original' },
  {
    code: '623',
    value: 'Banco Panamericano S.A',
    label: 'Banco Panamericano S.A',
  },
  { code: '611', value: 'Banco Paulista S.A', label: 'Banco Paulista S.A' },
  { code: '643', value: 'Banco Pine S.A', label: 'Banco Pine S.A' },
  {
    code: '747',
    value: 'Banco RaboBank Internacional Brasil S.A',
    label: 'Banco RaboBank Internacional Brasil S.A',
  },
  { code: '356', value: 'Banco Real S.A', label: 'Banco Real S.A' },
  {
    code: '633',
    value: 'Banco Rendimento S.A ',
    label: 'Banco Rendimento S.A ',
  },
  { code: '453', value: 'Banco Rural S.A ', label: 'Banco Rural S.A ' },
  { code: '422', value: 'Banco Safra S.A', label: 'Banco Safra S.A' },
  {
    code: '033',
    value: 'Banco Santander Banespa S.A',
    label: 'Banco Santander Banespa S.A',
  },
  { code: '250', value: 'Banco Schahin S.A', label: 'Banco Schahin S.A' },
  { code: '637', value: 'Banco Sofisa S.A', label: 'Banco Sofisa S.A' },
  { code: '366', value: 'Banco Sogeral S.A', label: 'Banco Sogeral S.A' },
  {
    code: '464',
    value: 'Banco Sumitomo Brasileiro S.A',
    label: 'Banco Sumitomo Brasileiro S.A',
  },
  { code: '655', value: 'Banco Votorantim S.A', label: 'Banco Votorantim S.A' },
  { code: '610', value: 'Banco VR S.A', label: 'Banco VR S.A' },
  {
    code: '038',
    value: 'Banco do Estado do Paraná S.A',
    label: 'Banco do Estado do Paraná S.A',
  },
  {
    code: '301',
    value: 'BPP Instituição de Pagamentos S.A',
    label: 'BPP Instituição de Pagamentos S.A',
  },
  { code: '999', value: 'Caixa - Tesouraria ', label: 'Caixa - Tesouraria ' },
  {
    code: '104',
    value: 'Caixa Ecônomica Federal',
    label: 'Caixa Ecônomica Federal',
  },
  {
    code: '085',
    value: 'Cooperativa Central de Creditos',
    label: 'Cooperativa Central de Creditos',
  },
  {
    code: '399',
    value: 'HSBC Bank Brasil S.A - Banco Múltiplo',
    label: 'HSBC Bank Brasil S.A - Banco Múltiplo',
  },
  { code: '260', value: 'Nu Pagamentos S.A', label: 'Nu Pagamentos S.A' },
  {
    code: '290',
    value: 'PagSeguro Internet S.A',
    label: 'PagSeguro Internet S.A',
  },
  { code: '756', value: 'SICOOB Cocrealpa ', label: 'SICOOB Cocrealpa ' },
];

// eslint-disable-next-line no-shadow
enum FormAnswerType {
  PROFILE_CLT = 'PROFILE_CLT',
  PROFILE_PJ = 'PROFILE_PJ',
  PROFILE_FOREIGN_CLT = 'PROFILE_FOREIGN_CLT',
  PROFILE_INTERNSHIP = 'PROFILE_INTERNSHIP',
  RESIDENTIAL_DATA = 'RESIDENTIAL_DATA ',
  DEPENDANTS_DATA = 'DEPENDANTS_DATA ',
  DOCUMENTS_CLT = 'DOCUMENTS_CLT',
  DOCUMENTS_PJ = 'DOCUMENTS_PJ',
  DOCUMENTS_FOREIGN_CLT = 'DOCUMENTS_FOREIGN_CLT',
  DOCUMENTS_INTERNSHIP = 'DOCUMENTS_INTERNSHIP',
  ATTACHMENT_CLT = 'ATTACHMENT_CLT',
  ATTACHMENT_PJ = 'ATTACHMENT_PJ',
  ATTACHMENT_INTERNSHIP = 'ATTACHMENT_INTERNSHIP',
  ATTACHMENT_FOREIGN_CLT = 'ATTACHMENT_FOREIGN_CLT',
  BANKING_CLT = 'BANKING_CLT',
  BANKING_PJ = 'BANKING_PJ',
  BANKING_INTERNSHIP = 'BANKING_INTERNSHIP',
  BANKING_FOREIGN_CLT = 'BANKING_FOREIGN_CLT',
  PRESENTATION = 'PRESENTATION',
}

export default FormAnswerType;

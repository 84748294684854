import React, { useContext, useEffect, useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import ProfileCivilStatus from 'components/Forms/Shared/Profile/ProfileCivilStatus';
import ProfileCountryOfBirth from 'components/Forms/Shared/Profile/ProfileCountryofBirth';
import ProfileDisability from 'components/Forms/Shared/Profile/ProfileDisability';
import ProfileEducationalDegree from 'components/Forms/Shared/Profile/ProfileEducationalDegree';
import ProfileParentage from 'components/Forms/Shared/Profile/ProfileParentage';
import ProfilePlaceBirth from 'components/Forms/Shared/Profile/ProfilePlaceBirth';
import ProfileRaceColor from 'components/Forms/Shared/Profile/ProfileRaceColor';
import ProfileSecondParentage from 'components/Forms/Shared/Profile/ProfileSecondParentage';
import ProfileSex from 'components/Forms/Shared/Profile/ProfileSex';
import ProfileFirstRow from 'components/Forms/Shared/ProfileFirstRow';
import { START_PERMISSION_SIGNATURE } from 'constants/imageUpload';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import './styles.scss';
import ProfileCLTResponse from 'types/models/ProfileCLTResponse';
import { CivilStatus } from 'types/common/CivilStatus';
import { Deficiency } from 'types/common/Deficiency';
import { EducationLevel } from 'types/common/EducationLevel';
import { Race } from 'types/common/Race';
import { Gender } from 'types/common/Gender';
import AuthContext from 'contexts/auth';
import { updateAdmissionForm } from 'store/user/actions';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
} from 'components/Forms/Shared/FormUtils';
import { formStatus } from 'types/common/FormStatus';
import ProfileEmergencyContact from 'components/Forms/Shared/Profile/ProfileEmergencyContact';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

type ProfileProps = {
  handleNext: () => void;
};

function Profile({ handleNext }: ProfileProps) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const formMethods: UseFormReturn<FormModel.Profile> =
    useForm<FormModel.Profile>({
      mode: 'onBlur',
      defaultValues: {
        phoneNumber: '',
        mobileNumber: '',
        dateOfBirth: '',
        emergencyContact: { phoneNumber: '', fullName: '', kinship: '' },
      },
    });

  const handleConfirm = async (data: FormModel.Profile) => {
    const newData = { ...data };
    newData.countryOfBirth = newData.countryOfBirth.trim();
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    const dataRequest: FormModel.Profile = {
      ...newData,
      photo: formMethods
        .getValues('photo')
        .split(START_PERMISSION_SIGNATURE)[0],
    };

    if (dataRequest.countryOfBirth?.toLocaleLowerCase() !== 'brasil') {
      dataRequest.stateOfBirth = '';
      dataRequest.cityOfBirth = '';
    }

    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.PROFILE_CLT,
    })
      .then(() => {
        setLoading(false);
        handleNext();
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  async function loadAnswersProfileCLT(): Promise<void> {
    return FormAnswerService.getAllAnswers<ProfileCLTResponse>(
      'PROFILE_CLT'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        formMethods.reset({
          photo: data.photo || '',
          name: data.name || '',
          preferredName: data.preferredName || '',
          email: data.email || '',
          dateOfBirth: data.dateOfBirth || '',
          phoneNumber: data.phoneNumber || '',
          mobileNumber: data.mobileNumber || '',
          parentage: data.parentage || '',
          secondParentage: data.secondParentage || '',
          civilStatus: (data.civilStatus as CivilStatus) || '',
          deficiency: (data.deficiency as Deficiency) || '',
          schooling: (data.schooling as EducationLevel) || '',
          race: (data.race as Race) || '',
          sex: (data.sex as Gender) || '',
          stateOfBirth: data.stateOfBirth || '',
          cityOfBirth: data.cityOfBirth || '',
          countryOfBirth: data.countryOfBirth || '',
          emergencyContact: {
            fullName: data.emergencyContact?.fullName || '',
            phoneNumber: data.emergencyContact?.phoneNumber || '',
            kinship: data.emergencyContact?.kinship || '',
          },
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.PROFILE,
          status: (validationStatus as formStatus) || 'none',
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );
    });
  }

  useEffect(() => {
    loadAnswersProfileCLT();
  }, []);

  function handleDisable() {
    return checkSaveButtonDisabled(formMethods, admissionForm);
  }

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : formMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="profile-container">
      <Row className="header-form-info">
        <Col>
          <h1>Informações Pessoais</h1>
          <p>
            Preencha com suas informações pessoais os dados para montar o seu
            perfil. <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>
      <ProfileFirstRow
        profileMethods={formMethods}
        formAnswerType={FormAnswerType.PROFILE_CLT}
      />
      <Row className="profile-row">
        <Col sm={6} xs={12}>
          <ProfileParentage profileMethods={formMethods} />
          <ProfileSecondParentage profileMethods={formMethods} />
        </Col>

        <Col sm={3} xs={12}>
          <ProfileSex profileMethods={formMethods} />
          <ProfileDisability profileMethods={formMethods} />
        </Col>
        <Col sm={3} xs={12}>
          <ProfileRaceColor profileMethods={formMethods} />
          <ProfileCivilStatus profileMethods={formMethods} />
        </Col>

        <Col sm={6} xs={12}>
          <ProfileCountryOfBirth profileMethods={formMethods} />

          {formMethods
            .getValues('countryOfBirth')
            ?.trim()
            .toLocaleLowerCase() === 'brasil' && (
            <ProfilePlaceBirth profileMethods={formMethods} />
          )}
        </Col>

        <Col sm={3} xs={12}>
          <ProfileEducationalDegree profileMethods={formMethods} />
        </Col>
      </Row>
      <ProfileEmergencyContact profileMethods={formMethods} />
      <Row className="row-form-buttons">
        <Col>
          <ButtonLink
            disabled={handleDisable()}
            className="ms-auto"
            componentType="button"
            onClick={handleButton()}
          >
            Salvar e continuar
          </ButtonLink>
        </Col>
      </Row>

      <GenericModal
        loading={loading}
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : formMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Profile;

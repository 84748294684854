/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileParentage({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const maxLength = 50;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'parentage',
    admissionForm,
    profileMethods
  );

  return (
    <label className="form-field" htmlFor="parentage">
      <span className="label">Filiação 1*</span>
      <Controller
        rules={{ required: true, maxLength }}
        control={profileMethods.control}
        name="parentage"
        render={({ field: { onChange, onBlur } }) => (
          <input
            onBlur={onBlur}
            value={profileMethods.getValues('parentage') || ''}
            onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
            type="text"
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            readOnly={shouldDisable}
          />
        )}
      />

      {errors && errors.parentage?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}

      {errors && errors.parentage?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileParentage;

import React, { createRef, MutableRefObject, useEffect } from 'react';
import {
  FormCategories,
  FormCategoriesBenefits,
  NameFormsTypeBenefits,
} from 'types/common/FormCategories';
import ValidationFormType from 'types/enum/ValidationFormType';
import './styles.scss';

type FormStepsProps = {
  steps: FormCategories | FormCategoriesBenefits;
  active: ValidationFormType | NameFormsTypeBenefits;
};

type SliderRefsProps = {
  name: string;
  element: MutableRefObject<HTMLLIElement>;
};

function FormSteps({ steps, active }: FormStepsProps) {
  const sliderRefs: SliderRefsProps[] = [];
  const isActive = (idx: number) =>
    idx <= steps.findIndex((s) => s.name === active);

  const scrollToActive = () => {
    sliderRefs
      .find((s) => s.name === active)
      ?.element.current.scrollIntoView(false);
  };

  useEffect(() => {
    if (sliderRefs) scrollToActive();
  }, [active]);

  return (
    <div className={`steps-container -${steps[0].name}`}>
      <ul className="steps">
        {steps.map((step, idx) => {
          const ref = createRef() as MutableRefObject<HTMLLIElement>;
          sliderRefs.push({
            name: step.name,
            element: ref,
          });

          return (
            <li
              key={step.name}
              ref={ref}
              className={`${isActive(idx) ? 'active' : ''}`}
              style={{ width: `${100 / steps.length}%` }}
            >
              <span className="step"> </span>
              <span className="title">{step.title}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default FormSteps;

import { ContractType } from 'types/common/ContractType';

const CLT: Record<string, Record<string, string>> = {
  admission: {
    status: 'Inactive',
    number: '1/4',
    description:
      'Etapa para você apresentar seus dados pessoais e documentos para sua admissão',
    name: 'Formulário Admissional',
    path: '/onboarding',
  },
  benefits: {
    status: 'Inactive',
    number: '2/4',
    name: 'Benefícios',
    description:
      'Defina como você quer distribuir a sua cesta em cada benefício disponível',
    path: '/beneficios',
  },
  health: {
    status: 'Inactive',
    number: '3/4',
    name: 'Exame Admissional',
    description:
      'O RH marcará o seu exame na clínica mais próxima de você, após isso, faça o upload do resultado',
    path: '/exam',
  },
  profile: {
    status: 'Inactive',
    number: '4/4',
    name: 'Apresente-se',
    description:
      'Queremos saber um pouco mais de você. Pode contar para a gente?',
    path: '/about',
  },
};

const PJ: Record<string, Record<string, string>> = {
  admission: {
    status: 'Inactive',
    number: '1/2',
    name: 'Formulário Admissional',
    description:
      'Etapa para você apresentar seus dados pessoais e documentos para sua admissão',
    path: '/onboarding',
  },
  profile: {
    status: 'Inactive',
    number: '2/2',
    name: 'Apresente-se',
    description:
      'Queremos saber um pouco mais de você. Pode contar para a gente?',
    path: '/about',
  },
};

const defaultSteps: Record<
  ContractType,
  Record<string, Record<string, string>>
> = { CLT, PJ, FOREIGN_CLT: CLT, INTERNSHIP: CLT };

export default defaultSteps;

import React from 'react';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

type DatepickerProps = {
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  dateValue: string;
  selected: Date | null | undefined;
  onSelect: (
    date: Date,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
  className: string;
  minDate?: Date;
  maxDate?: Date;
  startYear?: number;
  endYear?: number;
  disabled?: boolean;
};

function Datepicker({
  onBlur,
  dateValue,
  selected,
  onSelect,
  className,
  minDate,
  maxDate,
  startYear,
  endYear,
  disabled,
}: DatepickerProps) {
  const range = (start: number, end: number) => {
    const newRange = new Array(end - start).fill('').map((d, i) => i + start);
    return newRange;
  };
  const years =
    startYear && endYear
      ? range(startYear, endYear)
      : range(1900, getYear(new Date()) + 1);

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return (
    <div className={`${className} ${disabled ? 'disabled' : ''}`}>
      <DatePicker
        autoComplete="none"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        locale={ptBR}
        onBlur={onBlur}
        maxDate={maxDate || new Date()}
        dateFormat="dd/MM/yyy"
        minDate={minDate || new Date('1900-01-02')}
        value={dateValue}
        selected={selected}
        onSelect={onSelect}
        onChange={(_) => {}}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) =>
                changeYear(parseInt(value, 10))
              }
            >
              {years.map((option: number) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        readOnly={disabled}
      />
    </div>
  );
}

export default Datepicker;

import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileProfesionalEmail({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const maxLength = 50;

  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'professionalEmail',
    admissionForm,
    profileMethods
  );

  return (
    <label
      id="professionalEmail"
      className="form-field"
      htmlFor="professionalEmail"
    >
      <span className="label">E-mail Profissional</span>
      <Controller
        rules={{ maxLength, pattern: /^\S.*@\S.*\.\S+$/ }}
        control={profileMethods.control}
        name="professionalEmail"
        render={({ field: { onChange, onBlur } }) => (
          <input
            value={profileMethods.getValues('professionalEmail')}
            onBlur={onBlur}
            type="text"
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            onChange={(e) => onChange(e.target.value)}
            disabled={shouldDisable}
          />
        )}
      />
      {errors && errors.professionalEmail?.type === 'pattern' && (
        <span className="error-text">
          O endereço de e-mail é inválido, por favor revise.
        </span>
      )}
      {errors && errors.professionalEmail?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileProfesionalEmail;

import api from 'configs/api';
import SendBenefit from 'types/models/sendBenefits';
import { ValidateBenefitRequest } from 'types/models/ValidateBenefits';

async function sendBenefits({ benefits, payrollDiscount }: SendBenefit) {
  return api.post('/benefit', {
    benefits,
    payrollDiscount,
  });
}

async function getCurrentBenefit() {
  return api.get('/benefit');
}

async function getStaticBenefits() {
  return api.get('/benefit/staticBenefits');
}

async function validateBenefits(request: ValidateBenefitRequest) {
  return api.post('/benefit/valid', request);
}

const BenefitsService = {
  sendBenefits,
  getCurrentBenefit,
  getStaticBenefits,
  validateBenefits,
};

export default BenefitsService;

import React, { useContext, useEffect, useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import BankingAccount from 'components/Forms/Shared/Banking/BankingAccount';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import '../../FormCLT/Banking/styles.scss';
import AuthContext from 'contexts/auth';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
} from 'components/Forms/Shared/FormUtils';
import { updateAdmissionForm } from 'store/user/actions';
import BankingAnswersResponse from 'types/models/BankingAnswersResponse';
import { formStatus } from 'types/common/FormStatus';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Banking({
  handleNext,
  handlePrevious,
  formChanged,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const bankingMethods: UseFormReturn<FormModel.Banking> =
    useForm<FormModel.Banking>({
      mode: 'onBlur',
      defaultValues: {
        bank: '',
        account: '',
        agency: '',
      },
    });

  async function loadAnswersBankingPJ(): Promise<void> {
    return FormAnswerService.getAllAnswers<BankingAnswersResponse>(
      FormAnswerType.BANKING_PJ
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        bankingMethods.reset({
          account: data.account || '',
          digit: data.digit || '',
          agency: data.agency || '',
          bank: data.bank || '',
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.BANKING,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  const handleConfirm = async (dataRequest: FormModel.Banking) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.BANKING_PJ,
    })
      .then(() => {
        setLoading(false);
        handleNext();
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  function handleDisable() {
    return checkSaveButtonDisabled(bankingMethods, admissionForm) || loading;
  }

  const verifyGoBack = () => {
    if (formChanged(bankingMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  useEffect(() => {
    loadAnswersBankingPJ();
  }, []);

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : bankingMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="banking-container">
      <Row className="header-form-info">
        <Col>
          <h1>Dados bancários</h1>
          <p>
            Informe seus dados bancários para recebimento do salário.{' '}
            <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>
      <BankingAccount
        type={FormAnswerType.BANKING_PJ}
        bankingDataMethods={bankingMethods}
      />

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <ButtonLink onClick={verifyGoBack}>Voltar</ButtonLink>
          <ButtonLink
            disabled={handleDisable()}
            className="ms-3"
            onClick={handleButton()}
          >
            Salvar e continuar
          </ButtonLink>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />

      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : bankingMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Banking;

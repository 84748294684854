import StepsController from 'components/StateController/StepsController';
import StageOperations from 'components/StepsLoader/StageOperations';
import { steps as saveSteps } from 'store/user/actions';

export default function statusUpdater() {
  const stepsController = StepsController();

  function completeCurrentStep(currentStep: string, nextStep: string) {
    if (stepsController.steps[currentStep]?.status !== 'Complete') {
      const nextStageStatus = stepsController.steps[nextStep]?.status;
      const updated = StageOperations.updateStatus(
        [
          { stage: currentStep, status: 'Pending' },
          {
            stage: nextStep,
            status: nextStageStatus === 'Inactive' ? 'Active' : nextStageStatus,
          },
        ],
        stepsController.steps
      );

      stepsController.dispatch(saveSteps(updated));
    }
  }

  return { completeCurrentStep };
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import {
  getFormValidationProps,
  isErrorStatus,
  isPendingStatus,
  isValidStatus,
} from 'components/Forms/Shared/FormUtils';
import ValidationUtils from 'components/Forms/Shared/ValidationUtils';
import Icon, { IconTypes } from 'components/Icon';
import { getToken } from 'configs/AuthService';
import UPLOAD_MAX_SIZE from 'constants/documentUpload';
import AuthContext from 'contexts/auth';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { updateAdmissionForm } from 'store/user/actions';
import ValidationFormType from 'types/enum/ValidationFormType';
import ImageUtils from 'utils/ImageUtils';
import './style.scss';

export type DependantDocumentUploadProps = {
  uploadedFile: (
    nameDocument: string,
    urlDocument: string,
    id: number,
    index: number
  ) => void;
  closeAction: () => void;
  nameDocument: string;
  dependantDocumentMethods: UseFormReturn<any>;
  index: number;
};

export type statesType = {
  default: boolean;
  invalid: boolean;
  valid: boolean;
};

function DependantDocumentUpload({
  dependantDocumentMethods,
  uploadedFile,
  closeAction,
  nameDocument,
  index,
}: DependantDocumentUploadProps) {
  const {
    state: { admissionForm },
    dispatch,
  } = React.useContext(AuthContext);

  const states = {
    default: true,
    invalid: false,
    valid: false,
  };

  const [selectedFile, setSelectedFile] = useState('');
  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState<IconTypes | ''>('');
  const [changeInput, setChangeInput] = useState(states);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(0);
  const [oldNameFile, setOldNameFile] = useState('');
  const [fileName, setFileName] = useState('');

  const { shouldDisable, hasCustomError } = getFormValidationProps(
    `dependants.${index}.urlDocument`,
    admissionForm,
    dependantDocumentMethods
  );

  const verifyNameFile = (
    nameFile: string,
    sizeFile: number,
    handleConfirmFunction: () => void
  ) => {
    const name = nameFile?.slice(-4);
    setSize(sizeFile);

    if (sizeFile > UPLOAD_MAX_SIZE) {
      setMessage('O arquivo deve ter um tamanho de, no máximo, 5MB.');
      setIcon('pngFileIcon');
      setChangeInput({ default: false, invalid: true, valid: false });
      setFileName(nameFile);
      uploadedFile('', '', getDependantId(), index);
    } else if (
      !ImageUtils.isValidFormat(nameFile, ['jpg', 'jpeg', 'png', 'pdf'])
    ) {
      setMessage('O arquivo deve ser do tipo JPG, JPEG, PNG ou PDF.');
      setIcon('pdfFileIcon');
      setChangeInput({ default: false, invalid: true, valid: false });
      setFileName(nameFile);
      uploadedFile('', '', getDependantId(), index);
    } else {
      setIcon(
        name.toLocaleLowerCase() === '.pdf' ? 'pdfFileIcon' : 'pngFileIcon'
      );
      setChangeInput({ default: false, invalid: false, valid: true });
      handleConfirmFunction();
      setFileName(nameFile);
    }
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (nameDocument) {
      setOldNameFile(nameDocument);
    }

    const file = event.target.files![0];

    verifyNameFile(file.name, file.size, () => {
      handleConfirm(file);
    });
    setSelectedFile(file as unknown as string);
  };

  const getDependantId = () =>
    dependantDocumentMethods.getValues(`dependants[${index}]`).id;

  const handleRemove = () => {
    if (isInvalidFileWithoutValidFileSaved()) {
      setChangeInput({ default: true, invalid: false, valid: false });
      uploadedFile(oldNameFile, '', getDependantId(), index);
      return;
    }
    if (isInvalidFileWithValidFileSaved()) {
      setFileName(oldNameFile);
      setChangeInput({ default: false, invalid: false, valid: true });
      setSize(0);
      uploadedFile(oldNameFile, '', getDependantId(), index);
      setMessage('');
    } else {
      closeAction();
      setChangeInput({ default: true, invalid: false, valid: false });
      setFileName(oldNameFile);
      uploadedFile('', '', getDependantId(), index);
      setOldNameFile('');
    }
  };

  const handleConfirm = async (file: File) => {
    const token = getToken();
    const header = {
      Authorization: `Bearer ${token}`,
    };

    const { id } = dependantDocumentMethods.getValues(`dependants[${index}]`);
    const data = new FormData();

    if (id) data.append('id', id);
    data.append('file', file);
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_URL_API}/dependant/document`, data, {
        headers: header,
      })
      .then((res) => {
        uploadedFile(
          res.data.nameDocument,
          res.data.urlDocument,
          res.data.id,
          index
        );
        setChangeInput({ default: false, invalid: false, valid: true });
        setFileName(res.data.nameDocument);
        setMessage(`Arquivo ${res.data.nameDocument} anexado com sucesso.`);
        setOldNameFile('');
        dispatch(
          updateAdmissionForm({
            ...admissionForm,
            name: ValidationFormType.DEPENDANTS,
            validationResults: admissionForm.validationResults.filter(
              (item) => !item.includes(`.${index}.nameDocument`)
            ),
          })
        );
      })
      .catch((err) => {
        if (err) {
          setFileName(file.name);
          setIcon('pngFileIcon');
          setChangeInput({ default: false, invalid: true, valid: false });
          setMessage(
            'Falha no upload! Tente novamente, se o erro persistir, contate o responsável pela sua admissão.'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function isInvalidFileWithoutValidFileSaved() {
    return changeInput.invalid && oldNameFile === '';
  }

  function isInvalidFileWithValidFileSaved() {
    return oldNameFile !== '';
  }

  useEffect(() => {
    if (nameDocument) {
      verifyNameFile(nameDocument, size, () => {});
    }
  }, [nameDocument]);

  function handleFileValidation(fileNameInput: string) {
    if (changeInput.invalid === false) return true;
    return false;
  }

  function getChangeInputClassName(thisChangeInput: statesType) {
    if (thisChangeInput.invalid || hasCustomError) return 'invalid';
    return 'valid';
  }

  const displayUploadDisabled = () => {
    if (
      (isPendingStatus(admissionForm.status) ||
        isValidStatus(admissionForm.status)) &&
      fileName !== ''
    )
      return true;
    if (
      isErrorStatus(admissionForm.status) &&
      !changeInput.default &&
      shouldDisable
    )
      return true;
    return false;
  };

  return (
    <section className="upload-container-dependant">
      {!displayUploadDisabled() && (
        <>
          {changeInput.default && (
            <label
              htmlFor={`uploadInput-${index}`}
              className="docs-upload-container"
            >
              <div className="docs-input">
                <div className="doc-icon">
                  <Icon name="uploadDocsIcon" />
                </div>
                <Controller
                  control={dependantDocumentMethods.control}
                  rules={{
                    required: true,
                    validate: (fileValue: { name: string; url: string }) =>
                      handleFileValidation(fileValue.name),
                  }}
                  name={`dependants[${index}].nameDocument`}
                  render={({ field: {} }) => (
                    <input
                      onChange={(e) => handleFile(e)}
                      type="file"
                      id={`uploadInput-${index}`}
                      className="uploadInput"
                    />
                  )}
                />
              </div>
              <div className="upload-subtitle">
                Selecione um arquivo (.jpg, .png ou .pdf) da sua galeria, ele
                deve ser legível.
              </div>
            </label>
          )}

          {(changeInput.invalid || changeInput.valid) && (
            <label
              htmlFor={`uploadInput-${nameDocument}-${getChangeInputClassName(
                changeInput
              )}`}
              className={`docs-upload-container docs-upload-container-${getChangeInputClassName(
                changeInput
              )}`}
            >
              <div className="docs-input">
                <div className="icons">
                  <div className="doc-icon">
                    <Icon name={icon as IconTypes} />
                    <div className="file-name">
                      <span>{`Documento dependente - ${index + 1}`}</span>
                    </div>
                  </div>
                  {!loading && (
                    <button
                      className="close-icon"
                      type="button"
                      onClick={handleRemove}
                      disabled={shouldDisable}
                    >
                      <Icon name="closeIconValid" />
                    </button>
                  )}
                </div>
                <Controller
                  control={dependantDocumentMethods.control}
                  rules={{
                    required: true,
                    validate: (fileValue: { name: string; url: string }) =>
                      handleFileValidation(fileValue.name),
                  }}
                  name={`dependants[${index}].nameDocument`}
                  render={({ field: {} }) => (
                    <input
                      id={`uploadInput-${nameDocument}-${getChangeInputClassName(
                        changeInput
                      )}`}
                      className="uploadInput"
                      onChange={(e) => handleFile(e)}
                      type="file"
                      disabled={shouldDisable}
                    />
                  )}
                />
              </div>
              {!loading && message && (
                <div className="upload-subtitle">{message}</div>
              )}

              {ValidationUtils.errorCustomValidator(hasCustomError)}
            </label>
          )}
        </>
      )}

      {displayUploadDisabled() && (
        <label
          htmlFor={`uploadInput-${fileName}-valid`}
          className="docs-upload-container docs-upload-container-valid"
        >
          <div className="docs-input disabled">
            <div className="icons">
              <div className="doc-icon">
                <Icon name={icon as IconTypes} />
                <div className="file-name">
                  <span>{`Documento dependente - ${index + 1}`}</span>
                </div>
              </div>
            </div>
          </div>
        </label>
      )}
    </section>
  );
}

export default DependantDocumentUpload;

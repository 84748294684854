import React from 'react';
import './styles.scss';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

function Container({ children, className }: ContainerProps) {
  return (
    <div className={`container-component ${className || ''}`}>{children}</div>
  );
}

export default Container;

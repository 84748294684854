/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileSecondParentage({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const maxLength = 50;

  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'secondParentage',
    admissionForm,
    profileMethods
  );

  return (
    <label className="form-field" htmlFor="secondParentage">
      <span className="label">Filiação 2</span>
      <Controller
        rules={{ maxLength }}
        control={profileMethods.control}
        name="secondParentage"
        render={({ field: { onChange, onBlur } }) => (
          <input
            onBlur={onBlur}
            value={profileMethods.getValues('secondParentage') || ''}
            onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
            type="text"
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            readOnly={shouldDisable}
          />
        )}
      />
      {errors && errors.secondParentage?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileSecondParentage;

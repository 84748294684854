/* eslint-disable jsx-a11y/label-has-associated-control */
import DependantDocumentUpload from 'components/Dropzone/DependantDocumentUpload';
import './styles.scss';
import Icon from 'components/Icon';
import AuthContext from 'contexts/auth';
import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import DependantService from 'services/dependantsService';
import { customStyles } from 'styles/select/customStyles';
import documentValidator from 'utils/documentValidator';
import masks from 'utils/MasksUtils';
import Datepicker from '../Datepicker';
import ValidationUtils from '../ValidationUtils';
import {
  isErrorStatus,
  isPendingStatus,
  getFormValidationProps,
  isValidStatus,
} from '../FormUtils';
import DependantIncludeIR from './DependantIncludedIR';
import DependantName from './DependantName';

type DependantDataProps = {
  dependantsDataMethods: UseFormReturn<any>;
  setOpenModal: (b: boolean) => void;
  setModalData?: any;
  addNewDependant: () => void;
  handleMinus: (n: number) => void;
  fields: Record<'id', string>[];
};

function DependantData({
  dependantsDataMethods,
  setOpenModal,
  fields,
  addNewDependant,
  handleMinus,
  setModalData,
}: DependantDataProps) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { t } = useTranslation('dependants');
  const [enableChangeNumber, setEnableChangeNumber] = useState(false);

  const degreeKinship = [
    { value: 'Filho(a)', label: 'Filho(a)' },
    { value: 'Cônjuge', label: 'Cônjuge' },
  ];

  const { errors, isValid } = dependantsDataMethods.formState;
  const { control } = dependantsDataMethods;

  const uploadedFile = (
    nameDocument: string,
    urlDocument: string,
    id: number,
    index: number
  ) => {
    const dependantsData = dependantsDataMethods.getValues(
      `dependants[${index}]`
    );

    const newDependantsData = {
      ...dependantsData,
      nameDocument,
      id,
      urlDocument,
    };

    dependantsDataMethods.setValue(`dependants[${index}]`, newDependantsData, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const closeFunction = (id: number) => {
    DependantService.removeDocument(id);
  };

  const dateOfBirthFormValidationProps = (dependantIndex: number) =>
    getFormValidationProps(
      `dependants.${dependantIndex}.dateOfBirth`,
      admissionForm,
      dependantsDataMethods
    );

  const cpfFormValidationProps = (dependantIndex: number) =>
    getFormValidationProps(
      `dependants.${dependantIndex}.CPF`,
      admissionForm,
      dependantsDataMethods
    );

  const kinshipFormValidationProps = (dependantIndex: number) =>
    getFormValidationProps(
      `dependants.${dependantIndex}.kinship`,
      admissionForm,
      dependantsDataMethods
    );

  useEffect(() => {
    setEnableChangeNumber(
      !(
        isPendingStatus(admissionForm.status) ||
        isErrorStatus(admissionForm.status) ||
        isValidStatus(admissionForm.status)
      )
    );
  }, [admissionForm.status]);

  return (
    <div className="field-container">
      {fields.map((dependant, dependantIndex) => (
        <div key={dependant.id}>
          {fields.length !== dependantIndex + 1 && (
            <div className="separator" />
          )}

          <Row className="dependent-counter">
            <Col xs={10} sm={6}>
              Dependente {dependantIndex + 1}
            </Col>
            {enableChangeNumber && (
              <Col xs={2} sm={3}>
                <Row className="d-flex justify-content-end">
                  {fields.length > 1 ? (
                    <button
                      onClick={() => handleMinus(dependantIndex)}
                      className="minus-button"
                      type="button"
                    >
                      <Icon name="removeCircleOutline" />
                    </button>
                  ) : null}
                  {fields.length - 1 === dependantIndex.valueOf() &&
                    fields.length < 20 &&
                    isValid && (
                      <button
                        onClick={addNewDependant}
                        className="plus-button ms-2"
                        type="button"
                      >
                        <Icon name="plusIcon" />
                      </button>
                    )}
                  {fields.length - 1 === dependantIndex.valueOf() &&
                    (!isValid || fields.length >= 20) && (
                      <div className="plus-button">
                        <Icon name="plusIconDisabled" />
                      </div>
                    )}
                </Row>
              </Col>
            )}
          </Row>

          {fields.length >= 20 && dependantIndex >= 19 && (
            <div className="dependent-counter">
              <span className="error-text">*Limite de 20 dependentes</span>
            </div>
          )}

          <Row>
            <Col xs={12} sm={6}>
              <DependantName
                dependantsDataMethods={dependantsDataMethods}
                dependantIndex={dependantIndex}
              />
            </Col>

            <Col xs={12} sm={3}>
              <label
                id="dependantsDateOfBirth"
                htmlFor="dependantsDateOfBirth"
                className="form-field"
              >
                <span className="label">Data de Nascimento*</span>
                <Controller
                  control={dependantsDataMethods.control}
                  name={`dependants[${dependantIndex}].dateOfBirth`}
                  rules={{ required: true, minLength: 10 }}
                  render={({ field: { onChange, onBlur } }) => (
                    <Datepicker
                      className={
                        dateOfBirthFormValidationProps(dependantIndex)
                          .hasAnyError
                          ? 'field -input-error date'
                          : 'field -input date'
                      }
                      onBlur={onBlur}
                      onSelect={(date: any) => {
                        onChange(format(date!, 'dd/MM/yyyy'));
                        onBlur();
                      }}
                      dateValue={
                        dependantsDataMethods.getValues(
                          `dependants[${dependantIndex}].dateOfBirth`
                        ) || ''
                      }
                      selected={
                        dependantsDataMethods.watch(
                          `dependants[${dependantIndex}].dateOfBirth`
                        )
                          ? parse(
                              dependantsDataMethods.watch(
                                `dependants[${dependantIndex}].dateOfBirth`
                              ),
                              'dd/MM/yyyy',
                              new Date()
                            )
                          : null
                      }
                      disabled={
                        dateOfBirthFormValidationProps(dependantIndex)
                          .shouldDisable
                      }
                    />
                  )}
                />
                {errors &&
                  errors?.dependants?.at(dependantIndex)?.dateOfBirth?.type ===
                    'minLength' && (
                    <span className="error-text">
                      A data é inválida, por favor revise.
                    </span>
                  )}
                {ValidationUtils.errorViewValidator(
                  errors,
                  errors?.dependants?.at(dependantIndex)?.dateOfBirth
                )}
                {ValidationUtils.errorCustomValidator(
                  dateOfBirthFormValidationProps(dependantIndex).hasCustomError
                )}
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <label
                id="dependantsCPF"
                htmlFor="dependantsCPF"
                className="form-field"
              >
                <span className="label">CPF*</span>
                <Controller
                  control={control}
                  name={`dependants[${dependantIndex}].CPF`}
                  rules={{
                    required: true,
                    minLength: 14,
                    validate: documentValidator.validCPF,
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <input
                      type="text"
                      className={ValidationUtils.errorClass(
                        errors,
                        cpfFormValidationProps(dependantIndex).hasAnyError
                      )}
                      placeholder=""
                      onChange={(e) => onChange(masks.cpf(e.target.value))}
                      value={
                        dependantsDataMethods.getValues(
                          `dependants[${dependantIndex}].CPF`
                        ) || ''
                      }
                      onBlur={onBlur}
                      disabled={
                        cpfFormValidationProps(dependantIndex).shouldDisable
                      }
                    />
                  )}
                />
                {ValidationUtils.errorViewValidator(
                  errors,
                  errors?.dependants?.at(dependantIndex)?.CPF
                )}
                {errors &&
                  (errors.dependants?.at(dependantIndex)?.CPF?.type ===
                    'minLength' ||
                    errors.dependants?.at(dependantIndex)?.CPF?.type ===
                      'validate') && (
                    <span className="error-text">
                      O CPF é inválido, por favor revise.
                    </span>
                  )}
                {ValidationUtils.errorCustomValidator(
                  cpfFormValidationProps(dependantIndex).hasCustomError
                )}
              </label>
            </Col>

            <Col xs={12} sm={3}>
              <label
                id="dependantsRelationship"
                htmlFor="dependantsRelationship"
                className="form-field"
              >
                <span className="label">
                  {t('Kinship Title')}
                  <button
                    type="button"
                    className="question"
                    onClick={() => {
                      setModalData({
                        modalTitle: 'Atenção',
                        icon: 'triangleWarning',
                        title: '',
                        description: t('Kinship Warning'),
                      });
                      setOpenModal(true);
                    }}
                  >
                    <Icon name="question" />
                  </button>
                </span>
                <Controller
                  name={`dependants[${dependantIndex}].kinship`}
                  control={dependantsDataMethods.control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, name, ref, onBlur },
                  }) => (
                    <Select
                      placeholder=""
                      styles={customStyles(
                        kinshipFormValidationProps(dependantIndex).hasAnyError,
                        kinshipFormValidationProps(dependantIndex).shouldDisable
                      )}
                      options={degreeKinship}
                      name={name}
                      value={degreeKinship.find((r) => r.value === value)}
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption.value);
                      }}
                      onBlur={onBlur}
                      isSearchable={
                        !kinshipFormValidationProps(dependantIndex)
                          .shouldDisable
                      }
                      menuIsOpen={
                        kinshipFormValidationProps(dependantIndex).shouldDisable
                          ? false
                          : undefined
                      }
                    />
                  )}
                />
                {ValidationUtils.errorViewValidator(
                  errors,
                  errors?.dependants?.at(dependantIndex)?.kinship
                )}
                {ValidationUtils.errorCustomValidator(
                  kinshipFormValidationProps(dependantIndex).hasCustomError
                )}
              </label>
            </Col>
          </Row>
          <DependantIncludeIR
            dependantIndex={dependantIndex}
            dependantsDataMethods={dependantsDataMethods}
            setOpenModal={setOpenModal}
            setModalData={setModalData}
          />
          <Row className="mt-2">
            <label
              id="dependantsUploadedDocument"
              htmlFor="dependantsUploadedDocument"
              className="form-field"
            >
              <span className="label">
                RG ou, para bebês, certidão de nascimento*
              </span>

              <div className="d-flex">
                <DependantDocumentUpload
                  dependantDocumentMethods={dependantsDataMethods}
                  index={dependantIndex}
                  uploadedFile={uploadedFile}
                  nameDocument={
                    dependantsDataMethods.getValues(
                      `dependants[${dependantIndex}]`
                    ).nameDocument
                  }
                  closeAction={() => {
                    closeFunction(
                      dependantsDataMethods.getValues(
                        `dependants[${dependantIndex}]`
                      ).id
                    );
                  }}
                />
              </div>
            </label>
          </Row>
        </div>
      ))}
    </div>
  );
}

export default DependantData;

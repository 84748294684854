import AuthContext from 'contexts/auth';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseFormReturn } from 'react-hook-form';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function PlaceBirthInput({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const stateOfBirthFormValidationProps = getFormValidationProps(
    'stateOfBirth',
    admissionForm,
    profileMethods
  );

  const cityOfBirthFormValidationProps = getFormValidationProps(
    'cityOfBirth',
    admissionForm,
    profileMethods
  );

  return (
    <Row>
      <Col sm={6}>
        <label className="form-field" htmlFor="stateOfBirth">
          <span className="label">Estado de Nascimento*</span>
          <input
            type="text"
            className={
              stateOfBirthFormValidationProps.hasAnyError
                ? 'field -full-error'
                : 'field -full'
            }
            placeholder=""
            {...profileMethods.register('stateOfBirth', { required: true })}
            disabled={stateOfBirthFormValidationProps.shouldDisable}
          />
          {errors && errors.stateOfBirth?.type === 'required' && (
            <span className="error-text">Esse campo é obrigatório.</span>
          )}
          {stateOfBirthFormValidationProps.hasCustomError && (
            <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
          )}
        </label>
      </Col>

      <Col sm={6}>
        <label className="form-field" htmlFor="cityOfBirth">
          <span className="label">Cidade de Nascimento*</span>
          <input
            type="text"
            className={
              cityOfBirthFormValidationProps.hasAnyError
                ? 'field -full-error'
                : 'field -full'
            }
            placeholder=""
            {...profileMethods.register('cityOfBirth', { required: true })}
            disabled={cityOfBirthFormValidationProps.shouldDisable}
          />
          {errors && errors.cityOfBirth?.type === 'required' && (
            <span className="error-text">Esse campo é obrigatório.</span>
          )}
          {cityOfBirthFormValidationProps.hasCustomError && (
            <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
          )}
        </label>
      </Col>
    </Row>
  );
}

export default PlaceBirthInput;

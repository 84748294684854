import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

const sex = [
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Masculino', label: 'Masculino' },
];

function ProfileSex({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;

  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'sex',
    admissionForm,
    profileMethods
  );

  return (
    <label id="sex" className="form-field" htmlFor="sex">
      <span className="label">Sexo*</span>
      <Controller
        name="sex"
        control={profileMethods.control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name, ref, onBlur } }) => (
          <Select
            placeholder=""
            styles={customStyles(hasAnyError, shouldDisable)}
            options={sex}
            name={name}
            value={sex.find((r) => r.value === value)}
            onChange={(selectedOption: any) => {
              onChange(selectedOption.value);
            }}
            onBlur={onBlur}
            isSearchable={!shouldDisable}
            menuIsOpen={shouldDisable ? false : undefined}
          />
        )}
      />
      {errors && errors.sex?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileSex;

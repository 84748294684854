import React, { useContext, useEffect, useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import './style.scss';
import { CodeProps } from 'types/common/formAction';
import Checkbox from 'components/Checkbox';
import AuthContext from 'contexts/auth';
import { credentials } from 'store/user/actions';
import InputCode from './InputCode';

function FormCode({ codeNumber, resendCode, codeError }: CodeProps) {
  const [code, setCode] = useState('');
  const [checked, setChecked] = useState(false);
  const { dispatch } = useContext(AuthContext);

  function handleCode() {
    if (code.length === 6) {
      codeNumber({ code });
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleCode();
  }

  function toggleCheckbox(toggleState: boolean) {
    setChecked(toggleState);
    dispatch(credentials({ saveCredential: toggleState }));
  }

  return (
    <section>
      <form className="form-code" onSubmit={handleSubmit}>
        <InputCode codeError={codeError} sendCode={setCode} />
        <div className="buttons-container">
          <div className="stay-connected">
            <Checkbox checkedValue={toggleCheckbox} isChecked={checked} />
            <h3 className="checkbox-text">Continuar conectado</h3>
          </div>

          <div className="button">
            <ButtonLink
              componentType="button"
              id="form-code-page"
              code
              block
              onClick={handleCode}
            >
              Entrar
            </ButtonLink>
          </div>
        </div>

        <div className="resend-code">
          <a className="link" href="#" onClick={resendCode}>
            Reenvio do código
          </a>
        </div>
      </form>
    </section>
  );
}

export default FormCode;

import React, { useContext, useEffect, useState } from 'react';
import Modal from 'components/AppModal';
import './styles.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BenefitsService from 'services/benefitsService';
import AuthContext from 'contexts/auth';
import FormSteps from 'components/Forms/Shared/FormSteps';
import StepsHeader from 'components/Header/StepsHeader';
import { BenefitsSteps } from 'constants/texts/BenefitsSteps';
import GenericModal from 'components/Forms/Shared/GenericModal';

type StatisBenefitsResponse = Array<{
  description: string;
  name: string;
  shortDescription: string;
  urlImage: string;
}>;

type MoreInfoResponse = {
  description: string;
  name: string;
  shortDescription: string;
  urlImage: string;
};

function StaticBenefits() {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [staticBenefitInfo, setStaticBenefitInfo] =
    useState<StatisBenefitsResponse>([
      {
        description: '',
        name: '',
        shortDescription: '',
        urlImage: '',
      },
    ]);
  const [moreInfoModal, setMoreInfoModal] = useState<MoreInfoResponse>({
    description: '',
    name: '',
    shortDescription: '',
    urlImage: '',
  });

  const modalData = {
    modalTitle: 'Primeira fase concluída!',
    icon: 'checkboxMarkIcon',
    title: 'Muito bem! Seus dados serão validados pelo nosso time',
    description: 'Em breve você receberá os próximos passos.',
  };

  useEffect(() => {
    BenefitsService.getStaticBenefits().then((response) => {
      setStaticBenefitInfo([...response.data]);
    });
  }, []);

  const handleNavigate = () => {
    const nextStep = state.steps.health;
    const nextPath = nextStep.status === 'Complete' ? '/home' : nextStep.path;
    navigate(nextPath);
  };

  return (
    <div className="static-benefits-container">
      <div className="steps-benetifs">
        <StepsHeader route="exam" type="benefits" />
        <FormSteps steps={BenefitsSteps} active="fixedBenefits" />
      </div>
      <div className="benefits-static">
        <Row className="header-form-info d-none">
          <Col>
            <h1>Conheça os benefícios</h1>
            <p>
              Além dos benefícios que você fez a distribuição de valores, a
              Iteris oferece para todos os seus colaboradores os seguintes
              benefícios fixos.
            </p>
          </Col>
        </Row>
        <Row>
          {staticBenefitInfo.map((staticBenefit, idx) => (
            <Col xs={12} md={6} lg={4} key={idx.valueOf()}>
              <div key={staticBenefit.name} className="static-benefit-item">
                <img className="icon" src={staticBenefit.urlImage} />
                <div className="text-box">
                  <span className="name">{staticBenefit.name}</span>
                  <span className="short">
                    {staticBenefit.shortDescription}
                  </span>
                </div>
                <Button
                  onClick={() => {
                    setMoreInfoModal(staticBenefit);
                    setOpen(true);
                  }}
                  className="item-button"
                  outline
                >
                  Saber mais
                </Button>
                <button
                  onClick={() => {
                    setMoreInfoModal(staticBenefit);
                    setOpen(true);
                  }}
                  type="button"
                  className="item-button-icon"
                >
                  <Icon name="knowmore" />
                </button>
              </div>
            </Col>
          ))}
        </Row>
        <div className="footer">
          <button
            onClick={() => navigate('/beneficios')}
            type="button"
            className="app-button"
          >
            Anterior
          </button>
          <button
            onClick={handleNavigate}
            disabled={!state.approvedMedicalStep}
            type="button"
            className="app-button ms-auto"
          >
            Próximo
          </button>
        </div>
      </div>

      <GenericModal
        close={() => {
          setOpenModal(false);
        }}
        handleConfirm={() => {
          setOpenModal(false);
        }}
        open={openModal}
        data={modalData}
        modalType="confirmation"
      />

      <Modal
        className="each-benefit-modal"
        open={open}
        title={moreInfoModal.name}
        close={() => {
          setOpen(false);
        }}
        confirm={() => {
          setOpen(false);
        }}
      >
        <Row className="modal-item">
          <Row>
            <img
              alt="modal-icon"
              src={moreInfoModal.urlImage}
              className="modal-icon"
            />
          </Row>
          <Row>
            <span className="modal-description">
              {moreInfoModal.description}
            </span>
          </Row>
        </Row>
      </Modal>
    </div>
  );
}

export default StaticBenefits;

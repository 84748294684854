import AuthContext from 'contexts/auth';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function DocumentRegistrationVote({
  documentsDataMethods,
}: {
  documentsDataMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;

  const maxLength = {
    number: 15,
    zone: 5,
  };

  const voteRegistrationNumberFormValidationProps = getFormValidationProps(
    'voteRegistration.number',
    admissionForm,
    documentsDataMethods
  );

  const voteRegistrationAreaFormValidationProps = getFormValidationProps(
    'voteRegistration.area',
    admissionForm,
    documentsDataMethods
  );

  const voteRegistrationSectionFormValidationProps = getFormValidationProps(
    'voteRegistration.section',
    admissionForm,
    documentsDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorViewValidator(errorValidation: FieldError | undefined) {
    return errors && errorValidation?.type === 'required' ? (
      <span className="error-text">Esse campo é obrigatório.</span>
    ) : null;
  }

  function errorClassMedium(errorValidation: FieldError | undefined) {
    return errors && errorValidation
      ? 'field -input-error-medium'
      : 'field -input-medium';
  }

  function errorCustomValidator(hasCustomError: boolean) {
    return (
      hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )
    );
  }

  return (
    <>
      <div className="sub-form-title">Título de Eleitor</div>
      <Row>
        <Col xs={12} sm={3}>
          <label
            id="voteRegistrationNumber"
            className="form-field"
            htmlFor="voteRegistration.number"
          >
            <span className="label">Número*</span>
            <Controller
              control={documentsDataMethods.control}
              name="voteRegistration.number"
              rules={{ required: true, maxLength: maxLength.number }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(masks.number(e.target.value))}
                  className={errorClass(
                    voteRegistrationNumberFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues('voteRegistration.number') ||
                    ''
                  }
                  disabled={
                    voteRegistrationNumberFormValidationProps.shouldDisable
                  }
                />
              )}
            />
            {errors && errors.voteRegistration?.number?.type === 'maxLength' ? (
              <span className="error-text">
                Precisa ter até {maxLength.number} caracteres.
              </span>
            ) : null}
            {errorViewValidator(errors.voteRegistration?.number)}
            {errorCustomValidator(
              voteRegistrationNumberFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="voteRegistrationArea"
            className="form-field"
            htmlFor="voteRegistration.area"
          >
            <span className="label">Zona*</span>
            <Controller
              control={documentsDataMethods.control}
              name="voteRegistration.area"
              rules={{ required: true, maxLength: maxLength.zone }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(masks.number(e.target.value))}
                  className={errorClass(
                    voteRegistrationAreaFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues('voteRegistration.area') ||
                    ''
                  }
                  disabled={
                    voteRegistrationAreaFormValidationProps.shouldDisable
                  }
                />
              )}
            />
            {errorViewValidator(errors.voteRegistration?.area)}
            {errors && errors.voteRegistration?.area?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.zone} caracteres.
              </span>
            )}
            {errorCustomValidator(
              voteRegistrationAreaFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="voteRegistrationSection"
            className="form-field"
            htmlFor="voteRegistration.section"
          >
            <span className="label">Seção*</span>
            <Controller
              control={documentsDataMethods.control}
              name="voteRegistration.section"
              rules={{ required: true, maxLength: maxLength.zone }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(masks.number(e.target.value))}
                  className={errorClass(
                    voteRegistrationSectionFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues(
                      'voteRegistration.section'
                    ) || ''
                  }
                  disabled={
                    voteRegistrationSectionFormValidationProps.shouldDisable
                  }
                />
              )}
            />
            {errors &&
              errors.voteRegistration?.section?.type === 'maxLength' && (
                <span className="error-text">
                  Precisa ter até {maxLength.zone} caracteres.
                </span>
              )}
            {errorViewValidator(errors.voteRegistration?.section)}
            {errorCustomValidator(
              voteRegistrationSectionFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
    </>
  );
}

export default DocumentRegistrationVote;

type CardProps = {
  title: string;
  url: string;
};

// eslint-disable-next-line import/prefer-default-export
export const HOME_PRESENTATION: Array<CardProps> = [
  {
    title: 'card.title.site',
    url: 'http://www.iteris.com.br',
  },
  {
    title: 'card.title.comunidades',
    url: 'https://conteudo.iteris.com.br/onboarding-comunidades-de-pratica',
  },
  {
    title: 'card.title.manifesto',
    url: 'https://conteudo.iteris.com.br/onboarding-manifesto',
  },
  {
    title: 'card.title.valores',
    url: 'https://conteudo.iteris.com.br/onboarding-valores',
  },
  {
    title: 'card.title.instagram',
    url: 'https://www.instagram.com/iteris/',
  },
  {
    title: 'card.title.linkedin',
    url: 'https://www.linkedin.com/company/iteris-consultoria-e-software/',
  },
];

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepsController from 'components/StateController/StepsController';
import StageOperations from 'components/StepsLoader/StageOperations';
import statusUpdater from 'hooks/statusUpdater';
import StagesService from 'services/stagesService';
import NexStepFormAdmissionType from 'types/enum/NexStepFormAdmissionType';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import { formChanged } from 'components/Forms/Shared/FormUtils';
import ValidationFormType from 'types/enum/ValidationFormType';
import Banking from './Banking/Banking';
import Documents from './Documents';
import { infoPJ } from './PJ';
import Profile from './Profile/Profile';
import Residential from './Residential';
import Attachments from './Attachments';
import FormSteps from '../Shared/FormSteps';
import GenericModal from '../Shared/GenericModal';

function FormPJ() {
  const navigate = useNavigate();
  const stepsController = StepsController();
  const [stepName, setStepName] = useState(ValidationFormType.PROFILE);
  const { completeCurrentStep } = statusUpdater();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const renderForm = (form: ValidationFormType) => {
    const formMap: Partial<Record<ValidationFormType, JSX.Element>> = {
      PROFILE: <Profile handleNext={handleNext} />,
      RESIDENTIAL: (
        <Residential handlePrevious={handlePrevious} handleNext={handleNext} />
      ),
      DOCUMENTS: (
        <Documents
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          formChanged={formChanged}
        />
      ),
      BANKING: (
        <Banking
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          formChanged={formChanged}
        />
      ),
      ATTACHMENTS: (
        <Attachments
          handlePrevious={handlePrevious}
          handleConfirm={handleConfirm}
        />
      ),
    };

    return formMap[form] || formMap.PROFILE;
  };

  const handleNext = () => {
    const idx = infoPJ.findIndex((s) => s.name === stepName);
    setStepName(infoPJ[idx + 1].name);
  };

  const handlePrevious = () => {
    const idx = infoPJ.findIndex((s) => s.name === stepName);
    setStepName(infoPJ[idx - 1].name);
  };

  async function handleConfirm() {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await FormAnswerService.updatePendingStatus(
      FormAnswerType.ATTACHMENT_PJ
    ).catch(() => {
      setLoading(false);
      setModalData({
        modalTitle: 'Falha na operação!',
        icon: 'cancelIcon',
        title: 'Revise seus dados.',
        description: 'Algo deu errado ao tentar avançar para o próximo passo.',
      });
    });

    await StagesService.saveNextStepFormAdmission({
      nextStepFormAdmissionType: NexStepFormAdmissionType.PJ,
    })
      .then(() => {
        const currentStage = 'admission';
        const nextActiveStage = StageOperations.getNextStage(
          currentStage,
          'PJ'
        );
        const nextStep = stepsController.steps[nextActiveStage];
        const nextPath =
          nextStep.status === 'Complete' ? '/home' : nextStep.path;

        completeCurrentStep(currentStage, nextActiveStage);

        navigate(nextPath);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  }

  return (
    <div>
      <FormSteps steps={infoPJ} active={stepName as ValidationFormType} />

      <main className="admission-form">{renderForm(stepName)}</main>
      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={() => {
          setOpenModal(false);
        }}
        close={() => {
          setOpenModal(false);
        }}
      />
    </div>
  );
}

export default FormPJ;

import React, { useEffect, useState } from 'react';
import { httpStatus } from 'constants/httpStatus';
import './style.scss';

export type CodeProps = {
  codeError: string;
  sendCode: any;
};

function InputCode({ codeError, sendCode }: CodeProps) {
  const [code, setCode] = useState(new Array(6).fill(''));

  function focusOnInput(element: any) {
    const val = element.value;
    element.focus();
    setTimeout(() => val);
  }

  const handleInput = (
    element: EventTarget & HTMLInputElement,
    index: number
  ) => {
    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.value === null || (element.value === '' && index > 0)) {
      focusOnInput(element.previousElementSibling);
    } else if (index < 5) {
      focusOnInput(element.nextElementSibling);
    }
  };

  const handleChange = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      event.code === 'Backspace' &&
      event.currentTarget.value === '' &&
      index > 0
    ) {
      focusOnInput(event.currentTarget.previousElementSibling);
    }
  };

  const paste = (event: any) => {
    event.preventDefault();
    let data = event.clipboardData.getData('Text');
    data = data.toString().split('');

    if (data.length === 6) {
      setCode(data);
    }
  };

  useEffect(() => {
    sendCode(code.join(''));

    if (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !(window as any).MSStream
    ) {
      const inputs = document.querySelectorAll('input[type="number"]');
      for (let i = 0; i < inputs.length; i += 1)
        inputs[i].setAttribute('pattern', '\\d*');
    }
  }, [code]);

  return (
    <div className="input-code-container">
      <div className="otp-container">
        {code.map((data, index) => (
          <input
            autoComplete="off"
            className={`otp-input${
              codeError === httpStatus[401] ? ' -error' : ''
            }`}
            type="number"
            name="code"
            key={index.valueOf()}
            value={data}
            onChange={(e) => handleInput(e.target, index)}
            onKeyDown={(e) => handleChange(e, index)}
            onKeyPress={(e) => {
              data.length === 1 && e.preventDefault();
            }}
            onPaste={(e) => {
              paste(e);
            }}
          />
        ))}
      </div>
      {codeError === httpStatus.default ? (
        <span className="form-error">
          * Código incorreto, verifique o código enviado, ou solicite um novo
        </span>
      ) : null}
    </div>
  );
}

export default InputCode;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RedirectLogin from 'components/RedirectLogin';
import Login from 'pages/Login';
import Logout from 'components/Logout';

function SignRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<RedirectLogin />} />
    </Routes>
  );
}

export default SignRoutes;

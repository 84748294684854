/* eslint-disable import/prefer-default-export */
export const stateTexts: any = {
  valid: {
    title: 'Oba, seu código já chegou!',
    description: 'Insira os seis dígitos encaminhados para seu e-mail aqui.',
    emoji: 'glassesEmoji',
  },
  unauthorized: {
    title: 'Seu Código expirou.',
    description: 'Solicite o envio de um novo código para seu e-mail.',
    emoji: 'expiredEmoji',
  },
};

export const defaultState = 'valid';

import React, { useEffect, useState } from 'react';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import axios from 'axios';
import {
  getFormValidationProps,
  isErrorStatus,
  isPendingStatus,
  isValidStatus,
} from 'components/Forms/Shared/FormUtils';
import Icon, { IconTypes } from 'components/Icon';
import { getToken } from 'configs/AuthService';
import UPLOAD_MAX_SIZE from 'constants/documentUpload';
import AuthContext from 'contexts/auth';
import FormAnswerType from 'types/enum/FormAnswerType';
import ImageUtils from 'utils/ImageUtils';
import './style.scss';

export type DocumentProps = {
  uploadedFile: (name: string, url: string, index: number) => void;
  formAnswerType: FormAnswerType;
  fieldName: string;
  removeFile: () => void;
  inputRemoveControl: number;
  index: number;
  setInputRemoveControl: React.Dispatch<React.SetStateAction<number>>;
  isRequired?: boolean;
  control: Control<any, any>;
  errorComponent?: JSX.Element;
  defaultName?: string;
  setUploadError: (b: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  defaultUrl: string;
  documentAttachmentMethods: UseFormReturn<any, any>;
  documentPlaceholderText: string;
};

export type statesType = {
  default: boolean;
  invalid: boolean;
  valid: boolean;
};

function DocumentUpload({
  uploadedFile,
  formAnswerType,
  fieldName,
  control,
  removeFile,
  errorComponent,
  defaultName,
  inputRemoveControl,
  index,
  isRequired,
  setInputRemoveControl,
  setUploadError,
  loading,
  setLoading,
  defaultUrl,
  documentAttachmentMethods,
  documentPlaceholderText,
}: DocumentProps) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const states = {
    default: true,
    invalid: false,
    valid: false,
  };

  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [icon, setIcon] = useState<IconTypes | ''>('');
  const [iconDisabled, setIconDisabled] = useState<IconTypes | ''>('');
  const [changeInput, setChangeInput] = useState(states);
  const [size, setSize] = useState(0);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [oldFile, setOldFile] = useState({
    name: '',
    url: '',
  });

  const { shouldDisable, hasModified } = getFormValidationProps(
    `${fieldName}`,
    admissionForm,
    documentAttachmentMethods
  );

  const { status } = admissionForm;
  const documentName = `${fieldName}[${index}]`;

  const validateFile = (nameFile: string, sizeFile: number): boolean => {
    const name = nameFile?.slice(-4);
    setSize(sizeFile);
    if (sizeFile > UPLOAD_MAX_SIZE) {
      setFileName(nameFile);
      setErrorMessage('O arquivo selecionado não pode ser maior que 5Mb.');
      setIcon('pngFileIcon');
      setIconDisabled('pngFileIcon');
      setChangeInput({ default: false, invalid: true, valid: false });
      setUploadError(true);
      uploadedFile('', '', index);

      return false;
    }

    if (!ImageUtils.isValidFormat(nameFile, ['jpg', 'jpeg', 'png', 'pdf'])) {
      setFileName(nameFile);
      setErrorMessage(
        'Selecione um formato imagem válido, JPG, PNG ou PDF, a imagem deve ser nítida.'
      );
      setIcon('pdfFileIcon');
      setIconDisabled('pdfFileDisabledIcon');
      setChangeInput({ default: false, invalid: true, valid: false });
      setUploadError(true);
      uploadedFile('', '', index);

      return false;
    }

    setFileName(nameFile);
    setIcon(
      name.toLocaleLowerCase() === '.pdf' ? 'pdfFileIcon' : 'pngFileIcon'
    );
    setIconDisabled(
      name.toLocaleLowerCase() === '.pdf'
        ? 'pdfFileDisabledIcon'
        : 'pngFileIcon'
    );
    setChangeInput({ default: false, invalid: false, valid: true });
    setUploadError(false);
    setOldFile({
      name: '',
      url: '',
    });
    return true;
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (defaultName) {
      const oldFileUpdated = { ...oldFile };
      oldFileUpdated.name = defaultName;
      oldFileUpdated.url = defaultUrl;
      setOldFile(oldFileUpdated);
    }
    if (!validateFile(file.name, file.size)) return;
    setSelectedFile(file);
    handleConfirm(file);
  };

  const handleClose = () => {
    setUploadError(false);
    if (changeInput.invalid && oldFile.name === '' && oldFile.url === '') {
      setChangeInput({ default: true, invalid: false, valid: false });
      uploadedFile(oldFile.name, oldFile.url, index);
    }
    if (oldFile.name !== '' && oldFile.url !== '') {
      uploadedFile(oldFile.name, oldFile.url, index);
      validateFile(oldFile.name, 0);
    } else {
      removeFile();
      setOldFile({
        name: '',
        url: '',
      });
    }
  };

  useEffect(() => {
    if (inputRemoveControl === index) {
      setChangeInput(states);
    }
    return function cleanup() {
      setInputRemoveControl(-1);
    };
  }, [inputRemoveControl]);

  const handleConfirm = async (file: File) => {
    const token = getToken();
    const header = {
      Authorization: `Bearer ${token}`,
    };

    const savedDocumentName = defaultName || '';

    const data = new FormData();
    data.append('formAnswerType', formAnswerType);
    data.append('documentName', documentName.split('[')[0]);
    data.append('file', file, file.name);
    data.append('savedDocumentName', savedDocumentName);

    setLoadingLocal(true);
    setLoading(true);
    const name = file.name?.slice(-4);
    axios
      .post(`${process.env.REACT_APP_URL_API}/attachment/document`, data, {
        headers: header,
      })
      .then((res) => {
        uploadedFile(res.data.name, res.data.url, index);
        setMessage(`Arquivo ${name} anexado com sucesso.`);
      })
      .catch((err) => {
        if (err) {
          setFileName(file.name);
          setIcon('pngFileIcon');
          setIconDisabled('pngFileIcon');
          setChangeInput({ default: false, invalid: true, valid: false });
          setErrorMessage(
            'Falha no upload! Tente novamente, se o erro persistir, contate o responsável pela sua admissão.'
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setLoadingLocal(false);
      });
  };

  useEffect(() => {
    if (defaultName) {
      validateFile(defaultName, size);
    }
  }, [defaultName]);

  function getChangeInputClassName(thisChangeInput: statesType) {
    if (isErrorStatus(status) && !shouldDisable && !hasModified)
      return 'invalid';
    if (thisChangeInput.invalid) return 'invalid';
    return 'valid';
  }

  function handleFileValidation(fileNameInput: string) {
    if (isRequired) return !!fileNameInput;
    if (changeInput.invalid === false) return true;
    return false;
  }

  const displayUploadInput = () => {
    if (!changeInput.default) return false;
    if (!isRequired && shouldDisable) return false;
    if (isRequired && shouldDisable && !hasModified) return false;
    return true;
  };

  const displayUploadDisabled = () => {
    if ((isPendingStatus(status) || isValidStatus(status)) && fileName !== '')
      return true;
    if (isErrorStatus(status) && !changeInput.default && shouldDisable)
      return true;
    return false;
  };

  const displayMessageOptional = () => {
    if (
      (isValidStatus(status) ||
        isPendingStatus(status) ||
        isErrorStatus(status)) &&
      !isRequired &&
      shouldDisable &&
      fileName === ''
    )
      return true;
    return false;
  };

  return (
    <section className="upload-container">
      {displayUploadInput() && (
        <label
          htmlFor={`uploadInput-${documentName}`}
          className="docs-upload-container"
        >
          <div className={loading ? 'docs-input disabled' : 'docs-input'}>
            <div className="doc-icon">
              <Icon
                name={
                  (!loading
                    ? 'uploadDocsIcon'
                    : 'uploadDisabledDocsIcon') as IconTypes
                }
              />
            </div>

            <Controller
              control={control}
              name={documentName}
              rules={{
                validate: (fileValue: { name: string; url: string }) =>
                  handleFileValidation(fileValue.name),
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input
                  name={documentName}
                  onChange={(e) => handleFile(e)}
                  type="file"
                  id={`uploadInput-${documentName}`}
                  className="uploadInput"
                  accept=".pdf,.jpg,.jpeg,.png"
                  disabled={loading}
                />
              )}
            />
          </div>
          <div
            className={
              !loading ? 'upload-subtitle' : 'upload-subtitle disabled'
            }
          >
            Selecione um arquivo (.jpg, .png ou .pdf) da sua galeria, ele deve
            ser legível.
          </div>
        </label>
      )}

      {(changeInput.invalid || changeInput.valid) &&
        (!shouldDisable || hasModified) && (
          <label
            htmlFor={`uploadInput-${documentName}-${getChangeInputClassName(
              changeInput
            )}`}
            className={`docs-upload-container docs-upload-container-${getChangeInputClassName(
              changeInput
            )}`}
          >
            <div className={loading ? 'docs-input disabled' : 'docs-input'}>
              <div className="icons">
                <div className="doc-icon">
                  <Icon name={(!loading ? icon : iconDisabled) as IconTypes} />
                  <div
                    className={!loading ? 'file-name' : 'file-name disabled'}
                  >
                    <span>{documentPlaceholderText}</span>
                  </div>
                </div>
                {!loadingLocal && (
                  <button
                    className="close-icon"
                    type="button"
                    onClick={handleClose}
                    disabled={loading}
                  >
                    <Icon
                      name={
                        (!loading
                          ? 'docCloseError'
                          : 'docCloseErrorDisabled') as IconTypes
                      }
                    />
                  </button>
                )}
              </div>

              <Controller
                control={control}
                name={documentName}
                rules={{
                  validate: (fileValue: { name: string; url: string }) =>
                    handleFileValidation(fileValue.name),
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    name={documentName}
                    id={`uploadInput-${documentName}-${getChangeInputClassName(
                      changeInput
                    )}`}
                    className="uploadInput"
                    onChange={(e) => handleFile(e)}
                    type="file"
                    disabled={loading}
                  />
                )}
              />
            </div>
            {changeInput.invalid && (
              <div
                className={
                  !loading ? 'upload-subtitle' : 'upload-subtitle disabled'
                }
              >
                {errorMessage}
              </div>
            )}

            {!loadingLocal && changeInput.valid && (
              <div
                className={
                  !loading
                    ? 'upload-subtitle-success'
                    : 'upload-subtitle-success disabled'
                }
              >
                {message}
              </div>
            )}
          </label>
        )}

      {displayUploadDisabled() && (
        <label
          htmlFor={`uploadInput-${documentName}-valid`}
          className="docs-upload-container docs-upload-container-valid"
        >
          <div className="docs-input disabled">
            <div className="icons">
              <div className="doc-icon">
                <Icon name={icon as IconTypes} />
                <div className="file-name">
                  <span>{documentPlaceholderText}</span>
                </div>
              </div>
            </div>
          </div>
        </label>
      )}

      {displayMessageOptional() && (
        <label
          htmlFor={`uploadInput-${documentName}`}
          className="docs-upload-container mb-2"
        >
          <div
            className={
              !loading ? 'upload-subtitle' : 'upload-subtitle disabled'
            }
          >
            Documento opcional não enviado no cadastro e não precisa de
            validação.
          </div>
        </label>
      )}

      {errorComponent}
    </section>
  );
}

export default DocumentUpload;

import React from 'react';
import { FieldError } from 'react-hook-form';

const CUSTOM_ERROR_MESSAGE = 'Campo precisa de revisão.';

function errorMaxLengthValidator(
  errors: any,
  errorValidation: FieldError | undefined,
  maxLength: any
) {
  return errors && errorValidation?.type === 'maxLength' ? (
    <span className="error-text">
      Precisa ter até {maxLength.maxLengthName} caracteres.
    </span>
  ) : null;
}

function errorClass(errors: any, errorValidation: FieldError | undefined) {
  return errors && errorValidation ? 'field -input-error' : 'field -input';
}

function errorCustomValidator(hasCustomError: boolean) {
  return (
    hasCustomError && <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
  );
}

function errorViewValidator(
  errors: any,
  errorValidation: FieldError | undefined
) {
  return errors && errorValidation?.type === 'required' ? (
    <span className="error-text">Esse campo é obrigatório.</span>
  ) : null;
}

export default {
  CUSTOM_ERROR_MESSAGE,
  errorClass,
  errorCustomValidator,
  errorViewValidator,
  errorMaxLengthValidator,
};

import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileCountryOfBirth({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const maxLength = 50;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'countryOfBirth',
    admissionForm,
    profileMethods
  );

  return (
    <label id="countryOfBirth" className="form-field" htmlFor="countryOfBirth">
      <span className="label">País de Nascimento*</span>
      <Controller
        control={profileMethods.control}
        name="countryOfBirth"
        rules={{ required: true, maxLength }}
        render={({ field: { onBlur, onChange } }) => (
          <input
            value={profileMethods.getValues('countryOfBirth') || ''}
            onBlur={onBlur}
            onChange={(e) => {
              profileMethods.setValue('countryOfBirth', e.target.value, {
                shouldValidate: true,
              });
              onChange(masks.onlyLetters(e.target.value));
            }}
            type="text"
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            readOnly={shouldDisable}
          />
        )}
      />
      {errors && errors.countryOfBirth?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}

      {errors && errors.countryOfBirth?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}

      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileCountryOfBirth;

import React, { useEffect, useState } from 'react';
import './styles.scss';
import BenefitConfirmation from 'types/models/BenefitConfirmation';

const resume = [
  { name: 'Alelo Alimentação', value: -600 },
  { name: 'Alelo Refeição', value: -100 },
  { name: 'Alelo Flex Car', value: -100 },
  { name: 'Assistência Médica', value: -550 },
  { name: 'Dependentes: 2', value: -200 },
  { name: 'Upgrade', value: -50 },
];

export type BenefitsConfirmationProps = {
  defaultValue: number;
  spentValue: Function;
  benefitConfirmation: BenefitConfirmation;
};

function BenefitsConfirmation({
  defaultValue,
  spentValue,
  benefitConfirmation,
}: BenefitsConfirmationProps) {
  const [valueSpent, setvalueSpent] = useState(0);

  useEffect(() => {
    const result = resume.reduce(
      (total, currentValue) => total + currentValue.value,
      0
    );

    setvalueSpent(defaultValue + result);
    spentValue(defaultValue + result);
  }, [resume]);

  return (
    <div className="benefits-confirmation">
      <div className="benefit-total">
        <h3 className="main-title">Carteira de Benefícios</h3>
        <div className="benefits-value">
          <span> R${defaultValue.toFixed(2).replace('.', ',')}</span>
        </div>
      </div>

      <table className="benefits-table">
        {benefitConfirmation.benefits.map((object, i) => (
          <tbody key={i.valueOf()}>
            <tr className="benefits-row">
              <td className="benefits-datacell">{object.name}</td>
              <td className="benefits-datacell-values">
                - R$ {Math.abs(object.amountTotal).toFixed(2).replace('.', ',')}
              </td>
            </tr>
          </tbody>
        ))}
        <tfoot>
          <tr
            className={
              benefitConfirmation.payrollDiscount > 0 ? '' : 'no-display'
            }
          >
            <td className="benefits-datacell-total"> Desconto em folha:</td>
            <td className="benefits-datacell-total-values">
              R${' '}
              {Math.abs(benefitConfirmation.payrollDiscount)
                .toFixed(2)
                .replace('.', ',')}
            </td>
          </tr>
        </tfoot>
      </table>

      <p className="text-benefits">
        Verifique os valores da sua distribuição de benefícios. Esses valores
        podem ser alterados a cada 3 meses. Alterações nos planos de saúde e
        odontológico só serão feitos na renovação do contrato com a Amil.
      </p>
    </div>
  );
}

export default BenefitsConfirmation;

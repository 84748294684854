import React, { useContext, useEffect, useState } from 'react';
import Icon from 'components/Icon';
import moment from 'moment';
import statusUpdater from 'hooks/statusUpdater';
import { useNavigate } from 'react-router-dom';
import MedicalExamService from 'services/medicalExamService';
import StagesService from 'services/stagesService';
import StageAdmissionStatus from 'types/enum/StageAdmissionStatus';
import StageAdmissionType from 'types/enum/StageAdmissionType';
import './styles.scss';
import EntranceExamModal from 'components/EntranceExam/EntranceExamModal';
import ExamUpload from 'components/Dropzone/ExamUpload';
import { Col, Row } from 'react-bootstrap';
import WarningButton from 'components/Button/WarningButton';
import AuthContext from 'contexts/auth';
import StepsHeader from 'components/Header/StepsHeader';
import GenericModal from 'components/Forms/Shared/GenericModal';
import StepsController from 'components/StateController/StepsController';

type EntraceExamResponse = {
  date: Date;
  time: string;
  address: string;
  attachmentSigned: string;
  stageAdmissionStatus: StageAdmissionStatus | null;
  observation: string;
};

function EntranceExam() {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);

  const stepsController = StepsController();
  const isInvalid = () => stepsController.steps.health?.status === 'Error';

  const { completeCurrentStep } = statusUpdater();
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [disableUploadComponent, setDisableUploadComponent] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const [examInfos, setExamInfos] = useState<EntraceExamResponse>({
    date: new Date(),
    time: '',
    address: '',
    attachmentSigned: '',
    stageAdmissionStatus: null,
    observation: '',
  });

  const formatTime = (time: string) => {
    const timeFormat = time.split(':');
    return `${timeFormat[0]}:${timeFormat[1]}`;
  };

  useEffect(() => {
    MedicalExamService.getCurrentMedicalExam().then((response) => {
      setExamInfos({
        ...response.data,
        time: formatTime(response.data.time),
      });

      const stageAdmissionStatusIsCompleted =
        response.data.stageAdmissionStatus === StageAdmissionStatus.COMPLETE;

      const stageAdmissionStatusIsPending =
        response.data.stageAdmissionStatus === StageAdmissionStatus.PENDING;

      const stageAdmissionStatusIsError =
        response.data.stageAdmissionStatus === StageAdmissionStatus.ERROR;

      if (response.data.attachmentSigned || stageAdmissionStatusIsCompleted) {
        setDisableNextButton(false);
      }

      if (stageAdmissionStatusIsError) setDisableNextButton(true);

      if (stageAdmissionStatusIsCompleted || stageAdmissionStatusIsPending) {
        setDisableUploadComponent(true);
      } else {
        setDisableUploadComponent(false);
      }
    });
  }, []);

  const uploadedFile = (nameUploaded: string) => {
    setExamInfos({ ...examInfos, attachmentSigned: nameUploaded });
  };

  const closeFunction = () => {
    MedicalExamService.removeDocument();
    setDisableNextButton(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await StagesService.saveStageAdmission({
      stageAdmissionType: StageAdmissionType.ENTRANCE_EXAM,
    })
      .then(() => {
        completeCurrentStep('health', 'profile');
        const nextStep = state.steps.profile;
        const nextPath =
          nextStep.status === 'Complete' ? '/home' : nextStep.path;
        navigate(nextPath);
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  function showWarningButton() {
    const stage = state.stageAdmission.find(
      (e) => e.name === 'ENTRANCE_EXAM' && e.status === 'ERROR'
    );

    const text = stage?.observation;

    return text || '';
  }

  return (
    <div className="exam-container">
      <div className="steps-exam">
        <StepsHeader route="about" type="health" />
      </div>
      <main className="exam-form">
        <h3>Agendamento do Exame</h3>
        <Row>
          <Col xs={12} sm={6}>
            <div className="title">Data do Exame</div>
            <input
              type="text"
              disabled
              value={
                examInfos.date
                  ? moment(examInfos.date).format('DD/MM/yyyy')
                  : ''
              }
            />
          </Col>
          <Col xs={12} sm={6}>
            <div className="title">
              Horário (atendimento por ordem de chegada)
            </div>
            <input
              type="text"
              disabled
              value={examInfos.time ? `${examInfos.time} hs` : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="title">Local</div>
            <input
              type="text"
              disabled
              value={examInfos.address ? examInfos.address : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="title">Observação</div>
            <textarea disabled>
              {examInfos.observation
                ? examInfos.observation
                : ' Levar documento com foto e guia de encaminhamento do exame, disponível para download no botão abaixo.'}
            </textarea>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <button
              onClick={() => {
                setOpen(true);
              }}
              type="button"
              className="app-button"
            >
              <span className="me-3">Download da guia do exame</span>
              <Icon name="downloadIconWhite" />
            </button>
          </Col>
          <Col xs={12} sm={6}>
            <ExamUpload
              setDisableNextButton={setDisableNextButton}
              nameDocument={examInfos.attachmentSigned}
              disableUploadComponent={disableUploadComponent}
              id="entrance-exam"
              uploadedFile={uploadedFile}
              closeAction={() => closeFunction()}
              invalid={isInvalid()}
            />
          </Col>
        </Row>

        <section className="page-footer">
          <button
            onClick={() => handleConfirm()}
            disabled={disableNextButton}
            type="button"
            className="app-button"
          >
            Próximo
          </button>
        </section>
      </main>
      <EntranceExamModal open={open} setOpen={setOpen} />
      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        loading={loading}
        open={openModal}
        modalType="savingData"
        data={modalData}
        close={() => setOpenModal(false)}
        handleConfirm={() => setOpenModal(false)}
      />
      {showWarningButton().length > 0 && (
        <WarningButton step="3" observationText={showWarningButton()} />
      )}
    </div>
  );
}

export default EntranceExam;

import React from 'react';
import { cpf, cnpj } from 'cpf-cnpj-validator';

const replaceField = (field: string) =>
  field.replace('-', '').replaceAll('.', '').replace('/', '');

const validCPF = async (num: string) => cpf.isValid(num);

const validCNPJ = async (num: string) => cnpj.isValid(num);

const documentValidator = { validCPF, validCNPJ, replaceField };

export default documentValidator;

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PresentationModel from 'types/models/PresentationForm';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import { useNavigate } from 'react-router-dom';
import StagesService from 'services/stagesService';
import StageAdmissionType from 'types/enum/StageAdmissionType';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/auth';
import StepsHeader from 'components/Header/StepsHeader';
import './styles.scss';
import GenericModal from 'components/Forms/Shared/GenericModal';

function Presentation() {
  const { t } = useTranslation('profile');
  const { state } = useContext(AuthContext);
  const { register, formState, handleSubmit, ...useFormMethods } = useForm({
    mode: 'onBlur',
  });
  const [disableButton, setDisable] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { errors } = formState;
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    modalTitle: 'Confirmar Envio do Formulário',
    icon: 'triangleWarning',
    title: 'Você tem certeza de que deseja enviar as informações?',
    description: '',
  });

  const errorMessages = new Map<string, string>([
    ['maxLength', 'Precisa ter até 300 caracteres.'],
    ['required', 'Esse campo é obrigatório.'],
  ]);

  useEffect(() => {
    FormAnswerService.getAllAnswers<PresentationModel>('PRESENTATION').then(
      (response) => {
        const allAnswers = response.data;

        if (allAnswers) {
          useFormMethods.setValue('curiosity', allAnswers?.curiosity || '', {
            shouldValidate: true,
          });
        }

        setName(state.name);
      }
    );
  }, []);

  function submitForm(data: object) {
    setOpenModal(true);
    setLoading(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    FormAnswerService.saveForm({
      dataRequest: data,
      type: FormAnswerType.PRESENTATION,
    }).catch(() => {
      setLoading(false);
      setOpenModal(true);
      setDisable(false);
      setModalData({
        modalTitle: 'Falha na operação!',
        icon: 'cancelIcon',
        title: 'Algo deu errado ao enviar as informações',
        description: 'Por favor, tente novamente em alguns instantes.',
      });
    });

    StagesService.saveStageAdmission({
      stageAdmissionType: StageAdmissionType.PRESENTATION,
    }).then(() => {
      setLoading(false);
      setOpenModal(true);
      setModalData({
        modalTitle: 'Uhuuuu, acabouuu!',
        icon: 'greenCheckCircle',
        title:
          'Agora que você concluiu todas as fases do envio das documentações',
        description:
          ' E nos contou um pouco mais sobre quem é você, falta pouquíssimo para dar o start na sua jornada aqui com a gente.',
      });
    });
  }

  return (
    <Container className="presentation-container p-0">
      <div className="steps-presentation mb-4">
        <StepsHeader name={name} type="profile" />
      </div>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="form-field">
            <p className="label">{t('Title Question')}*</p>
            <textarea
              placeholder="Digite aqui..."
              className={
                errors && errors.curiosity
                  ? 'field -full-error'
                  : 'field -full '
              }
              {...register('curiosity', {
                maxLength: 300,
                required: 'Esse campo é obrigatório.',
              })}
            />
            {errors && errors.curiosity?.type ? (
              <span className="error-text d-block">
                {errorMessages.get(errors.curiosity?.type)}
              </span>
            ) : null}
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center">
          <div className="page-footer">
            <span>Obrigado por compartilhar sua história com a gente!</span>
            <button
              type="submit"
              className="app-button"
              onClick={() => {
                setOpenModal(true);
                setModalData({
                  modalTitle: 'Confirmar Envio do Formulário',
                  icon: 'triangleWarning',
                  title:
                    'Você tem certeza de que deseja enviar as informações?',
                  description: '',
                });
              }}
              disabled={disableButton || formState.isValid === false}
            >
              Enviar
            </button>
          </div>
        </Col>
      </Row>

      <GenericModal
        loading={loading}
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Confirmar Envio do Formulário'
            ? handleSubmit(submitForm)
            : () => {
                if (modalData.modalTitle === 'Uhuuuu, acabouuu!') {
                  setOpenModal(false);
                  navigate('/home');
                } else {
                  setOpenModal(false);
                }
              }
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Presentation;

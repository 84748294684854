import { FormCategories } from 'types/common/FormCategories';
import ValidationFormType from 'types/enum/ValidationFormType';

/* eslint-disable import/prefer-default-export */
export const infoInternship: FormCategories = [
  {
    name: ValidationFormType.PROFILE,
    title: 'INFORMAÇÕES PESSOAIS',
  },
  {
    name: ValidationFormType.RESIDENTIAL,
    title: 'Dados Residenciais',
  },
  {
    name: ValidationFormType.DOCUMENTS,
    title: 'PREENCHER DOCUMENTAÇÃO',
  },
  {
    name: ValidationFormType.BANKING,
    title: 'Dados Bancários',
  },
  {
    name: ValidationFormType.DEPENDANTS,
    title: 'Inclusão de Dependentes',
  },
  {
    name: ValidationFormType.ATTACHMENTS,
    title: 'UPLOAD DE DOCUMENTOS',
  },
];

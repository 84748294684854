/* eslint-disable import/prefer-default-export */
export const BankingTexts = {
  'pt-BR': {
    'Account Itau Title': 'Conta Corrente Itaú',
    'Account Itau Description title': 'Trabalhamos com o Itaú.',
    'Account Itau Description':
      'Caso não possua uma conta nesse banco, será necessário providenciar a abertura de uma conta salário.',
    'Account Letter Disabled':
      'Para que o download da carta seja habilitado, precisamos que as três primeiras etapas do formulário estejam preenchidas: Perfil do Colaborador, Dados Residenciais e Preenchimento dos Documentos.',
    'Account Letter Enabled':
      'Se você ainda não tem uma conta no bancária, estamos disponibilizando o download de carta para solicitação de abertura no Itaú.',
    'banking.data.title': 'Dados bancários',
    'inform.bank.details':
      'Informe seus dados bancários para recebimento do salário.',
    'mandatory.bank': ' *Obrigatório',
    'no.account.itau': 'Não tenho conta no Itaú',
    'back.button': 'Voltar',
    'save.continue.button': 'Salvar e continuar',
    'data.sent.cannot.change.want.continue':
      'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
    attention: 'Atenção!',
    'operation.failure': 'Falha na operação!',
    'review.data.title': 'Revise seus dados.',
    'something.wrong':
      'Algo deu errado ao tentar avançar para o próximo passo.',
    'saving.data': 'Salvando seus dados...',
    wait: 'Aguarde!',
    'something.wrong.title': 'Algo deu errado',
    'try.again.please': 'Por favor, tente novamente em alguns instantes.',
  },
  en: {},
};

import React, { useState, useRef } from 'react';

function calcOffset(offset: number, delta: number) {
  const calc = offset + delta;
  if (calc > 0) return 0;
  return calc;
}

function withinThreshold(
  value: number,
  limit: number | undefined,
  ratio: number
) {
  if (limit == null) return false;
  if (Number.isNaN(limit)) return false;

  return value <= limit * ratio;
}

const useCarousel = () => {
  const [dragState, setDragState] = useState({
    isDragging: false,
    initialX: -1,
    deltaX: 0,
    offset: 0,
  });
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleRelease = () => {
    setDragState((s) => ({
      ...s,
      isDragging: false,
      initialX: -1,
      deltaX: 0,
      offset: s.offset + s.deltaX,
    }));
  };

  const handlePress = (startingPoint: number) => {
    setDragState((s) => ({
      ...s,
      isDragging: true,
      initialX: startingPoint,
    }));
  };

  const handleMove = (currentPosition: number) => {
    if (dragState.isDragging) {
      const distance = currentPosition - dragState.initialX;
      const updatedOffset = dragState.offset + distance;

      if (
        updatedOffset < 0 &&
        withinThreshold(
          Math.abs(updatedOffset),
          sliderRef.current?.clientWidth,
          0.8
        )
      ) {
        setDragState((s) => ({
          ...s,
          deltaX: distance,
        }));
      }
    }
  };

  const onTouchEnd = (_: React.TouchEvent<HTMLElement>) => {
    handleRelease();
  };

  const onMouseLeave = (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleRelease();
  };

  const onMouseUp = (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleRelease();
  };

  const onTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    handlePress(e.touches[0].clientX);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handlePress(e.clientX);
  };

  const onTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    handleMove(e.touches[0].clientX);
  };

  const onMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleMove(e.clientX);
  };

  const style = {
    transform: `translateX(${calcOffset(
      dragState.offset,
      dragState.deltaX
    )}px)`,
    width: 'fit-content',
  };

  const handlers = {
    ref: sliderRef,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onMouseMove,
  };

  return {
    style,
    handlers,
  };
};

export default useCarousel;

import StepsController from 'components/StateController/StepsController';
import { UseFormReturn, UseFormSetError } from 'react-hook-form';
import { AdmissionFormConfig } from 'store/user/types';
import { formStatus } from 'types/common/FormStatus';

export const CUSTOM_ERROR_MESSAGE = 'Campo precisa de revisão.';

export function handleValidationResults(
  formSetError: UseFormSetError<any>,
  validationResults: string[] = []
) {
  if (validationResults.length) {
    validationResults.forEach((property) =>
      formSetError(property, {
        type: 'custom',
        message: 'Campo precisa de revisão.',
      })
    );
  }
}

const stringPathRecursion = (
  add: (item: string) => void,
  prop: string,
  obj: Record<string, any>
) => {
  Object.entries(obj).map(([key, value]) => {
    if (typeof value !== 'boolean') {
      stringPathRecursion((item) => add(item), `${prop}.${key}`, value);
    } else {
      add(`${prop}.${key}`);
    }
  });
};

const mapToStringPath = (fields: Record<string, any>): string[] => {
  const properties: string[] = [];

  Object.entries(fields).map(([key, value]) => {
    if (typeof value !== 'boolean') {
      stringPathRecursion((item) => properties.push(item), key, value);
    } else {
      properties.push(key);
    }
  });

  return properties;
};

export function isNoneStatus(status: formStatus): boolean {
  return status === 'none';
}

export function isErrorStatus(status: formStatus): boolean {
  return status === 'error';
}

export function isPendingStatus(status: formStatus): boolean {
  return status === 'pending';
}

export function isValidStatus(status: formStatus): boolean {
  return status === 'valid';
}

export function checkFinishButtonDisabled(
  form: UseFormReturn<any, any>,
  formStore: AdmissionFormConfig
): boolean {
  const { validationResults, status, modifiedAttachments } = formStore;

  if (isPendingStatus(status) || isValidStatus(status)) return false;

  const isError = isErrorStatus(status);
  if (!isError && !form.formState.isValid) return true;
  if (!modifiedAttachments) return false;
  return modifiedAttachments.length !== validationResults.length;
}

export function checkSaveButtonDisabled(
  form: UseFormReturn<any, any>,
  formStore: AdmissionFormConfig
): boolean {
  const { isDirty, dirtyFields, errors, isValid } = form.formState;
  const { validationResults, status } = formStore;

  if (isPendingStatus(status) || isValidStatus(status)) return false;
  if (isNoneStatus(status) && isDirty && isValid && !hasErrors(errors))
    return false;

  const isError = isErrorStatus(status);
  if (!isError && !isValid) return true;

  if (hasErrors(errors)) return true;

  const errorProps = [...validationResults];
  const dirtyProps = mapToStringPath(dirtyFields);
  const allPropsChanged = errorProps.every((prop) => dirtyProps.includes(prop));

  const shouldDisable = (!isDirty || !allPropsChanged) && isError;
  return shouldDisable;
}

function hasErrors(errors: any) {
  return Object.keys(errors).length !== 0;
}

export function formChanged(form: UseFormReturn<any, any>): boolean {
  const { isDirty } = form.formState;
  return isDirty;
}

export function getStepStatus(): string {
  const stepsController = StepsController();
  return stepsController.steps.admission.status;
}

const checkHasAnyError = (errors: any, fieldName: string) => {
  if (Object.keys(errors).length === 0) return false;
  if (fieldName.indexOf('.') === -1) return errors[fieldName];
  const splits = fieldName.split('.');

  if (!Object.keys(errors).includes(splits[0])) return false;

  if (splits.length === 2) {
    // documents
    return Object.keys(errors[splits[0]]).includes(splits[1]);
  }

  // dependants
  if (errors[splits[0]][splits[1]])
    return Object.keys(errors[splits[0]][splits[1]]).includes(splits[2]);

  return false;
};

export function getFormValidationProps(
  fieldName: string,
  formStore: AdmissionFormConfig,
  formMethods: UseFormReturn<any>
) {
  const { dirtyFields, errors } = formMethods.formState;
  let { validationResults } = formStore;
  const { status, isEditing, modifiedAttachments } = formStore;

  if (isPendingStatus(status)) {
    return {
      shouldDisable: true,
      hasCustomError: false,
      hasAnyError: false,
      hasModified: false,
    };
  }

  const isError = isErrorStatus(status);
  const hasModified =
    modifiedAttachments &&
    modifiedAttachments.some((prop) => prop === fieldName);

  if (!isError && Object.keys(errors).length > 0)
    return {
      shouldDisable: false,
      hasCustomError: false,
      hasAnyError: checkHasAnyError(errors, fieldName),
      hasModified,
    };

  const dirtyProps = mapToStringPath(dirtyFields);
  const isFieldDirty = dirtyProps.filter((p) => p === fieldName).length > 0;
  if (isFieldDirty) {
    validationResults = validationResults.filter((item) => item !== fieldName);
  }

  const hasCustomError =
    isEditing && validationResults.some((prop) => prop === fieldName);

  const hasAnyError =
    hasCustomError || (errors && checkHasAnyError(errors, fieldName));

  let shouldDisable = false;
  if (isValidStatus(status)) shouldDisable = true;
  else if (isFieldDirty) shouldDisable = false;
  else if (isError && !hasAnyError && isEditing) shouldDisable = true;

  return { shouldDisable, hasCustomError, hasAnyError, hasModified };
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfilePhoneNumber({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'phoneNumber',
    admissionForm,
    profileMethods
  );

  return (
    <label id="phoneNumber" className="form-field" htmlFor="phoneNumber">
      <span className="label">Telefone Residencial</span>
      <Controller
        control={profileMethods.control}
        name="phoneNumber"
        rules={{ minLength: 14 }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <input
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            onChange={(e) => onChange(masks.phone(e.target.value))}
            value={profileMethods.getValues('phoneNumber') || ''}
            onBlur={onBlur}
            readOnly={shouldDisable}
          />
        )}
      />
      {errors && errors.phoneNumber && (
        <span className="error-text">
          O telefone residencial é inválido, por favor revise.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfilePhoneNumber;

import api from 'configs/api';
import FormAnswerType from 'types/enum/FormAnswerType';
import { SaveFormAnswer } from 'types/models/FormAnswer';

async function saveForm({ dataRequest, type }: SaveFormAnswer) {
  const request = {
    ...dataRequest,
    type,
  };

  return api.post('/form', request);
}

async function updatePendingStatus(type: FormAnswerType) {
  return api.post('/form', { type });
}

async function getAllAnswers<T>(type: string) {
  return api.get<T>(`/form/${type}`);
}

const FormAnswerService = { saveForm, getAllAnswers, updatePendingStatus };

export default FormAnswerService;

import React from 'react';

import SignRoutes from 'routes/SignRoutes';
import OtherRoutes from 'routes/OtherRoutes';
import Header from 'components/Header';
import useLoggedUser from 'hooks/loggedUser';

function Base() {
  const loggedUser = useLoggedUser();
  return loggedUser.email ? (
    <>
      <Header />
      <OtherRoutes />
    </>
  ) : (
    <SignRoutes />
  );
}

export default Base;

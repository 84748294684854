import React, { useContext } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import boomePlaying from 'assets/img/boome-playing.jpg';
import boomeCompleted from 'assets/img/boome-completed.jpg';
import boomeStarted from 'assets/img/boome-started.jpg';
import Container from 'components/Container';
import AuthContext from 'contexts/auth';
import './style.scss';

type HomeInfoProps = {
  daysLeft: number;
  isProfileFormFilled: boolean;
  preventNavigation: (flag: boolean) => void;
};

type CardInfoProps = {
  title: string;
  description: string;
  buttonText: string;
  imgSource: string;
};

function HomeInfo({
  daysLeft,
  isProfileFormFilled,
  preventNavigation,
}: HomeInfoProps) {
  const { t } = useTranslation('home');
  const { state } = useContext(AuthContext);

  let stageWithError: Record<string, string> = {};
  let activeStage: Record<string, string> = {};

  Object.keys(state.steps).forEach((stage) => {
    if (state.steps[stage]?.status === 'Error') {
      stageWithError = state.steps[stage];
    } else if (state.steps[stage]?.status === 'Active') {
      activeStage = state.steps[stage];
    }
  });

  const shouldPreventNavigation =
    Object.keys(stageWithError).length === 0 &&
    activeStage.path === '/exam' &&
    !state.approvedMedicalStep;

  function isAllComplete() {
    if (state.contractType !== 'PJ')
      return (
        state.steps.admission.status === 'Complete' &&
        state.steps.benefits.status === 'Complete' &&
        state.steps.health.status === 'Complete' &&
        state.steps.profile.status === 'Complete'
      );
    return (
      state.steps.admission.status === 'Complete' &&
      state.steps.profile.status === 'Complete'
    );
  }

  function buildCardInfo(cardInfoProps: CardInfoProps) {
    return (
      <>
        <Col className="banner-info">
          <h1>{cardInfoProps.title}</h1>
          <p>{cardInfoProps.description}</p>
          {cardInfoProps.buttonText && (
            <ButtonLink
              onClick={() => {
                if (shouldPreventNavigation) {
                  preventNavigation(true);
                }
              }}
              componentType={shouldPreventNavigation ? 'button' : 'link'}
              url={stageWithError.path || activeStage.path || '/onboarding'}
              id="home-start-form"
              className="button-home"
            >
              <span>{cardInfoProps.buttonText}</span>
            </ButtonLink>
          )}
          {isAllComplete() && (
            <div className="text-completed">
              <span className="information-init">
                Você iniciará na Iteris em:
              </span>
              <span className="days-init">{daysLeft} dias</span>
            </div>
          )}
        </Col>
        <Col xs={5} className="d-flex boome-img">
          <img alt="boome" src={cardInfoProps.imgSource} />
        </Col>
      </>
    );
  }

  return (
    <section className="home-info-container">
      <Container className="banner-container">
        {!isAllComplete() &&
          !isProfileFormFilled &&
          buildCardInfo({
            title: t('Application Not Started Title'),
            description: t('Application Not Started Text'),
            buttonText: t('Application Not Started Button Text'),
            imgSource: boomePlaying,
          })}

        {!isAllComplete() &&
          isProfileFormFilled &&
          buildCardInfo({
            title: t('Application Started Title'),
            description: t('Application Started Text'),
            buttonText: t('Application Started Button Text'),
            imgSource: boomeStarted,
          })}

        {isAllComplete() &&
          buildCardInfo({
            title: t('Application Finished Title'),
            description: t('Application Finished Text'),
            buttonText: '',
            imgSource: boomeCompleted,
          })}
      </Container>
    </section>
  );
}

export default HomeInfo;

import axios from 'axios';

const api = axios.create({
  timeout: 30000,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },

  baseURL: process.env.REACT_APP_URL_API,
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }

    return Promise.reject(error);
  }
);

api.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(`Não foi encontrado header nessa requisição.`);
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    '@App:token'
  )}`;
  return config;
});

export default api;

import React from 'react';
import Icon from 'components/Icon';

import './styles.scss';
import { UseFormReturn } from 'react-hook-form';

type ImageCardProps = {
  urlImage: string;
  openModal: () => void;
  profileMethods: UseFormReturn<any>;
};

function ImageCard({ urlImage, openModal, profileMethods }: ImageCardProps) {
  const { errors } = profileMethods.formState;

  return (
    <div>
      <button onClick={openModal} type="button" className="image-card">
        <div className="card-image">
          {urlImage !== '' ? (
            <img className="profile-image" src={urlImage} />
          ) : null}
        </div>
        <div className="footer">
          {urlImage !== '' ? (
            <Icon name="reloadIcon" />
          ) : (
            <Icon name="uploadIcon" />
          )}
        </div>
      </button>

      {errors && errors.photo?.type === 'custom' && errors.photo?.message && (
        <span className="error-text">{errors.photo.message}</span>
      )}
    </div>
  );
}

export default ImageCard;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import AuthContext from 'contexts/auth';
import masks from 'utils/MasksUtils';
import ValidationUtils from '../ValidationUtils';
import { getFormValidationProps } from '../FormUtils';
import './styles.scss';

type DependantNameProps = {
  dependantsDataMethods: UseFormReturn<any>;
  dependantIndex: number;
};

function DependantName({
  dependantsDataMethods,
  dependantIndex,
}: DependantNameProps) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const maxLength = 50;
  const { errors } = dependantsDataMethods.formState;

  const nameFormValidationProps = () =>
    getFormValidationProps(
      `dependants.${dependantIndex}.name`,
      admissionForm,
      dependantsDataMethods
    );

  return (
    <label id="name" htmlFor="name" className="form-field">
      <span className="label">Nome completo*</span>
      <Controller
        name={`dependants[${dependantIndex}].name`}
        rules={{ maxLength, required: true }}
        control={dependantsDataMethods.control}
        render={({ field: { onChange, onBlur } }) => (
          <input
            type="text"
            onBlur={onBlur}
            className={ValidationUtils.errorClass(
              errors,
              nameFormValidationProps().hasAnyError
            )}
            value={
              dependantsDataMethods.getValues(
                `dependants[${dependantIndex}].name`
              ) || ''
            }
            onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
            disabled={nameFormValidationProps().shouldDisable}
          />
        )}
      />
      {ValidationUtils.errorViewValidator(
        errors,
        errors?.dependants?.at(dependantIndex)?.name
      )}
      {errors &&
        errors?.dependants?.at(dependantIndex)?.name?.type === 'maxLength' && (
          <span className="error-text">
            Precisa ter até {maxLength} caracteres.
          </span>
        )}
      {ValidationUtils.errorCustomValidator(
        nameFormValidationProps().hasCustomError
      )}
    </label>
  );
}

export default DependantName;

import React, { useEffect, useContext, useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import { Row, Col, Container } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerType from 'types/enum/FormAnswerType';
import FormAnswerService from 'services/formAnswerService';
import ProfileDisability from 'components/Forms/Shared/Profile/ProfileDisability';
import ProfileEducationalDegree from 'components/Forms/Shared/Profile/ProfileEducationalDegree';
import ProfileParentage from 'components/Forms/Shared/Profile/ProfileParentage';
import ProfileCivilStatus from 'components/Forms/Shared/Profile/ProfileCivilStatus';
import ProfileSex from 'components/Forms/Shared/Profile/ProfileSex';
import ProfileRaceColor from 'components/Forms/Shared/Profile/ProfileRaceColor';
import ProfileNationality from 'components/Forms/Shared/Profile/ProfileNationality';
import ProfileFirstRow from 'components/Forms/Shared/ProfileFirstRow';
import { START_PERMISSION_SIGNATURE } from 'constants/imageUpload';
import '../../FormCLT/Profile/styles.scss';
import ProfilePlaceBirthInput from 'components/Forms/Shared/Profile/ProfilePlaceBirthInput';
import ProfileSecondParentage from 'components/Forms/Shared/Profile/ProfileSecondParentage';
import ProfileForeignCLTResponse from 'types/models/ProfileForeignCLTResponse';
import { CivilStatus } from 'types/common/CivilStatus';
import { Deficiency } from 'types/common/Deficiency';
import { Race } from 'types/common/Race';
import { Gender } from 'types/common/Gender';
import { EducationLevel } from 'types/common/EducationLevel';
import { updateAdmissionForm } from 'store/user/actions';
import AuthContext from 'contexts/auth';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
} from 'components/Forms/Shared/FormUtils';
import { formStatus } from 'types/common/FormStatus';
import ProfileEmergencyContact from 'components/Forms/Shared/Profile/ProfileEmergencyContact';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Profile({ handleNext }: { handleNext: () => void }) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const profileMethods: UseFormReturn<FormModel.Profile> =
    useForm<FormModel.Profile>({
      mode: 'onBlur',
      defaultValues: {
        phoneNumber: '',
        mobileNumber: '',
        dateOfBirth: '',
        emergencyContact: { phoneNumber: '', fullName: '', kinship: '' },
      },
    });

  const handleConfirm = async (data: FormModel.Profile) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    const dataRequest: object = {
      ...data,
      photo: profileMethods
        .getValues('photo')
        .split(START_PERMISSION_SIGNATURE)[0],
    };
    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.PROFILE_FOREIGN_CLT,
    })
      .then(() => {
        setLoading(false);
        handleNext();
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  async function loadAnswersProfileForeignCLT(): Promise<void> {
    return FormAnswerService.getAllAnswers<ProfileForeignCLTResponse>(
      'PROFILE_FOREIGN_CLT'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        profileMethods.reset({
          photo: data.photo || '',
          name: data.name || '',
          preferredName: data.preferredName || '',
          email: data.email || '',
          dateOfBirth: data.dateOfBirth || '',
          phoneNumber: data.phoneNumber || '',
          mobileNumber: data.mobileNumber || '',
          parentage: data.parentage || '',
          secondParentage: data.secondParentage || '',
          civilStatus: (data.civilStatus as CivilStatus) || '',
          deficiency: (data.deficiency as Deficiency) || '',
          schooling: (data.schooling as EducationLevel) || '',
          race: (data.race as Race) || '',
          sex: (data.sex as Gender) || '',
          stateOfBirth: data.stateOfBirth || '',
          cityOfBirth: data.cityOfBirth || '',
          nationality: data.nationality || '',
          emergencyContact: {
            fullName: data.emergencyContact?.fullName || '',
            phoneNumber: data.emergencyContact?.phoneNumber || '',
            kinship: data.emergencyContact?.kinship || '',
          },
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.PROFILE,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );
    });
  }

  useEffect(() => {
    loadAnswersProfileForeignCLT();
  }, []);

  function handleDisable() {
    return checkSaveButtonDisabled(profileMethods, admissionForm);
  }
  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : profileMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="profile-container">
      <Row className="header-form-info">
        <Col>
          <h1>Informações Pessoais</h1>
          <p>
            Preencha com suas informações pessoais os dados para montar o seu
            perfil. <span>* Obrigatório</span>
          </p>
        </Col>
      </Row>

      <ProfileFirstRow
        profileMethods={profileMethods}
        formAnswerType={FormAnswerType.PROFILE_FOREIGN_CLT}
      />

      <Row className="profile-row">
        <Col sm={6}>
          <ProfileParentage profileMethods={profileMethods} />
          <ProfileSecondParentage profileMethods={profileMethods} />
          <Row>
            <Col sm={6}>
              <ProfileSex profileMethods={profileMethods} />
            </Col>
            <Col sm={6}>
              <ProfileRaceColor profileMethods={profileMethods} />
            </Col>
          </Row>
        </Col>

        <Col sm={6} xs={12}>
          <ProfilePlaceBirthInput profileMethods={profileMethods} />

          <Row>
            <Col sm={6}>
              <ProfileNationality profileMethods={profileMethods} />
            </Col>
            <Col sm={6}>
              <ProfileDisability profileMethods={profileMethods} />
            </Col>

            <Col sm={6}>
              <ProfileEducationalDegree profileMethods={profileMethods} />
            </Col>
            <Col sm={6}>
              <ProfileCivilStatus profileMethods={profileMethods} />
            </Col>
          </Row>
        </Col>
      </Row>

      <ProfileEmergencyContact profileMethods={profileMethods} />

      <Row className="row-form-buttons">
        <Col>
          <ButtonLink
            disabled={handleDisable()}
            className="ms-auto"
            componentType="button"
            onClick={handleButton()}
          >
            Salvar e continuar
          </ButtonLink>
        </Col>
      </Row>

      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={profileMethods.handleSubmit(handleConfirm)}
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Profile;

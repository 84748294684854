/* eslint-disable import/prefer-default-export */
export const HomeTexts = {
  'pt-BR': {
    'Welcome title':
      'Que coisa boa ter você por aqui! Bora começar sua jornada com a gente!',
    'Welcome description':
      'Para prosseguirmos com sua contratação, precisamos que você clique no botão “Iniciar o envio” e siga o passo a passo, preenchendo os formulários e anexando a documentação necessária. Estamos ansiosos para te conhecer e fazermos acontecer juntos!',
    'button.start': 'Iniciar jornada',
    'Welcome secondary title':
      'Quer conhecer um pouco mais da Iteris? Segue um spoiler.',
    'Welcome secondary description':
      'Enquanto o seu processo de contratação está em andamento, que tal já ir ficando por dentro de como as coisas funcionam aqui na Iteris?!',
    'Application Not Started Title': 'Vamos começar sua jornada?',
    'Application Not Started Text':
      'Para prosseguirmos com sua contratação, é necessário que você preencha todas as etapas do onboarding e, assim, torna-se parte do time oficialmente.',
    'Application Not Started Button Text': 'Iniciar jornada',
    'Application Started Title': 'Você já iniciou sua jornada!',
    'Application Started Text':
      'Para prosseguirmos com sua contratação, é necessário que você preencha todas as etapas do onboarding e, assim, torna-se parte do time oficialmente.',
    'Application Started Button Text': 'Continuar de onde parou',
    'Application Finished Title': 'Parabéns! Você concluiu a sua jornada',
    'Application Finished Text':
      'Você já faz parte do nosso time. Agora é só aguardar o seu primeiro dia de trabalho, caso tenha alguma dúvida fale com a gente abaixo.',
    'aplication.progress.status.inactive': 'Finalize a etapa anterior',
    'aplication.progress.status.active': 'Pronta para iniciar',
    'aplication.progress.status.error': 'Corrigir erros',
    'aplication.progress.status.pending': 'Aguarde liberação',
    'aplication.progress.status.complete': 'Finalizado',
    'aplication.progress.title': 'Acompanhe o seu progresso',
    'card.title.site': 'Visite o nosso site',
    'card.title.comunidades': 'Conheça as Comunidades de Prática',
    'card.title.manifesto': 'Conheça nosso manifesto',
    'card.title.valores': 'Conheça nossos valores',
    'card.title.instagram': 'Siga-nos no Instagram',
    'card.title.linkedin': 'Iteris no Linkedin',
    'card.title.facebook': 'Iteris no Facebook',
    'card.status.inactive': 'Não iniciado',
    'card.status.active': 'Etapa iniciada',
    'card.status.complete': 'Etapa concluída',
    'card.status.pending': 'Em validação pelo RH',
    'card.status.error': 'Há erros nesta etapa',
  },
  en: {
    'Welcome title': "It's so nice to have you here! Let' begin your journey!",
  },
};

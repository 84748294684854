import React, { useContext, useEffect, useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import DocumentCPF from 'components/Forms/Shared/Documents/DocumentCPF';
import DocumentRegistrationNumber from 'components/Forms/Shared/Documents/DocumentRegistrationNumber';
import {
  checkSaveButtonDisabled,
  getFormValidationProps,
  isErrorStatus,
} from 'components/Forms/Shared/FormUtils';
import AuthContext from 'contexts/auth';
import { Col, Container, Row } from 'react-bootstrap';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import FormAnswerService from 'services/formAnswerService';
import { customStyles } from 'styles/select/customStyles';
import FormAnswerType from 'types/enum/FormAnswerType';
import documentValidator from 'utils/documentValidator';
import masks from 'utils/MasksUtils';
import '../../FormCLT/Documents/styles.scss';
import DocumentsPJResponse from 'types/models/DocumentsPJResponse';
import { updateAdmissionForm } from 'store/user/actions';
import ValidationUtils from 'components/Forms/Shared/ValidationUtils';
import { formStatus } from 'types/common/FormStatus';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Documents({
  handleNext,
  handlePrevious,
  formChanged,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const maxLength = 50;
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const documentsDataMethods: UseFormReturn<FormModel.Documents> =
    useForm<FormModel.Documents>({
      mode: 'onBlur',
      defaultValues: {
        CNPJ: '',
        CPF: '',
        RG: { expeditionDate: '' },
      },
    });

  const { errors } = documentsDataMethods.formState;

  async function loadAnswersDocumentsPJ(): Promise<void> {
    return FormAnswerService.getAllAnswers<DocumentsPJResponse>(
      'DOCUMENTS_PJ'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        documentsDataMethods.reset({
          CPF: data.CPF || '',
          RG: {
            number: data.RG?.number || '',
            emmiter: data.RG?.emmiter || '',
            expeditionDate: data.RG?.expeditionDate || '',
          },
          CNPJ: data.CNPJ || '',
          simpleNational: data.simpleNational || '',
          companyName: data.companyName || '',
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.DOCUMENTS,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  const simpleNational = [
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' },
  ];

  const companyNameFormValidationProps = getFormValidationProps(
    'companyName',
    admissionForm,
    documentsDataMethods
  );

  const cnpjFormValidationProps = getFormValidationProps(
    'CNPJ',
    admissionForm,
    documentsDataMethods
  );

  const simpleNationalFormValidationProps = getFormValidationProps(
    'simpleNational',
    admissionForm,
    documentsDataMethods
  );

  const handleConfirm = async (dataRequest: FormModel.Documents) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.DOCUMENTS_PJ,
    })
      .then(() => handleNext())
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  function handleDisable() {
    return (
      checkSaveButtonDisabled(documentsDataMethods, admissionForm) || loading
    );
  }

  const verifyGoBack = () => {
    if (formChanged(documentsDataMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  useEffect(() => {
    loadAnswersDocumentsPJ();
  }, []);

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : documentsDataMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="documents-container">
      <Row className="header-form-info">
        <Col>
          <h1>Preencher documentação</h1>
          <p>
            Informe alguns dados presentes nos seus documentos pessoais.
            Tenha-os em mãos para este formulário. <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>
      <DocumentRegistrationNumber
        documentsDataMethods={documentsDataMethods}
        validate
      />
      <Row>
        <Col xs={12}>
          <label id="companyName" className="form-field" htmlFor="companyName">
            <span className="label">Razão Social da Empresa*</span>

            <Controller
              control={documentsDataMethods.control}
              name="companyName"
              rules={{
                maxLength,
                required: true,
              }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  className={ValidationUtils.errorClass(
                    errors,
                    companyNameFormValidationProps.hasAnyError
                  )}
                  placeholder=""
                  value={documentsDataMethods.getValues('companyName')}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  disabled={companyNameFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.companyName)}
            {ValidationUtils.errorCustomValidator(
              companyNameFormValidationProps.hasCustomError
            )}
            {errors && errors.companyName?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength} caracteres.
              </span>
            )}
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <label id="CNPJ" className="form-field" htmlFor="CNPJ">
            <span className="label">CNPJ da Empresa*</span>
            <Controller
              control={documentsDataMethods.control}
              name="CNPJ"
              rules={{
                required: true,
                minLength: 14,
                validate: documentValidator.validCNPJ,
              }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  className={ValidationUtils.errorClass(
                    errors,
                    cnpjFormValidationProps.hasAnyError
                  )}
                  placeholder=""
                  onChange={(e) => onChange(masks.cnpj(e.target.value))}
                  value={documentsDataMethods.getValues('CNPJ')}
                  onBlur={onBlur}
                  disabled={cnpjFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.CNPJ)}
            {errors &&
              (errors.CNPJ?.type === 'minLength' ||
                errors.CNPJ?.type === 'validate') && (
                <span className="error-text">
                  O CNPJ é inválido, por favor revise.
                </span>
              )}
            {ValidationUtils.errorCustomValidator(
              cnpjFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Col xs={12} md={3}>
          <label
            id="simpleNational"
            className="form-field"
            htmlFor="simpleNational"
          >
            <span className="label">Optante pelo Simples Nacional*</span>
            <Controller
              name="simpleNational"
              control={documentsDataMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStyles(
                    simpleNationalFormValidationProps.hasAnyError,
                    simpleNationalFormValidationProps.shouldDisable
                  )}
                  options={simpleNational}
                  name={name}
                  value={simpleNational.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isSearchable={
                    !simpleNationalFormValidationProps.shouldDisable
                  }
                  menuIsOpen={
                    simpleNationalFormValidationProps.shouldDisable
                      ? false
                      : undefined
                  }
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.simpleNational)}
            {ValidationUtils.errorCustomValidator(
              simpleNationalFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Row>
          <Col xs={12} sm={3}>
            <DocumentCPF documentsDataMethods={documentsDataMethods} />
          </Col>
        </Row>
      </Row>

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <ButtonLink onClick={verifyGoBack}>Voltar</ButtonLink>
          <ButtonLink
            disabled={handleDisable()}
            className="ms-3"
            onClick={handleButton()}
          >
            Salvar e continuar
          </ButtonLink>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />
      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : documentsDataMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Documents;

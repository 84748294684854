import api from './api';

export async function getRefreshToken(accessToken: string) {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refresh_token');
    const header = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };
    const body = {
      grant_type: 'refresh_token',
      refreshToken,
    };

    api
      .post('/refreshtoken', body, {
        headers: header,
      })
      .then(async (res) => {
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('refresh_token', res.data.refresh_token);
        // Fazer algo caso seja feito o refresh token
        return resolve(res);
      })
      .catch((err) => reject(err));
  });
}

export function getToken(): string {
  const token = sessionStorage.getItem('@App:token') || '';
  return token;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { getToken, getRefreshToken };

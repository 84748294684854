/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function DocumentReservist({
  documentsDataMethods,
  validate,
}: {
  documentsDataMethods: UseFormReturn<any>;
  validate: boolean;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;
  const maxLength = {
    number: 12,
    category: 15,
  };

  const reservistNumberFormValidationProps = getFormValidationProps(
    'reservist.number',
    admissionForm,
    documentsDataMethods
  );

  const reservistCategoryFormValidationProps = getFormValidationProps(
    'reservist.category',
    admissionForm,
    documentsDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorViewValidator(errorValidation: FieldError | undefined) {
    return errors && errorValidation?.type === 'required' ? (
      <span className="error-text">Esse campo é obrigatório.</span>
    ) : null;
  }

  function errorCustomValidator(hasCustomError: boolean) {
    return (
      hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )
    );
  }

  return (
    <>
      <div className="sub-form-title">Certificado de Reservista</div>
      <Row>
        <Col xs={12} sm={3}>
          <label
            id="reservistNumber"
            className="form-field"
            htmlFor="reservist.number"
          >
            <span className="label">Número{validate ? '*' : ''}</span>
            <Controller
              control={documentsDataMethods.control}
              name="reservist.number"
              rules={{ required: validate, maxLength: maxLength.number }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  className={errorClass(
                    reservistNumberFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues('reservist.number') || ''
                  }
                  disabled={reservistNumberFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.reservist?.number?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.number} caracteres.
              </span>
            )}
            {errorViewValidator(errors.reservist?.number)}
            {errorCustomValidator(
              reservistNumberFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
        <Col xs={12} sm={3}>
          <label
            id="reservistCategory"
            className="form-field"
            htmlFor="reservist.category"
          >
            <span className="label">Categoria{validate ? '*' : ''}</span>
            <Controller
              control={documentsDataMethods.control}
              name="reservist.category"
              rules={{ required: validate, maxLength: maxLength.category }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  className={errorClass(
                    reservistCategoryFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues('reservist.category') || ''
                  }
                  disabled={reservistCategoryFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.reservist?.category)}
            {errors && errors.reservist?.category?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.category} caracteres.
              </span>
            )}
            {errorCustomValidator(
              reservistCategoryFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
    </>
  );
}

export default DocumentReservist;

/* eslint-disable import/prefer-default-export */

export const Types: any = {
  admission: {
    Active: 'admissionActive',
    Inactive: 'admissionInactive',
    Complete: 'admissionComplete',
    Error: 'admissionError',
    Pending: 'admissionPending',
  },
  benefits: {
    Active: 'benefitsActive',
    Inactive: 'benefitsInactive',
    Complete: 'benefitsComplete',
    Error: 'benefitsError',
    Pending: 'benefitsPending',
  },
  health: {
    Active: 'healthActive',
    Inactive: 'healthInactive',
    Complete: 'healthComplete',
    Error: 'healthError',
    Pending: 'healthPending',
  },
  profile: {
    Active: 'profileActive',
    Inactive: 'profileInactive',
    Complete: 'profileComplete',
    Error: 'profileError',
    Pending: 'profilePending',
  },
};

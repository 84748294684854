import React, { useState, useContext, useEffect } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import DocumentCPF from 'components/Forms/Shared/Documents/DocumentCPF';
import DocumentPIS from 'components/Forms/Shared/Documents/DocumentPIS';
import DocumentRegistrationNumber from 'components/Forms/Shared/Documents/DocumentRegistrationNumber';
import DocumentWorkCard from 'components/Forms/Shared/Documents/DocumentWorkCard';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import '../../FormCLT/Documents/styles.scss';
import DocumentRNE from 'components/Forms/Shared/Documents/DocumentRNE';
import AuthContext from 'contexts/auth';
import DocumentsForeignCLTResponse from 'types/models/DocumentsForeignCLTResponse';
import { YesOrNoOption } from 'types/common/YesOrNoOption';
import { ForeignStatus } from 'types/common/ForeignStatus';
import { updateAdmissionForm } from 'store/user/actions';
import { CLTForeignType } from 'types/common/CLTForeignType';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
} from 'components/Forms/Shared/FormUtils';
import { formStatus } from 'types/common/FormStatus';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Documents({
  handleNext,
  handlePrevious,
  formChanged,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });
  const documentsDataMethods: UseFormReturn<FormModel.Documents> =
    useForm<FormModel.Documents>({
      mode: 'onBlur',
      defaultValues: {
        CPF: '',
        workCard: { expeditionDate: '' },
        RNE: { entryDate: '', expirationDate: '' },
        RG: { expeditionDate: '' },
      },
    });

  async function loadAnswersDocumentsForeignCLT(): Promise<void> {
    return FormAnswerService.getAllAnswers<DocumentsForeignCLTResponse>(
      'DOCUMENTS_FOREIGN_CLT'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        documentsDataMethods.reset({
          CPF: data.CPF || '',
          PIS: data.PIS || '',
          RG: {
            number: data.RG?.number || '',
            emmiter: data.RG?.emmiter || '',
            expeditionDate: data.RG?.expeditionDate || '',
          },
          RNE: {
            number: data.RNE?.number || '',
            digit: data.RNE?.digit || '',
            emmiter: data.RNE?.emmiter || '',
            entryDate: data.RNE?.entryDate || '',
            expirationDate: data.RNE?.expirationDate || '',
            marriedBrazilian:
              (data.RNE?.marriedBrazilian as YesOrNoOption) || '',
            brazilianSon: (data.RNE?.brazilianSon as YesOrNoOption) || '',
            type: (data.RNE?.type as CLTForeignType) || '',
          },
          workCard: {
            number: data.workCard?.number || '',
            series: data.workCard?.series || '',
            expeditionDate: data.workCard?.expeditionDate || '',
            UF: data.workCard?.UF || '',
          },
          foreignStatus: (data.foreignStatus as ForeignStatus) || '',
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.DOCUMENTS,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  const handleConfirm = async (dataRequest: FormModel.Documents) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.DOCUMENTS_FOREIGN_CLT,
    })
      .then(() => handleNext())
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  useEffect(() => {
    loadAnswersDocumentsForeignCLT();
  }, []);

  function handleDisable() {
    return (
      checkSaveButtonDisabled(documentsDataMethods, admissionForm) || loading
    );
  }

  const verifyGoBack = () => {
    if (formChanged(documentsDataMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : documentsDataMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="documents-container">
      <DocumentWorkCard documentsDataMethods={documentsDataMethods} />
      <DocumentRegistrationNumber
        documentsDataMethods={documentsDataMethods}
        validate={false}
      />

      <Row>
        <Col xs={12} sm={3}>
          <DocumentCPF documentsDataMethods={documentsDataMethods} />
        </Col>
        <Col xs={12} sm={3}>
          <DocumentPIS documentsDataMethods={documentsDataMethods} />
        </Col>
      </Row>

      <DocumentRNE documentsDataMethods={documentsDataMethods} />

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <ButtonLink onClick={verifyGoBack}>Voltar</ButtonLink>
          <ButtonLink
            disabled={handleDisable()}
            className="ms-3"
            onClick={handleButton()}
          >
            Salvar e continuar
          </ButtonLink>
        </Col>
      </Row>
      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={documentsDataMethods.handleSubmit(handleConfirm)}
        close={() => {
          setOpenModal(false);
        }}
      />
      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />
    </Container>
  );
}

export default Documents;

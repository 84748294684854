/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Switch from 'react-switch';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import AuthContext from 'contexts/auth';
import ValidationUtils from '../ValidationUtils';
import { getFormValidationProps } from '../FormUtils';
import './styles.scss';

type DependantIncludeIRProps = {
  dependantsDataMethods: UseFormReturn<any>;
  setOpenModal: (b: boolean) => void;
  setModalData: (data: {
    icon: string;
    title: string;
    description: string;
    modalTitle: string;
  }) => void;
  dependantIndex: number;
};

function DependantIncludeIR({
  dependantsDataMethods,
  setOpenModal,
  setModalData,
  dependantIndex,
}: DependantIncludeIRProps) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { t } = useTranslation('dependants');

  function setIncludedIR(check: boolean) {
    if (check) {
      setOpenModal(true);
      setModalData({
        icon: 'triangleWarning',
        title: '',
        description: t('Kinship Tax Warning'),
        modalTitle: 'Atenção!',
      });
    }

    dependantsDataMethods.setValue(
      `dependants[${dependantIndex}].includedIR`,
      check,
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  }

  const includeIRFormValidationProps = () =>
    getFormValidationProps(
      `dependants.${dependantIndex}.includedIR`,
      admissionForm,
      dependantsDataMethods
    );

  return (
    <Row>
      <div className="ir-switch">
        <span>{t('Kinship Tax Label')}</span>
        <Switch
          className="switch"
          onChange={(check) => {
            setIncludedIR(check);
          }}
          checked={
            !!dependantsDataMethods.getValues().dependants?.at(dependantIndex)
              ?.includedIR
          }
          onColor="#a1a0a0"
          onHandleColor="#F58220"
          offHandleColor="#7c7c80"
          uncheckedIcon={false}
          checkedIcon={false}
          handleDiameter={20}
          height={13}
          width={35}
          disabled={includeIRFormValidationProps().shouldDisable}
        />
      </div>
      {ValidationUtils.errorCustomValidator(
        includeIRFormValidationProps().hasCustomError
      )}
    </Row>
  );
}

export default DependantIncludeIR;

/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import { format, parse } from 'date-fns';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import Datepicker from '../Datepicker';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function DocumentRegistrationNumber({
  documentsDataMethods,
  validate,
}: {
  documentsDataMethods: UseFormReturn<any>;
  validate: boolean;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;
  const maxLength = 15;

  const rgNumberFormValidationProps = getFormValidationProps(
    'RG.number',
    admissionForm,
    documentsDataMethods
  );

  const rgExpeditionDateFormValidationProps = getFormValidationProps(
    'RG.expeditionDate',
    admissionForm,
    documentsDataMethods
  );

  const rgEmmiterFormValidationProps = getFormValidationProps(
    'RG.emmiter',
    admissionForm,
    documentsDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorViewValidator(errorValidation: FieldError | undefined) {
    return errors && errorValidation?.type === 'required' ? (
      <span className="error-text">Esse campo é obrigatório.</span>
    ) : null;
  }

  function errorCustomValidator(hasCustomError: boolean) {
    return (
      hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )
    );
  }

  return (
    <>
      <div className="sub-form-title">RG</div>
      <Row>
        <Col xs={12} sm={3}>
          <label id="RGNumber" className="form-field" htmlFor="RG.number">
            <span className="label">Número{validate ? '*' : ''}</span>
            <Controller
              control={documentsDataMethods.control}
              name="RG.number"
              rules={{ required: validate, maxLength }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(masks.RGDocNumber(e.target.value))}
                  className={errorClass(
                    rgNumberFormValidationProps.hasAnyError
                  )}
                  value={documentsDataMethods.getValues('RG.number') || ''}
                  disabled={rgNumberFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.RG?.number)}
            {errors && errors.RG?.number?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength} caracteres.
              </span>
            )}
            {errorCustomValidator(rgNumberFormValidationProps.hasCustomError)}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="RGExpeditionDate"
            className="form-field margin-right"
            htmlFor="RG.expeditionDate"
          >
            <span className="label">
              Data de Expedição{validate ? '*' : ''}
            </span>
            <Controller
              control={documentsDataMethods.control}
              name="RG.expeditionDate"
              rules={{ required: validate, minLength: 10 }}
              render={({ field: { onChange, onBlur } }) => (
                <Datepicker
                  onBlur={onBlur}
                  className={
                    rgExpeditionDateFormValidationProps.hasAnyError
                      ? 'field -input-error date'
                      : 'field -input date'
                  }
                  dateValue={
                    documentsDataMethods.getValues('RG.expeditionDate') || ''
                  }
                  selected={
                    documentsDataMethods.watch('RG.expeditionDate')
                      ? parse(
                          documentsDataMethods.watch('RG.expeditionDate'),
                          'dd/MM/yyyy',
                          new Date()
                        )
                      : null
                  }
                  onSelect={(date: Date) => {
                    onChange(format(date!, 'dd/MM/yyyy'));
                    onBlur();
                  }}
                  disabled={rgExpeditionDateFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.RG?.expeditionDate)}
            {errors && errors.RG?.expeditionDate?.type === 'minLength' && (
              <span className="error-text">
                A data é inválida, por favor revise.
              </span>
            )}
            {errorCustomValidator(
              rgExpeditionDateFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label id="RGEmmiter" className="form-field" htmlFor="RG.emmiter">
            <span className="label">Órgão Emissor{validate ? '*' : ''}</span>
            <Controller
              control={documentsDataMethods.control}
              name="RG.emmiter"
              rules={{ required: validate, maxLength }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  className={errorClass(
                    rgEmmiterFormValidationProps.hasAnyError
                  )}
                  value={documentsDataMethods.getValues('RG.emmiter') || ''}
                  disabled={rgEmmiterFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.RG?.emmiter)}
            {errors && errors.RG?.emmiter?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength} caracteres.
              </span>
            )}
            {errorCustomValidator(rgEmmiterFormValidationProps.hasCustomError)}
          </label>
        </Col>
      </Row>
    </>
  );
}

export default DocumentRegistrationNumber;

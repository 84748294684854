/* eslint-disable jsx-a11y/label-has-associated-control */
import Icon from 'components/Icon';
import AuthContext from 'contexts/auth';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../../FormUtils';
import GenericModal from '../../GenericModal';
import '../BankingAccount/style.scss';

function BankingAccountItau({
  bankingDataMethods,
}: {
  bankingDataMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const { errors } = bankingDataMethods.formState;
  const { t } = useTranslation('banking');
  const maxLength = { agency: 10, digit: 3 };

  const modalData = {
    modalTitle: 'Atenção!',
    icon: 'triangleWarning',
    title: t('Account Itau Description title'),
    description: t('Account Itau Description'),
  };

  const agencyFormValidationProps = getFormValidationProps(
    'agency',
    admissionForm,
    bankingDataMethods
  );

  const accountFormValidationProps = getFormValidationProps(
    'account',
    admissionForm,
    bankingDataMethods
  );

  const digitFormValidationProps = getFormValidationProps(
    'digit',
    admissionForm,
    bankingDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorCustomValidator(hasCustomError: boolean) {
    return (
      hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )
    );
  }

  return (
    <Row className="banking-account">
      <span className="title">
        {t('Account Itau Title')}
        <button
          type="button"
          className="question"
          onClick={() => setOpenModal(true)}
        >
          <Icon name="question" />
        </button>
      </span>
      <Col xs={12} sm={3}>
        <label id="agency" htmlFor="agency" className="form-field">
          <span className="label">Agência</span>
          <Controller
            control={bankingDataMethods.control}
            name="agency"
            rules={{ maxLength: maxLength.agency }}
            render={({ field: { onChange, onBlur, name } }) => (
              <input
                type="text"
                onBlur={onBlur}
                className={errorClass(agencyFormValidationProps.hasAnyError)}
                onChange={(e) =>
                  onChange(masks.numberAndLettersAndHyphen(e.target.value))
                }
                value={bankingDataMethods.getValues('agency') || ''}
                disabled={agencyFormValidationProps.shouldDisable}
              />
            )}
          />
          {errors && errors.agency?.type === 'maxLength' && (
            <span className="error-text">
              Precisa ter até {maxLength.agency} caracteres.
            </span>
          )}
          {errorCustomValidator(agencyFormValidationProps.hasCustomError)}
        </label>
      </Col>
      <Col xs={12} sm={3}>
        <label id="account" htmlFor="account" className="form-field">
          <span className="label">Conta Corrente</span>
          <Controller
            control={bankingDataMethods.control}
            name="account"
            rules={{ maxLength: maxLength.agency }}
            render={({ field: { onChange, onBlur } }) => (
              <input
                type="text"
                onBlur={onBlur}
                className={errorClass(accountFormValidationProps.hasAnyError)}
                onChange={(e) =>
                  onChange(masks.numberAndLettersAndHyphen(e.target.value))
                }
                value={bankingDataMethods.getValues('account') || ''}
                disabled={accountFormValidationProps.shouldDisable}
              />
            )}
          />
          {errors && errors.account?.type === 'maxLength' && (
            <span className="error-text">
              Precisa ter até {maxLength.agency} caracteres.
            </span>
          )}
          {errorCustomValidator(accountFormValidationProps.hasCustomError)}
        </label>
      </Col>
      <Col xs={12} sm={3}>
        <label id="digit" htmlFor="digit" className="form-field">
          <span className="label">Dígito</span>
          <Controller
            control={bankingDataMethods.control}
            name="digit"
            rules={{ maxLength: maxLength.digit }}
            render={({ field: { onChange, onBlur } }) => (
              <input
                type="text"
                onBlur={onBlur}
                className={
                  digitFormValidationProps.hasAnyError
                    ? 'field -full-error'
                    : 'field -full'
                }
                onChange={(e) => onChange(e.target.value)}
                value={bankingDataMethods.getValues('digit') || ''}
                disabled={digitFormValidationProps.shouldDisable}
              />
            )}
          />
          {errors && errors.digit?.type === 'maxLength' && (
            <span className="error-text">
              Precisa ter até {maxLength.digit} caracteres.
            </span>
          )}
          {errorCustomValidator(digitFormValidationProps.hasCustomError)}
        </label>
      </Col>
      <GenericModal
        open={openModal}
        data={modalData}
        modalType="confirmation"
        handleConfirm={() => {
          setOpenModal(false);
        }}
        close={() => {
          setOpenModal(false);
        }}
      />
    </Row>
  );
}

export default BankingAccountItau;

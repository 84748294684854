import { ReactComponent as AddCircleOutline } from 'assets/icons/add-circle-outline.svg';
import { ReactComponent as AlertCircle } from 'assets/icons/alert-circle.svg';
import { ReactComponent as AdmissionActive } from 'assets/icons/admission-active.svg';
import { ReactComponent as AdmissionComplete } from 'assets/icons/admission-complete.svg';
import { ReactComponent as AdmissionError } from 'assets/icons/admission-error.svg';
import { ReactComponent as AdmissionInactive } from 'assets/icons/admission-inactive.svg';
import { ReactComponent as AdmissionPending } from 'assets/icons/admission-pending.svg';
import { ReactComponent as AleloLogo } from 'assets/icons/alelo-icon.svg';
import { ReactComponent as AmilLogo } from 'assets/icons/amil-icon.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowError } from 'assets/icons/arrow-error.svg';
import { ReactComponent as ArrowValid } from 'assets/icons/arrow-valid.svg';
import { ReactComponent as ArrowWarning } from 'assets/icons/arrow-warning.svg';
import { ReactComponent as AttentionOrange } from 'assets/icons/attentionOrange.svg';
import { ReactComponent as BankAccountError } from 'assets/icons/bank-error.svg';
import { ReactComponent as BankAccountErrorModal } from 'assets/icons/bank-error-modal.svg';
import { ReactComponent as BankAccountValid } from 'assets/icons/bank-valid.svg';
import { ReactComponent as BankAccountWarning } from 'assets/icons/bank-warning.svg';
import { ReactComponent as BankAccountDefault } from 'assets/icons/bank.svg';
import { ReactComponent as BenefitsActive } from 'assets/icons/benefits-active.svg';
import { ReactComponent as BenefitsComplete } from 'assets/icons/benefits-complete.svg';
import { ReactComponent as BenefitsError } from 'assets/icons/benefits-error.svg';
import { ReactComponent as BenefitsInactive } from 'assets/icons/benefits-inactive.svg';
import { ReactComponent as BenefitsPending } from 'assets/icons/benefits-pending.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';
import { ReactComponent as CancelIconSmaller } from 'assets/icons/cancel-icon-smaller.svg';

import { ReactComponent as ChatBubble } from 'assets/icons/chat-bubble.svg';
import { ReactComponent as Checkbox } from 'assets/icons/checkbox.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg';
import { ReactComponent as CheckboxDefault } from 'assets/icons/checkbox-default.svg';
import { ReactComponent as CheckboxError } from 'assets/icons/checkbox-error.svg';
import { ReactComponent as CheckboxValid } from 'assets/icons/checkbox-valid.svg';
import { ReactComponent as CheckboxWarning } from 'assets/icons/checkbox-warning.svg';
import { ReactComponent as CheckboxMark } from 'assets/icons/checkmark-icon.svg';
import { ReactComponent as CloseIconDisabled } from 'assets/icons/close-icon-valid-disabled.svg';
import { ReactComponent as CloseIconValid } from 'assets/icons/close-icon-valid.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as CloseIconBlack } from 'assets/icons/close-icon-black.svg';
import { ReactComponent as Confirmed } from 'assets/icons/confirmed.svg';
import { ReactComponent as DocumentError } from 'assets/icons/doc-icon-error.svg';
import { ReactComponent as DocumentsErrorModal } from 'assets/icons/documents-error-modal.svg';
import { ReactComponent as DocumentErrorModal } from 'assets/icons/doc-icon-error-modal.svg';
import { ReactComponent as DocumentValid } from 'assets/icons/doc-icon-valid.svg';
import { ReactComponent as DocumentWarning } from 'assets/icons/doc-icon-warning.svg';
import { ReactComponent as DocumentDefault } from 'assets/icons/doc-icon.svg';
import { ReactComponent as PngFileIcon } from 'assets/icons/doc-image-error.svg';
import { ReactComponent as DocCloseError } from 'assets/icons/doc-x-error.svg';
import { ReactComponent as DocCloseErrorDisabled } from 'assets/icons/doc-x-error-disabled.svg';
import { ReactComponent as DownloadIconWhite } from 'assets/icons/download-icon-white.svg';
import { ReactComponent as DownloadArrow } from 'assets/icons/download-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as EntranceExam } from 'assets/icons/entrance-exam.svg';
import { ReactComponent as ExpiredEmoji } from 'assets/icons/expired-emoji.svg';
import { ReactComponent as FamilyGroupError } from 'assets/icons/family-icon-error.svg';
import { ReactComponent as FamilyGroupErrorModal } from 'assets/icons/family-icon-error-modal.svg';
import { ReactComponent as FamilyGroupValid } from 'assets/icons/family-icon-valid.svg';
import { ReactComponent as FamilyGroupWarning } from 'assets/icons/family-icon-warning.svg';
import { ReactComponent as FamilyGroup } from 'assets/icons/family-icon.svg';
import { ReactComponent as GeoLocationError } from 'assets/icons/geo-icon-error.svg';
import { ReactComponent as GeoLocationErrorModal } from 'assets/icons/geo-icon-error-modal.svg';
import { ReactComponent as GeoLocationValid } from 'assets/icons/geo-icon-valid.svg';
import { ReactComponent as GeoLocationWarning } from 'assets/icons/geo-icon-warning.svg';
import { ReactComponent as GeoLocationDefault } from 'assets/icons/geo-icon.svg';
import { ReactComponent as GlassesEmoji } from 'assets/icons/glasses-emoji.svg';
import { ReactComponent as GreenCheckCircle } from 'assets/icons/green-check.svg';
import { ReactComponent as HealthActive } from 'assets/icons/health-active.svg';
import { ReactComponent as HealthComplete } from 'assets/icons/health-complete.svg';
import { ReactComponent as HealthError } from 'assets/icons/health-error.svg';
import { ReactComponent as HealthInactive } from 'assets/icons/health-inactive.svg';
import { ReactComponent as HealthPending } from 'assets/icons/health-pending.svg';
import { ReactComponent as HeartEmoji } from 'assets/icons/heart-emoji.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image-icone.svg';
import { ReactComponent as ImageUploadError } from 'assets/icons/image-upload-error.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as KnowMore } from 'assets/icons/know-more.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus-icon.svg';
import { ReactComponent as MinusIconDisabled } from 'assets/icons/minus-icon-disabled.svg';
import { ReactComponent as ModalIconClose } from 'assets/icons/modal-icon-close.svg';
import { ReactComponent as NavigateMobileLeft } from 'assets/icons/navigate-mobile-left.svg';
import { ReactComponent as NavigateMobileRight } from 'assets/icons/navigate-mobile-right.svg';
import { ReactComponent as PdfFileIcon } from 'assets/icons/pdf-file-icon.svg';
import { ReactComponent as PdfFileDisabledIcon } from 'assets/icons/pdf-file-disabled-icon.svg';
import { ReactComponent as PlusIconDisabled } from 'assets/icons/plus-icon-disabled.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { ReactComponent as ProfileActive } from 'assets/icons/profile-active.svg';
import { ReactComponent as ProfileComplete } from 'assets/icons/profile-complete.svg';
import { ReactComponent as ProfileError } from 'assets/icons/profile-error.svg';
import { ReactComponent as ProfileErrorModal } from 'assets/icons/profile-error-modal.svg';
import { ReactComponent as ProfileInactive } from 'assets/icons/profile-inactive.svg';
import { ReactComponent as ProfilePending } from 'assets/icons/profile-pending.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload-icon.svg';
import { ReactComponent as RemoveCircleOutline } from 'assets/icons/remove-circle-outline.svg';
import { ReactComponent as ReportProblem } from 'assets/icons/report-problem-icon.svg';
import { ReactComponent as ReturnIcon } from 'assets/icons/return-icon.svg';
import { ReactComponent as ScanFileError } from 'assets/icons/scan-icon-error.svg';
import { ReactComponent as ScanFileValid } from 'assets/icons/scan-icon-valid.svg';
import { ReactComponent as ScanFileWarning } from 'assets/icons/scan-icon-warning.svg';
import { ReactComponent as ScanFileDefault } from 'assets/icons/scan-icon.svg';
import { ReactComponent as SearchField } from 'assets/icons/search-field.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-icon.svg';
import { ReactComponent as UploadBlueCircle } from 'assets/icons/upload-blue-circle.svg';
import { ReactComponent as UploadDocsIcons } from 'assets/icons/upload-docs-icon.svg';
import { ReactComponent as UploadDisabledDocsIcon } from 'assets/icons/upload-disabled-docs-icon.svg';
import { ReactComponent as UploadIconBlue } from 'assets/icons/upload-icon-blue.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-icon.svg';
import { ReactComponent as UserIconError } from 'assets/icons/user-icon-error.svg';
import { ReactComponent as UserIconValid } from 'assets/icons/user-icon-valid.svg';
import { ReactComponent as UserIconWarning } from 'assets/icons/user-icon-warning.svg';
import { ReactComponent as UserIconDefault } from 'assets/icons/user-icon.svg';
import { ReactComponent as LogoAndName } from 'assets/img/logo-name.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-icon.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron_right.svg';
import { ReactComponent as AccountCircle } from 'assets/icons/account_circle.svg';
import { ReactComponent as IterisLogoColor } from 'assets/icons/iteris-logotipo-color.svg';
import { ReactComponent as IterisSymbolColor } from 'assets/icons/iteris-symbol-color.svg';
import { ReactComponent as OpenBlank } from 'assets/icons/open-blank.svg';
import { ReactComponent as ObservationNotificationIcon } from 'assets/icons/observation-notification.svg';
import { ReactComponent as TriangleWarning } from 'assets/icons/triangle-warning.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as OpenLink } from 'assets/icons/open-link.svg';

import React from 'react';

export type IconTypes =
  | 'profileErrorModal'
  | 'observationNotificationIcon'
  | 'alertCircle'
  | 'downloadArrow'
  | 'arrowIcon'
  | 'chatBubble'
  | 'userIconDefault'
  | 'userIconWarning'
  | 'userIconValid'
  | 'userIconError'
  | 'arrowDown'
  | 'arrowDownValid'
  | 'arrowDownWarning'
  | 'arrowDownError'
  | 'admissionActive'
  | 'admissionInactive'
  | 'admissionComplete'
  | 'admissionError'
  | 'admissionPending'
  | 'benefitsActive'
  | 'benefitsInactive'
  | 'benefitsComplete'
  | 'benefitsError'
  | 'benefitsPending'
  | 'healthActive'
  | 'healthInactive'
  | 'healthComplete'
  | 'healthError'
  | 'healthPending'
  | 'profileActive'
  | 'profileInactive'
  | 'profileComplete'
  | 'profileError'
  | 'profilePending'
  | 'geoLocationDefault'
  | 'geoLocationWarning'
  | 'geoLocationValid'
  | 'geoLocationError'
  | 'geoLocationErrorModal'
  | 'documentDefault'
  | 'documentWarning'
  | 'documentValid'
  | 'documentError'
  | 'documentErrorModal'
  | 'documentsErrorModal'
  | 'scanFileDefault'
  | 'scanFileWarning'
  | 'scanFileValid'
  | 'scanFileError'
  | 'familyGroupDefault'
  | 'familyGroupWarning'
  | 'familyGroupValid'
  | 'familyGroupError'
  | 'familyGroupErrorModal'
  | 'logoAndName'
  | 'heartEmoji'
  | 'glassesEmoji'
  | 'bankAccountDefault'
  | 'bankAccountWarning'
  | 'bankAccountValid'
  | 'bankAccountError'
  | 'bankAccountErrorModal'
  | 'expiredEmoji'
  | 'checkboxDefault'
  | 'checkbox'
  | 'checkboxWarning'
  | 'checkboxError'
  | 'checkboxValid'
  | 'imageIcon'
  | 'uploadIcon'
  | 'reloadIcon'
  | 'imageUploadError'
  | 'modalIconClose'
  | 'editIcon'
  | 'checkIcon'
  | 'aleloLogo'
  | 'amilLogo'
  | 'returnIcon'
  | 'closeIcon'
  | 'closeIconBlack'
  | 'infoIcon'
  | 'uploadDocsIcon'
  | 'pngFileIcon'
  | 'docCloseError'
  | 'closeIconValid'
  | 'pdfFileIcon'
  | 'entranceExam'
  | 'downloadIconWhite'
  | 'uploadIconBlue'
  | 'minusIcon'
  | 'plusIconDisabled'
  | 'plusIcon'
  | 'navigateMobileRight'
  | 'navigateMobileLeft'
  | 'attentionOrange'
  | 'closeIconDisabled'
  | 'greenCheckCircle'
  | 'starIcon'
  | 'removeCircleOutline'
  | 'addCircleOutline'
  | 'cancelIcon'
  | 'cancelIconSmaller'
  | 'confirmed'
  | 'searchField'
  | 'checkboxMarkIcon'
  | 'knowmore'
  | 'question'
  | 'uploadBlueCircle'
  | 'calendarIcon'
  | 'reportProblem'
  | 'minusIconDisabled'
  | 'docCloseErrorDisabled'
  | 'uploadDisabledDocsIcon'
  | 'pdfFileDisabledIcon'
  | 'chevronRight'
  | 'accountCircle'
  | 'iterisLogoColor'
  | 'iterisSymbolColor'
  | 'triangleWarning'
  | 'deleteIcon'
  | 'warning'
  | 'openBlank'
  | 'openLink';

type IconProps = {
  name: IconTypes | '';
};

const icons: Record<IconTypes, React.ReactElement> = {
  observationNotificationIcon: <ObservationNotificationIcon />,
  alertCircle: <AlertCircle />,
  arrowIcon: <ArrowIcon />,
  downloadArrow: <DownloadArrow />,
  arrowDownValid: <ArrowValid />,
  arrowDownWarning: <ArrowWarning />,
  arrowDownError: <ArrowError />,
  chatBubble: <ChatBubble />,
  userIconDefault: <UserIconDefault />,
  userIconWarning: <UserIconWarning />,
  userIconValid: <UserIconValid />,
  userIconError: <UserIconError />,
  arrowDown: <ArrowDown />,
  admissionActive: <AdmissionActive />,
  admissionInactive: <AdmissionInactive />,
  admissionComplete: <AdmissionComplete />,
  admissionError: <AdmissionError />,
  admissionPending: <AdmissionPending />,
  benefitsActive: <BenefitsActive />,
  benefitsInactive: <BenefitsInactive />,
  benefitsComplete: <BenefitsComplete />,
  benefitsError: <BenefitsError />,
  benefitsPending: <BenefitsPending />,
  healthActive: <HealthActive />,
  healthInactive: <HealthInactive />,
  healthComplete: <HealthComplete />,
  healthError: <HealthError />,
  healthPending: <HealthPending />,
  profileActive: <ProfileActive />,
  profileInactive: <ProfileInactive />,
  profileComplete: <ProfileComplete />,
  profileError: <ProfileError />,
  profileErrorModal: <ProfileErrorModal />,
  profilePending: <ProfilePending />,
  geoLocationDefault: <GeoLocationDefault />,
  geoLocationWarning: <GeoLocationWarning />,
  geoLocationValid: <GeoLocationValid />,
  geoLocationError: <GeoLocationError />,
  geoLocationErrorModal: <GeoLocationErrorModal />,
  documentDefault: <DocumentDefault />,
  documentWarning: <DocumentWarning />,
  documentValid: <DocumentValid />,
  documentError: <DocumentError />,
  documentErrorModal: <DocumentErrorModal />,
  documentsErrorModal: <DocumentsErrorModal />,
  scanFileDefault: <ScanFileDefault />,
  scanFileWarning: <ScanFileWarning />,
  scanFileValid: <ScanFileValid />,
  scanFileError: <ScanFileError />,
  familyGroupDefault: <FamilyGroup />,
  familyGroupWarning: <FamilyGroupWarning />,
  familyGroupValid: <FamilyGroupValid />,
  familyGroupError: <FamilyGroupError />,
  familyGroupErrorModal: <FamilyGroupErrorModal />,
  bankAccountDefault: <BankAccountDefault />,
  bankAccountWarning: <BankAccountWarning />,
  bankAccountValid: <BankAccountValid />,
  bankAccountError: <BankAccountError />,
  bankAccountErrorModal: <BankAccountErrorModal />,
  logoAndName: <LogoAndName />,
  heartEmoji: <HeartEmoji />,
  glassesEmoji: <GlassesEmoji />,
  expiredEmoji: <ExpiredEmoji />,
  checkboxDefault: <CheckboxDefault />,
  checkbox: <Checkbox />,
  checkboxValid: <CheckboxValid />,
  checkboxWarning: <CheckboxWarning />,
  checkboxError: <CheckboxError />,
  imageIcon: <ImageIcon />,
  uploadIcon: <UploadIcon />,
  reloadIcon: <ReloadIcon />,
  imageUploadError: <ImageUploadError />,
  modalIconClose: <ModalIconClose />,
  editIcon: <EditIcon />,
  checkIcon: <CheckIcon />,
  returnIcon: <ReturnIcon />,
  infoIcon: <InfoIcon />,
  aleloLogo: <AleloLogo />,
  amilLogo: <AmilLogo />,
  closeIcon: <CloseIcon />,
  closeIconBlack: <CloseIconBlack />,
  uploadDocsIcon: <UploadDocsIcons />,
  pngFileIcon: <PngFileIcon />,
  docCloseError: <DocCloseError />,
  closeIconValid: <CloseIconValid />,
  pdfFileIcon: <PdfFileIcon />,
  entranceExam: <EntranceExam />,
  downloadIconWhite: <DownloadIconWhite />,
  uploadIconBlue: <UploadIconBlue />,
  plusIcon: <PlusIcon />,
  plusIconDisabled: <PlusIconDisabled />,
  minusIcon: <MinusIcon />,
  navigateMobileRight: <NavigateMobileRight />,
  navigateMobileLeft: <NavigateMobileLeft />,
  attentionOrange: <AttentionOrange />,
  closeIconDisabled: <CloseIconDisabled />,
  greenCheckCircle: <GreenCheckCircle />,
  starIcon: <StarIcon />,
  removeCircleOutline: <RemoveCircleOutline />,
  addCircleOutline: <AddCircleOutline />,
  cancelIcon: <CancelIcon />,
  cancelIconSmaller: <CancelIconSmaller />,
  confirmed: <Confirmed />,
  searchField: <SearchField />,
  checkboxMarkIcon: <CheckboxMark />,
  knowmore: <KnowMore />,
  question: <Question />,
  uploadBlueCircle: <UploadBlueCircle />,
  calendarIcon: <CalendarIcon />,
  reportProblem: <ReportProblem />,
  minusIconDisabled: <MinusIconDisabled />,
  docCloseErrorDisabled: <DocCloseErrorDisabled />,
  uploadDisabledDocsIcon: <UploadDisabledDocsIcon />,
  pdfFileDisabledIcon: <PdfFileDisabledIcon />,
  chevronRight: <ChevronRight />,
  accountCircle: <AccountCircle />,
  iterisLogoColor: <IterisLogoColor />,
  iterisSymbolColor: <IterisSymbolColor />,
  warning: <Warning />,
  openBlank: <OpenBlank />,
  openLink: <OpenLink />,
  triangleWarning: <TriangleWarning />,
  deleteIcon: <DeleteIcon />,
};

function Icon({ name }: IconProps): React.ReactElement | null {
  if (name) {
    const Component = icons[name];
    return Component;
  }

  return null;
}

export default Icon;

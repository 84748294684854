import axios from 'axios';
import EmailValidation from 'types/models/EmailValidation';
import Login from 'types/models/Login';

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

async function login({ username, password }: Login) {
  const params = {
    username,
    password,
  };

  return axios.get(`${process.env.REACT_APP_URL_API}/jwt`, {
    params,
  });
}

async function validateEmail({ email }: EmailValidation) {
  const params = {
    email,
  };

  return axios.post(`${process.env.REACT_APP_URL_API}/login`, { ...params });
}

const LoginService = { login, validateEmail };

export default LoginService;

/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import { format, parse } from 'date-fns';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, UseFormReturn } from 'react-hook-form';
import Datepicker from '../Datepicker';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileDateBirth({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'dateOfBirth',
    admissionForm,
    profileMethods
  );

  return (
    <label id="dateOfBirth" className="form-field" htmlFor="dateOfBirth">
      <span className="label">Data de Nascimento*</span>
      <Controller
        control={profileMethods.control}
        name="dateOfBirth"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur } }) => (
          <Datepicker
            onBlur={onBlur}
            className={
              hasAnyError ? 'field -input-error date' : 'field -input date'
            }
            dateValue={profileMethods.getValues('dateOfBirth') || ''}
            selected={
              profileMethods.watch('dateOfBirth')
                ? parse(
                    profileMethods.watch('dateOfBirth'),
                    'dd/MM/yyyy',
                    new Date()
                  )
                : null
            }
            onSelect={(date: Date) => {
              onChange(format(date!, 'dd/MM/yyyy'));
              onBlur();
            }}
            disabled={shouldDisable}
          />
        )}
      />
      {errors && errors.dateOfBirth?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileDateBirth;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CitySelect from 'components/Forms/Shared/CitySelect/CitySelect';
import StateSelect from 'components/Forms/Shared/StateSelect/StateSelect';
import { UseFormReturn } from 'react-hook-form';
import AuthContext from 'contexts/auth';
import { getFormValidationProps } from '../FormUtils';

type AutoFillFields = {
  photo: string;
  name: string;
  deficiency: string;
  schooling: string;
  email: string;
  residentialPhone: string;
  mobileNumber: string;
  parentage: string;
  stateOfBirth: string;
  cityOfBirth: string;
  dateOfBirth: string;
  civilStatus: object;
  sex: string;
  race: string;
};

function ProfilePlaceBirth({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = useContext(AuthContext);

  const [autoFill, setAutoFill] = useState<AutoFillFields>({
    photo: '',
    name: '',
    deficiency: '',
    schooling: '',
    email: '',
    residentialPhone: '',
    mobileNumber: '',
    parentage: '',
    stateOfBirth: '',
    cityOfBirth: '',
    dateOfBirth: '',
    civilStatus: {},
    sex: '',
    race: '',
  });

  const { errors } = profileMethods.formState;

  const cityFormValidationProps = getFormValidationProps(
    'cityOfBirth',
    admissionForm,
    profileMethods
  );

  const stateFormValidationProps = getFormValidationProps(
    'stateOfBirth',
    admissionForm,
    profileMethods
  );

  return (
    <Row>
      <Col sm={6}>
        <StateSelect
          selected={profileMethods.getValues('stateOfBirth')}
          formValidationProps={stateFormValidationProps}
          control={profileMethods.control}
          errors={errors}
          onChangeState={(selected: { sigla: string; label: string }) => {
            setAutoFill((s) => ({
              ...s,
              state: selected.sigla,
            }));
            if (profileMethods.getValues('cityOfBirth')) {
              profileMethods.setValue('cityOfBirth', '', {
                shouldValidate: true,
              });
            }
          }}
        />
      </Col>
      <Col sm={6}>
        <CitySelect
          formValidationProps={cityFormValidationProps}
          selected={profileMethods.getValues('cityOfBirth')}
          initials={profileMethods.getValues('stateOfBirth')}
          onChangeCity={(selected: { label: string }) => {
            setAutoFill((s) => ({
              ...s,
              city: selected.label,
            }));
          }}
          control={profileMethods.control}
          errors={errors}
        />
      </Col>
    </Row>
  );
}

export default ProfilePlaceBirth;

import React from 'react';
import { BaseProps } from './ButtonProps';
import './style.scss';

function Button({
  className = '',
  outline = false,
  disabled = false,
  onClick = () => {},
  floating = false,
  children,
}: BaseProps) {
  return (
    <button
      className={`app-button${outline ? ' outline' : ''}${
        floating ? ' floating' : ''
      } ${className}`}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;

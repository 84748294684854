/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

const deficiency = [
  { value: 'Não é portador', label: 'Não é portador' },
  { value: 'Física', label: 'Física' },
  { value: 'Auditiva', label: 'Auditiva' },
  { value: 'Visual', label: 'Visual' },
  { value: 'Mental', label: 'Mental' },
  { value: 'Múltipla', label: 'Múltipla' },
  { value: 'Reabilitado', label: 'Reabilitado' },
];

function ProfileDisability({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'deficiency',
    admissionForm,
    profileMethods
  );

  return (
    <label id="deficiency" className="form-field" htmlFor="deficiency">
      <span className="label">Deficiente Físico*</span>
      <Controller
        name="deficiency"
        control={profileMethods.control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name, ref, onBlur } }) => (
          <Select
            placeholder=""
            styles={customStyles(hasAnyError, shouldDisable)}
            options={deficiency}
            name={name}
            value={deficiency.find((r) => r.value === value)}
            onChange={(selectedOption: any) => {
              onChange(selectedOption.value);
            }}
            onBlur={onBlur}
            isSearchable={!shouldDisable}
            menuIsOpen={shouldDisable ? false : undefined}
          />
        )}
      />
      {errors && errors.deficiency?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileDisability;

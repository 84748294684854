import { AdmissionFormConfig, types, UserStore } from './types';

function reducer(state: UserStore, action: any): UserStore {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        loggedUser: action.payload,
      };

    case types.SAVE_CREDENTIALS:
      return {
        ...state,
        saveCredentials: action.payload,
      };

    case types.UPDATE_STEPS:
      return {
        ...state,
        steps: action.payload,
      };

    case types.SAVE_CONTRACT_TYPE:
      return {
        ...state,
        contractType: action.payload,
      };

    case types.APPROVE_MEDICAL_STEP:
      return {
        ...state,
        approvedMedicalStep: action.payload,
      };

    case types.SAVE_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case types.STAGE_ADMISSION:
      return {
        ...state,
        stageAdmission: action.payload,
      };

    case types.FORM_ADMISSION:
      return {
        ...state,
        admissionForm: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;

import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { fetchStates } from 'services/stateService';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE } from '../FormUtils';

export type StateSelectProps = {
  formValidationProps: any;
  onChangeState: any;
  control: any;
  errors: any;
  selected?: string;
  controlerName?: string;
  titleText?: string;
};

function StateSelect({
  formValidationProps,
  onChangeState,
  control,
  errors,
  selected,
  controlerName = 'stateOfBirth',
  titleText = 'Estado de Nascimento*',
}: StateSelectProps) {
  const [states, setStates] = useState<
    Array<{ id: string; nome: string; sigla: string }>
  >([]);
  const [localSelected, setSelected] = useState({ sigla: '', label: '' });
  const { shouldDisable } = formValidationProps;

  useEffect(() => {
    fetchStates().then((st) => setStates(st.data));
  }, []);

  useEffect(() => {
    if (selected && states.length) {
      const selectedFromAPI = states.filter((s) => s.sigla === selected)[0];

      setSelected({
        sigla: selectedFromAPI?.sigla || '',
        label: selectedFromAPI?.nome || '',
      });
    }
  }, [selected, states]);

  const isErrorRequired =
    errors[controlerName]?.type === 'required' ||
    (controlerName === 'workCard.UF' &&
      errors.workCard?.UF?.type === 'required');

  return (
    <label className="form-field" htmlFor="stateOfBirth">
      <span className="label">{titleText}</span>
      <Controller
        name={controlerName}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name, ref, onBlur } }) => (
          <Select
            defaultValue={{ sigla: '', label: '' }}
            name={name}
            value={localSelected}
            onChange={(s) => {
              onChange(s?.sigla);
              onChangeState(s);
              setSelected({ sigla: s?.sigla || '', label: s?.label || '' });
            }}
            styles={customStyles(
              formValidationProps.hasAnyError || isErrorRequired,
              formValidationProps.shouldDisable
            )}
            placeholder=""
            options={states
              .sort((a, b) => a.nome.localeCompare(b.nome))
              .map((st) => {
                const { nome, sigla } = st;
                return {
                  sigla,
                  label: nome,
                };
              })}
            onBlur={onBlur}
            isSearchable={!shouldDisable}
            menuIsOpen={shouldDisable ? false : undefined}
          />
        )}
      />
      {errors && isErrorRequired && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {formValidationProps.hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default StateSelect;

import React, { useContext, useEffect, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StagesService from 'services/stagesService';
import { UserSteps } from 'types/common/UserSteps';
import StageOperations from 'components/StepsLoader/StageOperations';
import StageAdapters from 'components/StepsLoader/StageAdapters';
import defaultSteps from 'constants/defaultStages';
import { StageAdmission } from 'types/models/StageAdmission';
import AuthContext from 'contexts/auth';
import { ContractType } from 'types/common/ContractType';
import Icon from 'components/Icon';
import { Types } from 'components/NavigationState/types';
import { StageAdmissionSteps } from 'types/common/StageAdmissionSteps';
import StepsController from 'components/StateController/StepsController';
import {
  steps as saveSteps,
  stageAdmission as saveStageAdmission,
} from 'store/user/actions';
import useCarousel from 'hooks/sliderCarousel';
import './style.scss';

export type HomeProgressProps = {
  preventNavigation: (flag: boolean) => void;
};

function HomeProgress({ preventNavigation }: HomeProgressProps) {
  const { handlers, style } = useCarousel();
  const { t } = useTranslation('home');
  const navigate = useNavigate();
  const stepsController = StepsController();
  const { state } = useContext(AuthContext);
  const [steps, setSteps] = useState<UserSteps>();

  useEffect(() => {
    StagesService.getStages().then((res) => {
      const { data } = res;

      const updatedStages = StageOperations.updateStatus(
        data.map((element: StageAdmission) => ({
          stage: StageAdapters.stageAdapter[element.name],
          status:
            StageAdapters.stageStatusAdapter[element.stageAdmissionStatus],
        })),
        defaultSteps[state.contractType as ContractType]
      );

      const stageAdmission: StageAdmissionSteps[] = data.map(
        (element: StageAdmission) => ({
          status: element.stageAdmissionStatus,
          name: element.name,
          observation: element.observation,
          validationProfiles: element.validationProfiles,
        })
      );

      const lastCompleteStage = StageOperations.findLastMatch(updatedStages, [
        'Complete',
        'Pending',
      ]);

      const nextActiveStage = StageOperations.getNextStage(
        lastCompleteStage,
        state.contractType
      );

      const nextActiveStageStatus = updatedStages[nextActiveStage]?.status;

      const currentStages =
        nextActiveStageStatus === 'Error'
          ? updatedStages
          : StageOperations.updateStatus(
              [{ stage: nextActiveStage, status: 'Active' }],
              updatedStages
            );

      stepsController.dispatch(saveSteps(currentStages));
      stepsController.dispatch(saveStageAdmission(stageAdmission));

      setSteps(currentStages);
    });
  }, []);

  function getStatusLabel(status: string) {
    const statusMap: Record<string, string> = {
      Inactive: t('aplication.progress.status.inactive'),
      Active: t('aplication.progress.status.active'),
      Error: t('aplication.progress.status.error'),
      Pending: t('aplication.progress.status.pending'),
      Complete: t('aplication.progress.status.complete'),
    };

    return statusMap[status] || '';
  }

  return (
    <section className="home-progress-container">
      <Container className="home-progress">
        <h1 className="progress-title">{t('aplication.progress.title')}</h1>
        <Col
          className="d-flex cards-slider"
          {...handlers}
          style={{
            ...style,
          }}
        >
          {steps &&
            Object.keys(steps).map((key, idx) => {
              const step = steps[key];
              return (
                <div
                  className={`card-step -${step.status}`}
                  key={key}
                  tabIndex={idx}
                  role="button"
                  onKeyDown={() => {}}
                  onClick={() => {
                    if (
                      step.status === 'Active' &&
                      step.path === '/exam' &&
                      !state.approvedMedicalStep
                    ) {
                      preventNavigation(true);
                      return;
                    }

                    if (
                      step.status !== 'Complete' &&
                      step.status !== 'Inactive'
                    ) {
                      navigate(step.path);
                    }
                  }}
                >
                  <div className="status">
                    <Icon name={Types[key][step.status]} />
                    <span className="text">
                      {t(`card.status.${step.status.toLowerCase()}`)}
                    </span>
                  </div>
                  <div className="card-step-description">
                    <h1 className={`step-name ${step.status}`}>{step.name}</h1>
                    <span>{step.description}</span>
                  </div>
                  <div className="card-step-footer">
                    <hr />
                    <h2 className={step.status}>
                      {getStatusLabel(step.status)}
                    </h2>
                  </div>
                </div>
              );
            })}
        </Col>
      </Container>
    </section>
  );
}

export default HomeProgress;

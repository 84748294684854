/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import AuthContext from 'contexts/auth';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileEmergencyPhoneNumber({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'emergencyContact.phoneNumber',
    admissionForm,
    profileMethods
  );

  return (
    <label
      id="emergencyContact.phoneNumber"
      className="form-field"
      htmlFor="emergencyContact.phoneNumber"
    >
      <span className="label">Telefone de contato*</span>
      <Controller
        control={profileMethods.control}
        name="emergencyContact.phoneNumber"
        rules={{ required: true, minLength: 14 }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <input
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            onChange={(e) => {
              onChange(masks.mobilePhone(e.target.value));
            }}
            value={
              profileMethods.getValues('emergencyContact.phoneNumber') || ''
            }
            onBlur={onBlur}
            readOnly={shouldDisable}
          />
        )}
      />

      {errors && errors.emergencyContact?.phoneNumber?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}

      {errors && errors.emergencyContact?.phoneNumber?.type === 'minLength' && (
        <span className="error-text">
          O telefone celular é inválido, por favor revise.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileEmergencyPhoneNumber;

import api from 'configs/api';

async function getCurrentMedicalExam() {
  return api.get('/medicalExam');
}

async function removeDocument() {
  return api.delete('/medicalExam/document-signed');
}

const MedicalExamService = { getCurrentMedicalExam, removeDocument };

export default MedicalExamService;

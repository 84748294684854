function updateStatus(
  updates: Array<{ stage: string; status: string }>,
  oldStages: Record<string, Record<string, string>>
) {
  const newStages = { ...oldStages };

  updates.forEach((e) => {
    const { stage, status } = e;

    if (newStages[stage]) {
      newStages[stage] = {
        ...newStages[stage],
        status,
      };
    }
  });

  return newStages;
}

function findLastMatch(
  stages: Record<string, Record<string, string>>,
  match: Array<string>
) {
  let lastComplete = '';

  Object.keys(stages).forEach((k) => {
    if (match.filter((m) => m === stages[k].status).length > 0) {
      lastComplete = k;
    }
  });

  return lastComplete;
}

function getNextStage(stage: string, category: string) {
  const nextStage: Record<string, Record<string, string>> = {
    CLT: {
      admission: 'benefits',
      benefits: 'health',
      health: 'profile',
      profile: 'done',
    },
    FOREIGN_CLT: {
      admission: 'benefits',
      benefits: 'health',
      health: 'profile',
      profile: 'done',
    },
    INTERNSHIP: {
      admission: 'benefits',
      benefits: 'health',
      health: 'profile',
      profile: 'done',
    },
    PJ: {
      admission: 'profile',
      profile: 'done',
    },
  };

  return nextStage[category][stage] || 'admission';
}

const StageOperations = {
  updateStatus,
  findLastMatch,
  getNextStage,
};

export default StageOperations;

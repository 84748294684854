import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CUSTOM_ERROR_MESSAGE } from 'components/Forms/Shared/FormUtils';
import Icon, { IconTypes } from 'components/Icon';
import { getToken } from 'configs/AuthService';
import UPLOAD_MAX_SIZE from 'constants/documentUpload';
import ImageUtils from 'utils/ImageUtils';
import './styles.scss';

export type DocumentProps = {
  uploadedFile: (name: string) => void;
  id: string;
  closeAction: () => void;
  nameDocument: string;
  setDisableNextButton: (b: boolean) => void;
  disableUploadComponent: boolean;
  invalid?: boolean;
};

export type statesType = {
  default: boolean;
  invalid: boolean;
  valid: boolean;
};

function ExamUpload({
  uploadedFile,
  id,
  closeAction,
  nameDocument,
  setDisableNextButton,
  disableUploadComponent,
  invalid,
}: DocumentProps) {
  const states = {
    default: true,
    invalid: false,
    valid: false,
  };

  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState<IconTypes | ''>('');
  const [changeInput, setChangeInput] = useState(states);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(0);
  const [oldNameFile, setOldNameFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorField, setErrorField] = useState(invalid);

  const verifyNameFile = (
    nameFile: string,
    sizeFile: number,
    handleConfirmFunction: () => void
  ) => {
    const name = nameFile?.slice(-4);
    setSize(sizeFile);

    if (sizeFile > UPLOAD_MAX_SIZE) {
      setMessage('O arquivo deve ter um tamanho de, no máximo, 5MB.');
      setIcon('pngFileIcon');
      setChangeInput({ default: false, invalid: true, valid: false });
      setFileName(nameFile);
      setDisableNextButton(true);
      uploadedFile('');
    } else if (
      !ImageUtils.isValidFormat(nameFile, ['jpg', 'jpeg', 'png', 'pdf'])
    ) {
      setMessage('O arquivo deve ser do tipo JPG, JPEG, PNG ou PDF.');
      setIcon('pdfFileIcon');
      setChangeInput({ default: false, invalid: true, valid: false });
      setFileName(nameFile);
      setDisableNextButton(true);
    } else {
      setIcon(
        name.toLocaleLowerCase() === '.pdf' ? 'pdfFileIcon' : 'pngFileIcon'
      );
      setChangeInput({
        default: false,
        invalid: invalid || false,
        valid: true,
      });
      handleConfirmFunction();
      setFileName(nameFile);
    }
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (nameDocument) {
      setOldNameFile(nameDocument);
    }
    const file = event.target.files![0];
    verifyNameFile(file.name, file.size, () => {
      handleConfirm(file);
    });
  };

  const handleClose = () => {
    setErrorField(false);
    if (isInvalidFileWithoutValidFileSaved()) {
      setChangeInput({ default: true, invalid: false, valid: false });
      uploadedFile(oldNameFile);
      return;
    }
    if (isInvalidFileWithValidFileSaved()) {
      setFileName(oldNameFile);
      setChangeInput({
        default: false,
        invalid: invalid || false,
        valid: true,
      });
      setDisableNextButton(invalid || false);
      setOldNameFile('');
      setMessage('');
      if (invalid) {
        setErrorField(true);
      }
    } else {
      closeAction();
      setChangeInput({ default: true, invalid: false, valid: false });
      uploadedFile('');
      setOldNameFile('');
    }
  };

  const handleConfirm = async (file: File) => {
    setErrorField(false);
    const token = getToken();
    const header = {
      Authorization: `Bearer ${token}`,
    };

    const data = new FormData();
    data.append('file', file);
    setLoading(true);
    setChangeInput({ default: false, invalid: false, valid: true });
    axios
      .post(
        `${process.env.REACT_APP_URL_API}/medicalExam/document-signed`,
        data,
        {
          headers: header,
        }
      )
      .then((res) => {
        uploadedFile(res.data.name);
        setFileName(res.data.name);
        setDisableNextButton(false);
        setMessage(`Arquivo ${res.data.name} anexado com sucesso.`);
        setOldNameFile('');
        setChangeInput({ default: false, invalid: false, valid: true });
      })
      .catch((err) => {
        if (err) {
          setFileName(file.name);
          setIcon('pngFileIcon');
          setChangeInput({ default: false, invalid: true, valid: false });
          setMessage(
            'Ocorreu um erro inesperado, contate o responsável pela sua admissão.'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function isInvalidFileWithoutValidFileSaved() {
    return changeInput.invalid && oldNameFile === '';
  }

  function isInvalidFileWithValidFileSaved() {
    return oldNameFile !== '';
  }

  useEffect(() => {
    if (nameDocument) {
      verifyNameFile(nameDocument, size, () => {});
    }
  }, [nameDocument]);

  function getChangeInputClassName(thisChangeInput: statesType) {
    if (thisChangeInput.invalid) return 'invalid';
    return 'valid';
  }
  return (
    <section className="exam-upload-container">
      {!disableUploadComponent && (
        <>
          {changeInput.default && (
            <label
              htmlFor={`uploadInput-${id}`}
              className="docs-upload-container"
            >
              <div className="docs-input">
                <div className="doc-icon">
                  <Icon name="uploadDocsIcon" />
                </div>

                <input
                  name={nameDocument}
                  onChange={(e) => handleFile(e)}
                  type="file"
                  id={`uploadInput-${id}`}
                  className="uploadInput"
                  disabled={disableUploadComponent}
                />
              </div>
              <div className="upload-subtitle">
                Selecione um arquivo (.jpg, .png ou .pdf) da sua galeria, ele
                deve ser legível.
              </div>
            </label>
          )}
          {(changeInput.invalid || changeInput.valid) && (
            <label
              htmlFor={`uploadInput-${nameDocument}-${getChangeInputClassName(
                changeInput
              )}`}
              className={`docs-upload-container docs-upload-container-${getChangeInputClassName(
                changeInput
              )}`}
            >
              <div className={loading ? 'docs-input disabled' : 'docs-input'}>
                <div className="icons">
                  <div className="doc-icon">
                    <Icon name={icon as IconTypes} />
                    <div className="file-name">
                      <span>{fileName}</span>
                    </div>
                  </div>
                  {!loading && !disableUploadComponent && (
                    <button
                      className="close-icon"
                      type="button"
                      onClick={handleClose}
                    >
                      {!invalid ? (
                        <Icon name="closeIconValid" />
                      ) : (
                        <Icon name="docCloseError" />
                      )}
                    </button>
                  )}
                </div>
                <input
                  name={nameDocument}
                  id={`uploadInput-${nameDocument}-${getChangeInputClassName(
                    changeInput
                  )}`}
                  className="uploadInput"
                  onChange={(e) => handleFile(e)}
                  type="file"
                  disabled={disableUploadComponent}
                />
              </div>
              {!loading && <div className="upload-subtitle">{message}</div>}
              {errorField && (
                <div className="upload-revision">{CUSTOM_ERROR_MESSAGE}</div>
              )}
            </label>
          )}
        </>
      )}
      {disableUploadComponent && (
        <label
          htmlFor={`uploadInput-${fileName}-valid`}
          className="docs-upload-container docs-upload-container-valid"
        >
          <div className="docs-input disabled">
            <div className="icons">
              <div className="doc-icon">
                <Icon name="pdfFileDisabledIcon" />
                <div className="file-name">
                  <span>{fileName}</span>
                </div>
              </div>
            </div>
          </div>
        </label>
      )}
    </section>
  );
}

export default ExamUpload;

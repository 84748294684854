export const RaceColorTexts = {
  'pt-BR': {
    Label: 'Raça/Cor*',
    Error: 'Esse campo é obrigatório.',
    'Black Option': 'Preta',
    'White Option': 'Branca',
    'Yellow Option': 'Amarela',
    'Brown Option': 'Parda',
    'Indigenous Option': 'Indígena',
    'Tooltip Info':
      'Preenchimento necessário pelo próprio empregado, por determinação legal (lei 14553/23). Os dados serão compartilhados com órgãos governamentais conforme lei. O empregado tem ciência de que deve preencher a autodeclaração com veracidade, sob as penas da lei.',
  },
};

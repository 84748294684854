import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { fetchCitiesForState } from 'services/stateService';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE } from '../FormUtils';

export type Props = {
  formValidationProps: any;
  initials: any;
  onChangeCity: any;
  control: any;
  errors: any;
  selected?: string;
  controlerName?: string;
  titleText?: string;
};

function CitySelect({
  formValidationProps,
  control,
  errors,
  initials,
  onChangeCity,
  selected = '',
  controlerName = 'cityOfBirth',
  titleText = 'Cidade de Nascimento*',
}: Props) {
  const { shouldDisable } = formValidationProps;
  const [cities, setCities] = useState<Array<{ id: string; nome: string }>>([]);
  const [localSelected, setSelected] = useState({ label: '' });

  useEffect(() => {
    if (initials) {
      fetchCitiesForState(initials).then((ct) => {
        setSelected({ label: '' });
        setCities(ct.data);
      });
    }
  }, [initials]);

  useEffect(() => {
    if (selected && cities.length) {
      const selectedFromAPI = cities.filter((s) => s.nome === selected)[0];

      setSelected({ label: selectedFromAPI?.nome || '' });
    }
  }, [selected, cities]);

  return (
    <label className="form-field" htmlFor="cityOfBirth">
      <span className="label">{titleText}</span>
      <Controller
        name={controlerName}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name, ref, onBlur } }) => (
          <Select
            noOptionsMessage={() => 'Selecione um Estado'}
            defaultValue={{ label: '' }}
            name={name}
            value={localSelected}
            placeholder=""
            onChange={(s) => {
              onChange(s?.label);
              onChangeCity(s);
              setSelected({ label: s?.label || '' });
            }}
            styles={customStyles(
              formValidationProps.hasAnyError,
              formValidationProps.shouldDisable
            )}
            options={cities
              .sort((a, b) => a.nome.localeCompare(b.nome))
              .map((ct) => {
                const { nome } = ct;
                return { label: nome };
              })}
            onBlur={onBlur}
            isSearchable={!shouldDisable}
            menuIsOpen={shouldDisable ? false : undefined}
          />
        )}
      />
      {errors && errors[controlerName]?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {formValidationProps.hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default CitySelect;

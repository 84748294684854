import React, { useEffect, useState } from 'react';
import './style.scss';
import FormCode from 'components/Forms/FormCode';
import Icon from 'components/Icon';
import { stateTexts, defaultState } from 'constants/texts/CodeCard';

type CodeCardProps = {
  handleCodeNumber: (code: string) => {};
  resendCode: () => {};
  codeError: string;
};

function CodeCard({ handleCodeNumber, resendCode, codeError }: CodeCardProps) {
  const [componentInfo, setComponentInfo] = useState(stateTexts.valid);

  useEffect(() => {
    setComponentInfo(stateTexts[codeError] || stateTexts[defaultState]);
  }, [codeError]);

  async function handleCode(codeNumber: { code: string }) {
    handleCodeNumber(codeNumber.code);
  }

  return (
    <div className="code-container">
      <div className="text">
        <h2 className="description">{componentInfo.title}</h2>
        <h3 className="secondary-description">{componentInfo.description}</h3>
      </div>
      <div className="code">
        <FormCode
          codeNumber={handleCode}
          codeError={codeError}
          resendCode={resendCode}
        />
      </div>
    </div>
  );
}

export default CodeCard;

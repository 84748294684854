/* eslint-disable import/prefer-default-export */
export const BenefitsTexts = {
  'pt-BR': {
    'Description Option Question': 'Selecione a opção desejada:',
    'Main Title': 'Benefícios',
    'Main Description':
      'Defina como você quer distribuir a sua cesta em cada benefício disponível.',
    'Go NextPage': 'Ir para Exame Admissional',
    'Status inactive': 'Não iniciado',
    'Status active': 'Etapa iniciada',
    'Status pending': 'Em validação pelo RH',
    'Status complete': 'Etapa concluída',
    'Status error': 'Há erros nesta etapa',
    'Back Home': 'Voltar para home',
  },
  en: {},
};

import api from 'configs/api';
import { RemoveDocumentProps } from 'types/models/Attachment';

async function removeDocument({
  fileName,
  documentName,
  formAnswerType,
}: RemoveDocumentProps) {
  return api.delete('attachment/document', {
    data: {
      fileName,
      documentName,
      formAnswerType,
    },
  });
}

const AttachmentService = { removeDocument };

export default AttachmentService;

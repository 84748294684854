import { FormStages, StageStatus } from 'types/common/FormStages';

const stageAdapter: Record<FormStages, string> = {
  PRESENTATION: 'profile',
  ENTRANCE_EXAM: 'health',
  BENEFITS: 'benefits',
  FORM_ADMISSION: 'admission',
};

const stageStatusAdapter: Record<StageStatus, string> = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  INACTIVE: 'Inactive',
  COMPLETE: 'Complete',
  ERROR: 'Error',
};

const StageAdapters = { stageAdapter, stageStatusAdapter };

export default StageAdapters;

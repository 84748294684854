import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import './styles.scss';
import { customStylesForBenefits } from 'styles/select/customStylesForBenefits';
import Benefit, { BenefitOption } from 'types/models/Benefit';
import Dependant from 'types/models/Dependant';
import { Col, Row } from 'react-bootstrap';
import masks from 'utils/MasksUtils';
import { useTranslation } from 'react-i18next';
import healthImage from 'assets/img/ImagemSaude.png';
import odontoImage from 'assets/img/ImagemOdonto.png';
import AuthContext from 'contexts/auth';
import BenefitDependants from '../BenefitDependants';
import { SelectBenefit } from '../BenefitsContainer/BenefitsContainer';

export type BenefitDescriptionProps = {
  value: number;
  benefit: Benefit;
  dependants: Dependant[];
  close: () => void;
  selectedValue: (event: number) => void;
  initialValue: number;
  updatedValue: number;
  setSelectedOption: (selected: SelectBenefit) => void;
  selectedOption: SelectBenefit;
  checkDependantBenefit: (check: boolean, dependantId: number) => void;
  checkedDependants: Array<number>;
};

function BenefitDescription({
  checkDependantBenefit,
  value,
  benefit,
  dependants,
  close,
  selectedValue,
  initialValue,
  updatedValue,
  setSelectedOption,
  selectedOption,
  checkedDependants,
}: BenefitDescriptionProps) {
  const [valueInput, setValueInput] = useState(initialValue);
  const [selectValue, setSelectValue] = useState(selectedOption);
  const { t } = useTranslation('benefits');
  const { state } = useContext(AuthContext);
  const nonPossibilityChangeHealthCare =
    'Estagiários não podem alterar o plano, tendo a opção do plano S750.';

  const inputValue = (event: number) => {
    setValueInput(event);
    selectedValue(event);
  };
  const availableAmount = updatedValue + initialValue;
  const availableAmountFixed = Math.round(availableAmount * 10) / 10;

  function errorViewValidator(currentValue: number) {
    return currentValue > availableAmountFixed ? (
      <span className="error-text">*Valor maior que o máximo permitido</span>
    ) : null;
  }

  function errorClass(errorValidation: number) {
    return errorValidation > availableAmountFixed
      ? 'value-input -error'
      : 'value-input';
  }

  function renderAvailable() {
    return (
      <div
        className={
          availableAmount <= 0 ? 'benefits-total -error' : 'benefits-total'
        }
      >
        <span>Valor máximo: {masks.currency(availableAmount)}</span>
      </div>
    );
  }

  function replaceTextToClickableTag(description: string) {
    return description.replace(
      'clique aqui.',
      `<a
              class="benefit-link"
                target="_blank"
                href="${benefit.mandatory ? healthImage : odontoImage}"
                rel="noreferrer"
              >
                ${' '}
                clique aqui.
              </a>`
    );
  }

  useEffect(() => {
    selectedValue(initialValue);
  }, []);

  return (
    <div className="benefit-description-container">
      {!benefit.flagBenefitOption && (
        <Row className="header">
          <Col xs={8} className="ms-auto">
            {renderAvailable()}
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} sm={6} className="benefit-image d-flex">
          <img src={benefit.urlCardImage} alt="Imagem do Benefício" />
        </Col>
        <Col xs={12} sm={6}>
          {!benefit.flagBenefitOption && (
            <span className="value-available">{renderAvailable()}</span>
          )}
          <p className="benefit-text">
            <span
              dangerouslySetInnerHTML={{
                __html: replaceTextToClickableTag(benefit.description),
              }}
            />

            {benefit.flagBenefitOption &&
              state.contractType === 'INTERNSHIP' &&
              benefit.mandatory && (
                <span> {nonPossibilityChangeHealthCare} </span>
              )}
          </p>

          {benefit?.flagBenefitValue && (
            <div className="benefit-value">
              <h3 className="title">Digite o valor:</h3>
              <input
                maxLength={15}
                type="text"
                id="value"
                className={errorClass(valueInput)}
                value={masks.currency(valueInput)}
                onChange={(e) => {
                  const reformatData = Number(
                    e.target.value.replace(/[^0-9]+/g, '')
                  );
                  inputValue(reformatData);
                }}
              />
            </div>
          )}
          {!benefit.flagBenefitOption && errorViewValidator(valueInput)}
        </Col>
      </Row>
      <Row className="mt-3">
        {benefit.flagBenefitOption && (
          <Col xs={12} sm={6}>
            <h2 className="title-options">
              {t('Description Option Question')}
            </h2>
            <div className="plan-select">
              <Select
                defaultValue={selectValue}
                options={benefit.options.map((option: BenefitOption) => {
                  const { name, idBenefitOption, valueBeneficiary } = option;
                  return {
                    label:
                      valueBeneficiary > 0
                        ? `${name}: ${masks.brazilianCurrencyFormat(
                            valueBeneficiary
                          )}`
                        : name,
                    idBenefitOption,
                  };
                })}
                value={selectValue}
                styles={customStylesForBenefits(false, false)}
                onChange={(e) => {
                  setSelectedOption({
                    label: e?.label || '',
                    idBenefitOption: e?.idBenefitOption || -1,
                  });
                  setSelectValue({
                    label: e?.label || '',
                    idBenefitOption: e?.idBenefitOption || -1,
                  });
                }}
              />
            </div>
          </Col>
        )}

        {benefit.flagBenefitOption && (
          <Col xs={12} sm={6}>
            <BenefitDependants
              checkDependantBenefit={checkDependantBenefit}
              dependants={dependants}
              checkedDependants={checkedDependants || []}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default BenefitDescription;

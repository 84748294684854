import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import Icon from 'components/Icon';
import {
  CUSTOM_ERROR_MESSAGE,
  getFormValidationProps,
  isErrorStatus,
  isPendingStatus,
  isValidStatus,
} from 'components/Forms/Shared/FormUtils';
import AttachmentService from 'services/attachmentService';
import FormAnswerType from 'types/enum/FormAnswerType';
import AuthContext from 'contexts/auth';
import GenericModal from 'components/Forms/Shared/GenericModal';
import DocumentUpload from '../DocumentUpload';
import './style.scss';

export type DocumentProps = {
  fieldName: string;
  documentAttachmentMethods: UseFormReturn<any, any>;
  formAnswerType: FormAnswerType;
  isRequired?: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  documentPlaceholderText: string;
};

function MultipleDocument({
  fieldName,
  documentAttachmentMethods,
  formAnswerType,
  isRequired,
  loading,
  setLoading,
  documentPlaceholderText,
}: DocumentProps) {
  const maxFiles = 5;

  const {
    state: { admissionForm },
  } = useContext(AuthContext);

  const { status } = admissionForm;
  const { control } = documentAttachmentMethods;
  const [openX, setOpenX] = useState(false);
  const [openMinus, setOpenMinus] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [fileActionIndex, setFileActionIndex] = useState(-1);
  const [inputRemoveControl, setInputRemoveControl] = useState(-1);
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const modalData = {
    modalTitle: 'Confirmação de exclusão',
    icon: 'deleteIcon',
    title: 'Você deseja realmente excluir este arquivo?',
  };

  const { shouldDisable, hasModified } = getFormValidationProps(
    `${fieldName}`,
    admissionForm,
    documentAttachmentMethods
  );

  const uploadedFile = (name: string, url: string, index: number) => {
    const documentsAttachment = [
      ...documentAttachmentMethods.getValues(fieldName),
    ];

    documentsAttachment.forEach((acc, position) => {
      if (position === index) {
        acc.name = name;
        acc.url = url;
      }
    });
    documentAttachmentMethods.setValue(
      `${fieldName}`,
      [...documentsAttachment],
      { shouldValidate: true }
    );

    if (admissionForm.isEditing) {
      addModifiedAttachment();
    }
  };

  const handleMinus = (index: number) => {
    setFileActionIndex(index);
    if (documentAttachmentMethods.getValues(fieldName)[index].name) {
      setOpenMinus(true);
    } else {
      remove(index);
      setUploadError(false);
    }
  };

  const handleUploadError = (b: boolean): void => {
    setUploadError(b);
    if (b) addModifiedAttachment();
  };

  const addModifiedAttachment = () => {
    if (!admissionForm.modifiedAttachments)
      admissionForm.modifiedAttachments = [];

    if (
      admissionForm.modifiedAttachments.filter((m) => m === fieldName)
        .length === 0
    )
      admissionForm.modifiedAttachments.push(fieldName);
  };

  const removeFile = (index: number) => {
    if (documentAttachmentMethods.getValues(fieldName)[index].name === '') {
      setUploadError(false);
      setInputRemoveControl(index);
    } else {
      setFileActionIndex(index);
      setOpenX(true);
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: '',
        url: '',
      });
    }
  }, [fields]);

  function handleUploadDocument() {
    const fileName =
      documentAttachmentMethods.getValues(fieldName)[fileActionIndex].name;
    const documentName = fieldName;
    AttachmentService.removeDocument({
      fileName,
      documentName,
      formAnswerType,
    });

    if (openX) {
      setInputRemoveControl(fileActionIndex);
      uploadedFile('', '', fileActionIndex);
      setOpenX(false);
    } else {
      remove(fileActionIndex);
      setInputRemoveControl(fileActionIndex);
      setOpenMinus(false);
    }
  }

  const renderPlusButtonDisabled = (index: number) => {
    if (
      (fields.length - 1 === index.valueOf() &&
        !documentAttachmentMethods.getValues(fieldName)[index].name) ||
      index === maxFiles - 1 ||
      (fields.length - 1 === index.valueOf() && uploadError)
    ) {
      return (
        <button
          type="button"
          className="plus-button"
          title={index === maxFiles - 1 ? 'Limite máximo de 5 arquivos' : ''}
        >
          <Icon name="plusIconDisabled" />
        </button>
      );
    }
    return null;
  };

  const renderPlusButtonEnabled = (index: number) => {
    if (
      fields.length - 1 === index.valueOf() &&
      documentAttachmentMethods.getValues(fieldName)[index].name &&
      index !== maxFiles - 1 &&
      !uploadError
    ) {
      return (
        <button
          onClick={() => {
            append({
              name: '',
              url: '',
            });
          }}
          className="plus-button"
          type="button"
          disabled={loading}
        >
          <Icon name={!loading ? 'plusIcon' : 'plusIconDisabled'} />
        </button>
      );
    }
    return null;
  };

  const renderMinusButton = (index: number) => {
    if (!(fields.length - 1 === index.valueOf()) || fields.length > 1) {
      return (
        <button
          onClick={() => handleMinus(index)}
          className="minus-button"
          type="button"
          disabled={loading}
        >
          <Icon name={!loading ? 'minusIcon' : 'minusIconDisabled'} />
        </button>
      );
    }

    return null;
  };

  const shouldHideButtons = () =>
    isValidStatus(status) ||
    isPendingStatus(status) ||
    (isErrorStatus(status) && shouldDisable);

  const shouldDisplayCustomMessage = () =>
    isErrorStatus(status) && !shouldDisable && !hasModified;

  return (
    <Col className="documents d-flex flex-column-reverse">
      {fields.flatMap((document, index) => (
        <Row key={index.valueOf()}>
          <Col xs={10} sm={10} className="pe-0">
            <DocumentUpload
              documentPlaceholderText={`${documentPlaceholderText} - ${
                index + 1
              }`}
              formAnswerType={formAnswerType}
              uploadedFile={uploadedFile}
              removeFile={() => removeFile(index)}
              fieldName={fieldName}
              setUploadError={handleUploadError}
              control={control}
              defaultName={
                documentAttachmentMethods.getValues(fieldName)[index].name
              }
              inputRemoveControl={inputRemoveControl}
              setInputRemoveControl={setInputRemoveControl}
              index={index.valueOf()}
              isRequired={isRequired}
              loading={loading}
              setLoading={setLoading}
              defaultUrl={
                documentAttachmentMethods.getValues(fieldName)[index].url
              }
              documentAttachmentMethods={documentAttachmentMethods}
            />
            {shouldDisplayCustomMessage() && (
              <div className="upload-revision">{CUSTOM_ERROR_MESSAGE}</div>
            )}
          </Col>

          {shouldHideButtons() ? null : (
            <Col xs={2} sm={2} className="pe-0">
              <div className="buttons">
                {renderMinusButton(index)}
                {renderPlusButtonEnabled(index)}
                {renderPlusButtonDisabled(index)}
              </div>
            </Col>
          )}
        </Row>
      ))}

      <GenericModal
        modalType="confirmation"
        handleConfirm={handleUploadDocument}
        data={modalData}
        open={openX || openMinus}
        close={() => {
          openX ? setOpenX(false) : setOpenMinus(false);
        }}
      />
    </Col>
  );
}

export default MultipleDocument;

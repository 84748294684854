import React, { useContext, useEffect, useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import Checkbox from 'components/Checkbox';
import BankingAccount from 'components/Forms/Shared/Banking/BankingAccount';
import BankingNoAccount from 'components/Forms/Shared/Banking/BankingNoAccount';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
  isPendingStatus,
  isValidStatus,
} from 'components/Forms/Shared/FormUtils';
import AuthContext from 'contexts/auth';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import { updateAdmissionForm } from 'store/user/actions';
import FormAnswerType from 'types/enum/FormAnswerType';
import BankingAnswersResponse from 'types/models/BankingAnswersResponse';
import '../../FormCLT/Banking/styles.scss';
import { formStatus } from 'types/common/FormStatus';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Banking({
  handleNext,
  handlePrevious,
  formChanged,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const bankingDataMethods: UseFormReturn<FormModel.Banking> =
    useForm<FormModel.Banking>({
      mode: 'onBlur',
      defaultValues: {
        hasAccount: true,
      },
    });

  const hasAccount = bankingDataMethods.watch('hasAccount');
  useEffect(() => {
    bankingDataMethods.control.register('hasAccount');
    loadAnswersBankingInternship();
  }, []);

  async function loadAnswersBankingInternship(): Promise<void> {
    return FormAnswerService.getAllAnswers<BankingAnswersResponse>(
      FormAnswerType.BANKING_INTERNSHIP
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        bankingDataMethods.reset({
          hasAccount: data.hasAccount,
          account: data.account || '',
          digit: data.digit || '',
          agency: data.agency || '',
          bank: data.bank || '',
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.BANKING,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  const handleConfirm = async (dataRequest: FormModel.Banking) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });
    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.BANKING_INTERNSHIP,
    })
      .then(() => {
        setLoading(false);
        handleNext();
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };
  const verifyGoBack = () => {
    if (formChanged(bankingDataMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  function clearFields(check: boolean) {
    bankingDataMethods.setValue('bank', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('agency', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('account', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('digit', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('hasAccount', !check, {
      shouldValidate: true,
    });
  }

  function handleDisable() {
    return (
      checkSaveButtonDisabled(bankingDataMethods, admissionForm) || loading
    );
  }

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : bankingDataMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="banking-container">
      <Row className="header-form-info">
        <Col>
          <h1>Dados bancários</h1>
          <p>
            Informe seus dados bancários para recebimento do salário.{' '}
            <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>
      <input type="hidden" {...bankingDataMethods.register('hasAccount')} />
      {hasAccount && (
        <BankingAccount
          type={FormAnswerType.BANKING_INTERNSHIP}
          bankingDataMethods={bankingDataMethods}
        />
      )}

      <Row className="checkbox-account">
        <Col className="d-flex">
          <Checkbox
            checkedValue={clearFields}
            isChecked={!bankingDataMethods.watch('hasAccount')}
            disabled={
              isPendingStatus(admissionForm.status) ||
              isErrorStatus(admissionForm.status) ||
              isValidStatus(admissionForm.status)
            }
          />
          <span className="text-checkbox my-auto">Não tenho conta</span>
        </Col>
      </Row>

      {!hasAccount && <BankingNoAccount isFormsValid />}

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <ButtonLink onClick={verifyGoBack}>Voltar</ButtonLink>
          <ButtonLink
            disabled={handleDisable()}
            className="ms-3"
            onClick={handleButton()}
          >
            Salvar e continuar
          </ButtonLink>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />
      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : bankingDataMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Banking;

import React from 'react';
import './style.scss';
import { Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import Container from 'components/Container';

function Header() {
  return (
    <Row className="header-container">
      <Container className="d-flex align-items-center">
        <Col className="header-col">
          <Icon name="iterisLogoColor" />
          <hr className="header-separator" />
          <h4>Onboarding</h4>
          <div className="user d-none">
            <span>carlos.mola@iteris.com.br</span>
            <Icon name="accountCircle" />
          </div>
        </Col>
      </Container>
    </Row>
  );
}

export default Header;

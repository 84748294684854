const BenefitsErrorsType = {
  TOTAL_AMOUNT_BASKET_GREATER_THAN_ALLOWED_WITH_DEPENDANTS:
    'Olá! Não podemos prosseguir nesse formato porque você alocou mais benefícios do que sua cesta permite. Por favor, diminua os valores dos benefícios.',
  MINIMUM_BENEFIT_AMOUNT_LESS_THAN_UNION_MINIMUM_AMOUNT:
    'Olá! Não podemos prosseguir nesse formato porque você não alocou pelo menos R$400,00 da sua cesta de benefícios para vale alimentação ou vale refeição. Esse é um limite sindical. Por favor, retorne a tela anterior e revise os benefícios',
  TOTAL_AMOUNT_BASKET_GREATER_THAN_ALLOWED:
    'Olá! Não podemos prosseguir nesse formato porque você alocou mais benefícios do que sua cesta permite. Por favor, diminua os valores dos benefícios.',
  TOTAL_AMOUNT_BASKET_LESS_THAN_ALLOWED:
    'Olá! Você não utilizou todo o saldo da sua cesta de benefícios.',
  GENERIC_ERROR:
    'Desculpe, tivemos um problema. Por favor, procure o DP da Iteris para dar prosseguimento a sua admissão',
};

export default BenefitsErrorsType;

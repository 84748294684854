import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function RedirectLogin() {
  // implement login redirect with an return url
  const location = useLocation();

  return <Navigate to="/" state={{ from: location.pathname }} replace />;
}

export default RedirectLogin;

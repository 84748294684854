// https://github.com/react-cropper/react-cropper
// https://codesandbox.io/s/wonderful-pine-i7fs3?file=/src/Demo.tsx:884-900
import React, { useState } from 'react';
import './style.scss';
import axios from 'axios';
import 'react-image-crop/dist/ReactCrop.css';
import 'cropperjs/dist/cropper.min.css';
import { getToken } from 'configs/AuthService';
import { CROP_DIMENSIONS, UPLOAD_MAX_SIZE } from 'constants/imageUpload';
import Icon from 'components/Icon';
import { Cropper } from 'react-cropper';
import FormAnswerType from 'types/enum/FormAnswerType';
import AppModal from 'components/AppModal';
import { useTranslation } from 'react-i18next';

type ImageUploadProps = {
  setImageUploaded: any;
  close: () => void;
  open: boolean;
  profileType: FormAnswerType;
};

function ImageUpload({
  open,
  close,
  setImageUploaded,
  profileType,
}: ImageUploadProps) {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [image, setImage] = useState<string | null>(null);
  const [cropper, setCropper] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [clickDisable, setClickDisable] = useState(true);
  const { t } = useTranslation('admission');

  function hasMessage() {
    return message != '';
  }

  function validateImage(event: any) {
    setLoading(false);

    if (event.target.files.length > 1) {
      setMessage('Somente uma imagem pode ser selecionada.');
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(file);

    if (file.size > UPLOAD_MAX_SIZE) {
      setMessage(t('Profile Picture Size Error'));
      return;
    }

    if (
      !file.type.endsWith('png') &&
      !file.type.endsWith('jpg') &&
      !file.type.endsWith('jpeg')
    ) {
      setMessage(t('Profile Picture Format Error'));
      return;
    }

    setFiles(event.target.files);
    setClickDisable(false);
    setMessage('');
  }

  const handleConfirm = async () => {
    cropper.getCroppedCanvas().toBlob(
      (blob: any) => {
        setLoading(true);

        const token = getToken();
        const header = {
          Authorization: `Bearer ${token}`,
        };

        const data = new FormData();
        data.append(`file`, blob, files[0].name);
        data.append(`type`, profileType);

        axios
          .post(`${process.env.REACT_APP_URL_API}/attachment/photo`, data, {
            headers: header,
          })
          .then((res) => {
            setImageUploaded(res.data.url);
            setImage(null);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setMessage(
              'Ocorreu um erro inesperado, contate o responsável pela sua admissão.'
            );
          });
      },
      'image/jpeg',
      '-moz-parse-options:format=bmp;bpp=32'
    );
  };

  const cropperStyle = () => {
    if (window.innerWidth <= 600) return { height: 270, width: 270 };

    if (window.innerWidth >= 1900) return { height: 315, width: 315 };

    if (window.innerWidth >= 1300 && window.innerWidth <= 1400)
      return { height: 260, width: 260 };

    if (window.innerWidth <= 1600) return { height: 240, width: 240 };
    return { height: 270, width: 270 };
  };

  return (
    <AppModal
      title={t('Profile Picture Title')}
      open={open}
      className="modal-picture"
      close={() => {
        setImage(null);
        setLoading(false);
        setClickDisable(true);
        close();
      }}
      disableConfirm={clickDisable}
      confirm={() => {
        handleConfirm();
        setClickDisable(true);
      }}
    >
      <section className="image-upload-container">
        {image == null && (
          <label htmlFor="inputTag" className="dropzone">
            <input
              id="inputTag"
              type="file"
              name="file"
              onChange={(event) => validateImage(event)}
            />
            <div className="image-icon">
              <Icon name="imageIcon" />
            </div>
            <span className="dropzone-body">
              {t('Profile Picture Description')}
            </span>
          </label>
        )}

        {!loading && image != null && !hasMessage() && (
          <Cropper
            style={cropperStyle()}
            zoomTo={0}
            initialAspectRatio={1}
            src={image}
            className="mx-3"
            viewMode={3}
            cropBoxResizable={false}
            minCropBoxWidth={CROP_DIMENSIONS.width}
            minCropBoxHeight={CROP_DIMENSIONS.height}
            background={false}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        )}

        {loading && (
          <div className="load-container">
            <div className="load-image" />
          </div>
        )}

        {!loading && image != null && hasMessage() && (
          <div className="dropzone dropzone-error">
            <div className="image-icon">
              <Icon name="imageUploadError" />
            </div>
            <span className="dropzone-title">Erro</span>
            <span className="dropzone-body">{message}</span>
          </div>
        )}
      </section>
    </AppModal>
  );
}

export default ImageUpload;

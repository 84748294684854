import React from 'react';
import Icon from 'components/Icon';
import './style.scss';

type CheckboxProps = {
  checkedValue: (toggleValue: boolean) => void;
  isChecked: boolean;
  disabled?: boolean;
};

function Checkbox({
  checkedValue,
  isChecked = false,
  disabled = false,
}: CheckboxProps) {
  const handleCheckboxChange = () => {
    checkedValue(!isChecked);
  };

  return (
    <button
      type="button"
      onClick={handleCheckboxChange}
      className="checkbox-container"
      disabled={disabled}
    >
      <input
        aria-checked={isChecked}
        className="hidden-checkbox"
        type="checkbox"
        readOnly={disabled}
      />
      <div
        aria-checked={isChecked}
        style={{
          display: isChecked ? 'flex' : 'none',
        }}
        className="styled-checkbox"
      >
        {disabled ? (
          <Icon name="closeIconDisabled" />
        ) : (
          <Icon name="checkbox" />
        )}
      </div>
    </button>
  );
}

export default Checkbox;

/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import { format, parse } from 'date-fns';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import Datepicker from '../Datepicker';
import StateSelect from '../StateSelect/StateSelect';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';
import ValidationUtils from '../ValidationUtils';

type AutoFillFields = {
  workCard: {
    number: string;
    series: string;
    expeditionDate: string;
    UF: string;
  };
};

function DocumentWorkCard({
  documentsDataMethods,
}: {
  documentsDataMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;
  const maxLength = {
    number: 10,
    UF: 2,
  };

  const [autoFill, setAutoFill] = useState<AutoFillFields>({
    workCard: {
      number: '',
      series: '',
      expeditionDate: '',
      UF: '',
    },
  });

  const workCardNumberFormValidationProps = getFormValidationProps(
    'workCard.number',
    admissionForm,
    documentsDataMethods
  );

  const workCardSeriesFormValidationProps = getFormValidationProps(
    'workCard.series',
    admissionForm,
    documentsDataMethods
  );

  const workCardExpeditionDateFormValidationProps = getFormValidationProps(
    'workCard.expeditionDate',
    admissionForm,
    documentsDataMethods
  );

  const workCardUFFormValidationProps = getFormValidationProps(
    'workCard.UF',
    admissionForm,
    documentsDataMethods
  );

  return (
    <>
      <div className="sub-form-title">Carteira de Trabalho</div>
      <Row>
        <Col xs={12} sm={3}>
          <label
            id="workCardNumber"
            className="form-field"
            htmlFor="workCard.number"
          >
            <span className="label">Número*</span>
            <Controller
              control={documentsDataMethods.control}
              name="workCard.number"
              rules={{ required: true, maxLength: maxLength.number }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  onChange={(e) => onChange(masks.number(e.target.value))}
                  className={ValidationUtils.errorClass(
                    errors,
                    workCardNumberFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues('workCard.number') || ''
                  }
                  disabled={workCardNumberFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(
              errors,
              errors.workCard?.number
            )}
            {errors && errors.workCard?.number?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.number} caracteres.
              </span>
            )}
            {ValidationUtils.errorCustomValidator(
              workCardNumberFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
        <Col xs={12} sm={3}>
          <label
            id="workCardSeries"
            className="form-field"
            htmlFor="workCard.series"
          >
            <span className="label">Série*</span>
            <Controller
              control={documentsDataMethods.control}
              name="workCard.series"
              rules={{ required: true, maxLength: maxLength.number }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={ValidationUtils.errorClass(
                    errors,
                    workCardSeriesFormValidationProps.hasAnyError
                  )}
                  value={
                    documentsDataMethods.getValues('workCard.series') || ''
                  }
                  onChange={(e) => onChange(masks.number(e.target.value))}
                  disabled={workCardSeriesFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(
              errors,
              errors.workCard?.series
            )}
            {errors && errors.workCard?.series?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.number} caracteres.
              </span>
            )}
            {ValidationUtils.errorCustomValidator(
              workCardSeriesFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
        <Col xs={12} sm={3}>
          <label id="workCardUF" className="form-field" htmlFor="workCard.UF">
            <StateSelect
              controlerName="workCard.UF"
              titleText="UF*"
              errors={errors}
              formValidationProps={workCardUFFormValidationProps}
              control={documentsDataMethods.control}
              selected={documentsDataMethods.getValues('workCard.UF')}
              onChangeState={(selected: { sigla: string; label: string }) => {
                setAutoFill((s) => ({
                  ...s,
                  state: selected.sigla,
                }));
              }}
            />
          </label>
        </Col>
        <Col xs={12} sm={3}>
          <label
            id="workCardExpeditionDate"
            className="form-field"
            htmlFor="workCard.expeditionDate"
          >
            <span className="label">Data de Expedição*</span>
            <Controller
              control={documentsDataMethods.control}
              name="workCard.expeditionDate"
              rules={{ required: true, minLength: 10 }}
              render={({ field: { onChange, onBlur } }) => (
                <Datepicker
                  onBlur={onBlur}
                  className={
                    workCardExpeditionDateFormValidationProps.hasAnyError
                      ? 'field -input-error date'
                      : 'field -input date'
                  }
                  dateValue={
                    documentsDataMethods.getValues('workCard.expeditionDate') ||
                    ''
                  }
                  selected={
                    documentsDataMethods.watch('workCard.expeditionDate')
                      ? parse(
                          documentsDataMethods.watch('workCard.expeditionDate'),
                          'dd/MM/yyyy',
                          new Date()
                        )
                      : null
                  }
                  onSelect={(date: Date) => {
                    onChange(format(date!, 'dd/MM/yyyy'));
                    onBlur();
                  }}
                  disabled={
                    workCardExpeditionDateFormValidationProps.shouldDisable
                  }
                />
              )}
            />
            {ValidationUtils.errorViewValidator(
              errors,
              errors.workCard?.expeditionDate
            )}
            {errors &&
              errors.workCard?.expeditionDate?.type === 'minLength' && (
                <span className="error-text">
                  A data é inválida, por favor revise.
                </span>
              )}
            {ValidationUtils.errorCustomValidator(
              workCardExpeditionDateFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
    </>
  );
}

export default DocumentWorkCard;

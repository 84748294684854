/* eslint-disable no-shadow */
enum ValidationFormType {
  NONE = 'NONE',
  PROFILE = 'PROFILE',
  RESIDENTIAL = 'RESIDENTIAL',
  DOCUMENTS = 'DOCUMENTS',
  BANKING = 'BANKING',
  DEPENDANTS = 'DEPENDANTS',
  ATTACHMENTS = 'ATTACHMENTS',
}

export default ValidationFormType;

import AuthContext from 'contexts/auth';
import { useContext, useEffect, useState } from 'react';
import { login } from 'store/user/actions';
import Storage from 'services/storage';
import StorageTokens from 'constants/storage';

const useLoggedUser = () => {
  const [loggedUser, setLoggedUser] = useState({ email: '' });
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    try {
      const token = Storage.getToken();
      const user = Storage.getUser();

      if (token) {
        sessionStorage.setItem(StorageTokens.ACCESS_TOKEN, token);
      }

      if (user && state.loggedUser.email !== user) {
        dispatch(login({ name: '', email: user }));
      }
    } catch (err) {
      setLoggedUser({ email: '' });
    }
  }, [state.loggedUser, loggedUser, dispatch]);

  useEffect(() => {
    if (state.loggedUser.email && state.loggedUser.email !== loggedUser.email) {
      setLoggedUser({ email: state.loggedUser.email });
    }
  }, [state.loggedUser, loggedUser]);

  return loggedUser;
};

export default useLoggedUser;

import { useContext } from 'react';
import AuthContext from 'contexts/auth';
import {
  steps as saveSteps,
  stageAdmission as saveStageAdmission,
} from 'store/user/actions';
import { UserSteps } from 'types/common/UserSteps';
import { NavigationStep } from 'types/common/NavigationStep';
import StageAdmissionStatus from 'types/enum/StageAdmissionStatus';
import StageAdmissionType from 'types/enum/StageAdmissionType';

function StepsController() {
  const { state, dispatch } = useContext(AuthContext);

  function updateSteps(step: string, values: Record<string, string>) {
    return saveSteps({
      ...state.steps,
      [step]: {
        ...state.steps[step],
        ...values,
      },
    });
  }

  function updateStageAdmissionStatus(
    name: StageAdmissionType,
    stageAdmissionStatus: StageAdmissionStatus
  ) {
    const stagesAdmissionUpdated = [...state.stageAdmission];

    stagesAdmissionUpdated.map((element) => {
      const elementUpdated = element;
      if (elementUpdated.name === name) {
        elementUpdated.status = stageAdmissionStatus;
      }
      return elementUpdated;
    });

    return saveStageAdmission(stagesAdmissionUpdated);
  }

  function convertTypes(toBeConverted: UserSteps): Array<NavigationStep> {
    const keys = Object.keys(toBeConverted);
    const result = keys.map(
      (s): NavigationStep => ({
        name: s,
        status: toBeConverted[s].status,
        number: toBeConverted[s].number,
        description: toBeConverted[s].description,
        path: toBeConverted[s].path,
      })
    );

    return result;
  }

  return {
    steps: state.steps,
    dispatch,
    updateSteps,
    convertTypes,
    updateStageAdmissionStatus,
  };
}

export default StepsController;

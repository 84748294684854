import React, { useState } from 'react';
import StepsController from 'components/StateController/StepsController';
import StageOperations from 'components/StepsLoader/StageOperations';
import statusUpdater from 'hooks/statusUpdater';
import { useNavigate } from 'react-router-dom';
import StagesService from 'services/stagesService';
import NexStepFormAdmissionType from 'types/enum/NexStepFormAdmissionType';
import { formChanged } from 'components/Forms/Shared/FormUtils';
import FormAnswerService from 'services/formAnswerService';
import FormAnswerType from 'types/enum/FormAnswerType';
import ValidationFormType from 'types/enum/ValidationFormType';
import Banking from './Banking';
import Documents from './Documents';
import { infoForeignCLT } from './ForeignCLT';
import Profile from './Profile';
import Residential from './Residential';
import Dependants from './Dependants';
import Attachments from './Attachments';
import FormSteps from '../Shared/FormSteps';

function FormForeignCLT() {
  const [stepName, setStepName] = useState(ValidationFormType.PROFILE);
  const stepsController = StepsController();
  const navigate = useNavigate();
  const { completeCurrentStep } = statusUpdater();

  const renderForm = (form: ValidationFormType) => {
    const formMap: Partial<Record<ValidationFormType, JSX.Element>> = {
      PROFILE: <Profile handleNext={handleNext} />,
      RESIDENTIAL: (
        <Residential handlePrevious={handlePrevious} handleNext={handleNext} />
      ),
      DOCUMENTS: (
        <Documents
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          formChanged={formChanged}
        />
      ),
      BANKING: (
        <Banking
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          formChanged={formChanged}
        />
      ),
      DEPENDANTS: (
        <Dependants handlePrevious={handlePrevious} handleNext={handleNext} />
      ),
      ATTACHMENTS: (
        <Attachments
          handlePrevious={handlePrevious}
          handleConfirm={handleConfirm}
        />
      ),
    };

    return formMap[form] || formMap.PROFILE;
  };

  const handleNext = () => {
    const idx = infoForeignCLT.findIndex((s) => s.name === stepName);
    setStepName(infoForeignCLT[idx + 1].name);
  };

  const handlePrevious = () => {
    const idx = infoForeignCLT.findIndex((s) => s.name === stepName);
    setStepName(infoForeignCLT[idx - 1].name);
  };

  async function handleConfirm() {
    await FormAnswerService.updatePendingStatus(
      FormAnswerType.ATTACHMENT_FOREIGN_CLT
    );

    await StagesService.saveNextStepFormAdmission({
      nextStepFormAdmissionType: NexStepFormAdmissionType.FOREIGN_CLT,
    }).then(() => {
      const currentStage = 'admission';
      const nextActiveStage = StageOperations.getNextStage(
        currentStage,
        'FOREIGN_CLT'
      );
      const nextPath = stepsController.steps[nextActiveStage].path;

      completeCurrentStep(currentStage, nextActiveStage);

      navigate(nextPath);
    });
  }

  return (
    <div>
      <FormSteps
        steps={infoForeignCLT}
        active={stepName as ValidationFormType}
      />

      <main className="admission-form">{renderForm(stepName)}</main>
    </div>
  );
}

export default FormForeignCLT;

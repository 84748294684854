/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import './styles.scss';
import { UseFormReturn } from 'react-hook-form';
import ImageUpload from 'components/Dropzone/ImageUpload';
import ImageCard from 'components/ImageCard';
import FormAnswerType from 'types/enum/FormAnswerType';
import AuthContext from 'contexts/auth';
import { getFormValidationProps } from '../../FormUtils';

function ProfilePicture({
  profileMethods,
  formAnswerType,
}: {
  profileMethods: UseFormReturn<any>;
  formAnswerType: FormAnswerType;
}) {
  const {
    state: { admissionForm },
  } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [urlImage, setUrlImage] = useState('');

  const { shouldDisable, hasCustomError } = getFormValidationProps(
    'photo',
    admissionForm,
    profileMethods
  );

  function uploadImage(urlImageRequest: string) {
    setUrlImage(urlImageRequest);
    setOpen(false);
    profileMethods.setValue('photo', urlImageRequest, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  return (
    <div className={`picture-container ${hasCustomError ? 'disabled' : ''}`}>
      <input
        type="hidden"
        {...profileMethods.register('photo', {
          required: true,
        })}
      />
      <ImageCard
        openModal={() => {
          setOpen(true);
        }}
        urlImage={profileMethods.getValues('photo') || urlImage}
        profileMethods={profileMethods}
      />
      {!shouldDisable && (
        <ImageUpload
          open={open}
          close={() => {
            setOpen(false);
          }}
          setImageUploaded={uploadImage}
          profileType={formAnswerType}
        />
      )}
    </div>
  );
}

export default ProfilePicture;

/* eslint-disable import/prefer-default-export */
export const EmailTexts = {
  'pt-BR': {
    'Email title': 'Bem-vindo (a)!',
    'Email description': 'Estamos muito animados com a sua chegada!',
    'Email subdescription':
      'Insira seu e-mail no campo abaixo para receber o código de acesso',
    'Email button text': 'Enviar código',
    'Email placeholder': 'Digite seu e-mail',
    'Privacy warning title': 'Aviso de privacidade!',
    'Privacy warning description':
      ' A Iteris está comprometida em proteger a sua privacidade, assim, todos os dados pessoais coletados serão tratados para propósitos legítimos e finalidades específicas. Para entender como a Iteris trata os seus dados, acesse a nossa Política de Privacidade.',
    Continue: 'Continuar',
    'Privacy policy': 'Abrir Política de Privacidade',
    'Privacy policy short': 'Política de Privacidade',
  },
};

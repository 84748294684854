import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, UseFormReturn } from 'react-hook-form';
import AuthContext from 'contexts/auth';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileRaceColor({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const { t } = useTranslation('raceColor');
  const {
    state: { admissionForm, contractType },
  } = React.useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'race',
    admissionForm,
    profileMethods
  );

  const color = [
    { value: t('White Option'), label: t('White Option') },
    { value: t('Black Option'), label: t('Black Option') },
    { value: t('Yellow Option'), label: t('Yellow Option') },
    { value: t('Brown Option'), label: t('Brown Option') },
    { value: t('Indigenous Option'), label: t('Indigenous Option') },
  ];

  return (
    <OverlayTrigger
      placement="top"
      show={contractType !== 'PJ' && !shouldDisable && open}
      overlay={
        <Tooltip
          placement="top"
          className="race-tooltip"
          id="raceColor-tooltip"
        >
          {t('Tooltip Info')}
        </Tooltip>
      }
    >
      <label
        data-tooltip-id="raceColor-tooltip"
        id="raceColor"
        className="form-field"
        htmlFor="race"
      >
        <span className="label">{t('Label')}</span>
        <Controller
          name="race"
          control={profileMethods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name, ref, onBlur } }) => (
            <Select
              onMenuOpen={() => setOpen(true)}
              onMenuClose={() => setOpen(false)}
              placeholder=""
              styles={customStyles(hasAnyError, shouldDisable)}
              options={color}
              name={name}
              value={color.find((r) => r.value === value)}
              onChange={(selectedOption: any) => {
                onChange(selectedOption.value);
              }}
              onBlur={onBlur}
              isSearchable={!shouldDisable}
              menuIsOpen={shouldDisable ? false : undefined}
            />
          )}
        />
        {errors && errors.race?.type === 'required' && (
          <span className="error-text">{t('Error')}</span>
        )}
        {hasCustomError && (
          <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
        )}
      </label>
    </OverlayTrigger>
  );
}

export default ProfileRaceColor;

import Button from 'components/Button';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import DocumentCPF from 'components/Forms/Shared/Documents/DocumentCPF';
import DocumentPIS from 'components/Forms/Shared/Documents/DocumentPIS';
import DocumentRegistrationNumber from 'components/Forms/Shared/Documents/DocumentRegistrationNumber';
import DocumentRegistrationVote from 'components/Forms/Shared/Documents/DocumentRegistrationVote';
import DocumentReservist from 'components/Forms/Shared/Documents/DocumentReservist';
import DocumentWorkCard from 'components/Forms/Shared/Documents/DocumentWorkCard';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
} from 'components/Forms/Shared/FormUtils';
import GenericModal from 'components/Forms/Shared/GenericModal';
import AuthContext from 'contexts/auth';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import { updateAdmissionForm } from 'store/user/actions';
import { formStatus } from 'types/common/FormStatus';
import FormAnswerType from 'types/enum/FormAnswerType';
import ValidationFormType from 'types/enum/ValidationFormType';
import DocumentsCLTResponse from 'types/models/DocumentsCLTResponse';
import * as FormModel from '../models';
import './styles.scss';

function Documents({
  handleNext,
  handlePrevious,
  formChanged,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const documentsDataMethods: UseFormReturn<FormModel.Documents> =
    useForm<FormModel.Documents>({
      mode: 'onBlur',
      defaultValues: {
        workCard: { number: '', series: '', UF: '', expeditionDate: '' },
        RG: { number: '', expeditionDate: '', emmiter: '' },
        reservist: { number: '', category: '' },
        voteRegistration: { number: '', area: '', section: '' },
        CPF: '',
        PIS: '',
      },
    });

  async function loadAnswersDocumentsCLT(): Promise<void> {
    return FormAnswerService.getAllAnswers<DocumentsCLTResponse>(
      'DOCUMENTS_CLT'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        documentsDataMethods.reset({
          CPF: data.CPF || '',
          PIS: data.PIS || '',
          RG: {
            number: data.RG?.number || '',
            emmiter: data.RG?.emmiter || '',
            expeditionDate: data.RG?.expeditionDate || '',
          },
          reservist: {
            number: data.reservist?.number || '',
            category: data.reservist?.category || '',
          },
          voteRegistration: {
            number: data.voteRegistration?.number || '',
            area: data.voteRegistration?.area || '',
            section: data.voteRegistration?.section || '',
          },
          workCard: {
            number: data.workCard?.number || '',
            series: data.workCard?.series || '',
            expeditionDate: data.workCard?.expeditionDate || '',
            UF: data.workCard?.UF || '',
          },
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.DOCUMENTS,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  const handleConfirm = async (dataRequest: FormModel.Documents) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });

    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.DOCUMENTS_CLT,
    })
      .then(() => handleNext())
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  useEffect(() => {
    loadAnswersDocumentsCLT();
  }, []);

  function handleDisable() {
    return (
      checkSaveButtonDisabled(documentsDataMethods, admissionForm) || loading
    );
  }

  const verifyGoBack = () => {
    if (formChanged(documentsDataMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };
  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title:
              'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
            description: '',
            modalTitle: 'Atenção!',
          });
        }
      : documentsDataMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="documents-container">
      <Row className="header-form-info">
        <Col>
          <h1>Preencher documentação</h1>
          <p>
            Informe alguns dados presentes nos seus documentos pessoais.
            Tenha-os em mãos para este formulário. <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>

      <DocumentWorkCard documentsDataMethods={documentsDataMethods} />
      <DocumentRegistrationNumber
        documentsDataMethods={documentsDataMethods}
        validate
      />
      <DocumentReservist
        documentsDataMethods={documentsDataMethods}
        validate={false}
      />
      <DocumentRegistrationVote documentsDataMethods={documentsDataMethods} />
      <Row>
        <Col xs={12} sm={3}>
          <DocumentCPF documentsDataMethods={documentsDataMethods} />
        </Col>
        <Col xs={12} sm={3}>
          <DocumentPIS documentsDataMethods={documentsDataMethods} />
        </Col>
      </Row>

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <Button onClick={verifyGoBack}>Voltar</Button>
          <Button
            disabled={handleDisable()}
            className="ms-3"
            onClick={handleButton()}
          >
            Salvar e continuar
          </Button>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />
      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : documentsDataMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Documents;

/* eslint-disable import/prefer-default-export */
export const customStylesForBenefits = (
  hasError: any,
  wrap: boolean = false,
  width: string = '100%'
) => ({
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#7C7C80',
    padding: 10,
    backgroundColor: state.isFocused ? 'rgba(18, 18, 18, 0.08)' : 'white',
    '&:active': {
      backgroundColor: '#F1F1F6',
      fontWeight: 'bolder',
    },
  }),
  control: () => ({
    width: '200px',
    height: '40px',
    border: hasError ? '1px solid #e25c5a' : '1px solid #e5e5e5',
    borderRadius: '8px',
    display: 'flex',
    paddingRight: 10,
    justifyContent: 'space-between',
    caretColor: 'transparent',

    '&:hover': {
      border: '1px solid #f58220',
    },
  }),

  singleValue: (provided: any, state: any) => ({
    color: '#7C7C80',
    whiteSpace: provided.whiteSpace,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#f58220',
    paddingTop: 10,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    '&:hover': {
      color: '#f58220',
    },
  }),

  indicatorSeparator: (provided: any, state: any) => ({
    background: 'none',
  }),

  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '8px',
    width: '65%',

    '@media (min-width: 1200px)': {
      width: '90%',
    },

    '@media (min-width: 1920px)': {
      width: '62%',
    },
  }),

  valueContainer: (provided: any, state: any) => ({
    display: 'flex',
    justifyContainer: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  }),
});

import React from 'react';
import './style.scss';
import { Button, Modal } from 'react-bootstrap';
import Conditional from 'components/Conditional/Conditional';

type ContainerProps = {
  children: React.ReactNode;
  title?: string;
  className?: string;
  close: () => void;
  confirm?: () => void;
  open?: boolean;
  disableConfirm?: boolean;
  showFooter?: boolean;
  buttonText?: string;
  showHeader?: boolean;
};

function AppModal({
  title,
  children,
  open,
  className,
  close,
  confirm,
  disableConfirm,
  showFooter = true,
  showHeader = true,
  buttonText = 'Confirmar',
}: ContainerProps) {
  return (
    <Conditional if={open}>
      <Modal show centered dialogClassName={className}>
        {showHeader && (
          <Modal.Header>
            <Modal.Title className="text-start">{title}</Modal.Title>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={close}
            />
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
        {showFooter ? (
          <Modal.Footer>
            <Button
              onClick={confirm}
              disabled={disableConfirm}
              className="modal-btn btn-finish"
            >
              {buttonText}
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    </Conditional>
  );
}

export default AppModal;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { HomeTexts } from 'constants/localization/HomeTexts';
import { EmailTexts } from 'constants/localization/Login';
import { AdmissionFormTexts } from 'constants/localization/AdmissionFormTexts';
import { BankingTexts } from 'constants/localization/BankingTexts';
import { DependantsTexts } from 'constants/localization/DependantsTexts';
import { BenefitsTexts } from 'constants/localization/BenefitsTexts';
import { AttachmentsTexts } from 'constants/localization/AttachmentsTexts';
import { PresentationTexts } from 'constants/localization/PresentationTexts';
import { ExamTexts } from 'constants/localization/ExamTexts';
import { fieldTexts } from 'constants/localization/ValidationTexts';
import { RaceColorTexts } from 'constants/localization/RaceColorTexts';

export default function configLocalization() {
  i18n.use(initReactI18next).init({
    resources: {
      'pt-BR': {
        home: HomeTexts['pt-BR'],
        loginEmail: EmailTexts['pt-BR'],
        admission: AdmissionFormTexts['pt-BR'],
        banking: BankingTexts['pt-BR'],
        dependants: DependantsTexts['pt-BR'],
        benefits: BenefitsTexts['pt-BR'],
        attachments: AttachmentsTexts['pt-BR'],
        health: ExamTexts['pt-BR'],
        profile: PresentationTexts['pt-BR'],
        fields: fieldTexts['pt-BR'],
        raceColor: RaceColorTexts['pt-BR'],
      },
    },
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',

    defaultNS: 'home',

    interpolation: {
      escapeValue: false,
    },
  });
}

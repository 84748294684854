/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import documentValidator from 'utils/documentValidator';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function DocumentCPF({
  documentsDataMethods,
}: {
  documentsDataMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;
  const maxLength = 15;

  const { hasAnyError, hasCustomError, shouldDisable } = getFormValidationProps(
    'CPF',
    admissionForm,
    documentsDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorViewValidator(errorValidation: FieldError | undefined) {
    return errors && errorValidation?.type === 'required' ? (
      <span className="error-text">Esse campo é obrigatório.</span>
    ) : null;
  }

  return (
    <label id="CPF" className="form-field" htmlFor="CPF">
      <span className="label">CPF*</span>
      <Controller
        control={documentsDataMethods.control}
        name="CPF"
        rules={{
          maxLength,
          required: true,
          minLength: 14,
          validate: documentValidator.validCPF,
        }}
        render={({ field: { onChange, onBlur } }) => (
          <input
            type="text"
            className={errorClass(hasAnyError)}
            placeholder=""
            onChange={(e) => onChange(masks.cpf(e.target.value))}
            value={documentsDataMethods.getValues('CPF')}
            onBlur={onBlur}
            disabled={shouldDisable}
          />
        )}
      />
      {errorViewValidator(errors.CPF)}
      {errors &&
        (errors.CPF?.type === 'minLength' ||
          errors.CPF?.type === 'validate') && (
          <span className="error-text">
            O CPF é inválido, por favor revise.
          </span>
        )}
      {errors && errors.CPF?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default DocumentCPF;

import React from 'react';
import './styles/base/base.scss';
import { BrowserRouter } from 'react-router-dom';
import configLocalization from 'configs/localization';
import { AuthProvider } from './contexts/auth';
import Base from './components/Base';
import store from './store/store';

function App() {
  configLocalization();
  return (
    <AuthProvider store={store}>
      <BrowserRouter>
        <Base />
      </BrowserRouter>
    </AuthProvider>
  );
}
export default App;

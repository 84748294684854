import api from 'configs/api';
import NexStepFormAdmissionType from 'types/enum/NexStepFormAdmissionType';
import StageAdmissionType from 'types/enum/StageAdmissionType';

async function getStages() {
  return api.get('/stage-admission');
}

async function saveNextStepFormAdmission({
  nextStepFormAdmissionType,
}: {
  nextStepFormAdmissionType: NexStepFormAdmissionType;
}) {
  const request = {
    nextStepFormAdmissionType,
  };
  return api.post('/stage-admission/next-step-form-admission', request);
}

async function saveStageAdmission({
  stageAdmissionType,
}: {
  stageAdmissionType: StageAdmissionType;
}) {
  const request = {
    stageAdmissionType,
  };
  return api.post('/stage-admission', request);
}
const StagesService = {
  getStages,
  saveNextStepFormAdmission,
  saveStageAdmission,
};

export default StagesService;

import React, { useMemo } from 'react';
import './styles.scss';
import Button from 'components/Button';
import Benefit from 'types/models/Benefit';
import masks from 'utils/MasksUtils';
import { Col, Row } from 'react-bootstrap';
import { SelectBenefit } from '../BenefitsContainer';

export type BenefitCardProps = {
  benefit: Benefit;
  value: number;
  selectedOption: SelectBenefit;
  dependants: number;
  onClick?: VoidFunction;
  locked: boolean;
};

function BenefitCard({
  benefit,
  value,
  selectedOption,
  dependants,
  onClick = () => {},
  locked = false,
}: BenefitCardProps) {
  const benefitCost = useMemo(() => {
    if (benefit.flagBenefitValue) {
      return value;
    }

    const selectedBenefit = benefit.options.find(
      (b) => b.idBenefitOption === selectedOption.idBenefitOption
    );

    const cost =
      (selectedBenefit?.valueBeneficiary || 0) +
      dependants * (selectedBenefit?.valueDependant || 0);

    return cost * 100;
  }, [
    benefit.flagBenefitValue,
    benefit.options,
    dependants,
    selectedOption.idBenefitOption,
    value,
  ]);

  return (
    <Col
      xs={5}
      sm={3}
      aria-hidden="true"
      className="benefit-card"
      onClick={onClick}
    >
      <div>
        <Row className="card-image">
          <img src={benefit.urlImage} />
        </Row>
        <Row className="benefit-name">
          <h3>{benefit.name}</h3>
        </Row>
        {benefit.flagBenefitOption && (
          <Row className="row-description">
            <div className="benefit-value">
              {selectedOption.label} <span className="dot"> &middot; </span>
              {masks.currency(benefitCost)}
            </div>
            <span className="dependants">Dependentes: {dependants}</span>
          </Row>
        )}

        {benefit.flagBenefitValue && (
          <Row className="row-description">
            <span className="benefit-value">{masks.currency(value)}</span>
          </Row>
        )}
      </div>

      {!locked && (
        <Row className="justify-content-center">
          <Button className="button-edit" outline>
            Editar
          </Button>
        </Row>
      )}
    </Col>
  );
}

export default BenefitCard;

/* eslint-disable no-continue */
import React from 'react';

const ImageUtils = {
  isValidFormat(filename: string, validExtensions: string[]) {
    let isValid = false;
    for (let i = 0; i < validExtensions.length; i += 1) {
      if (!filename.toLocaleLowerCase().endsWith(validExtensions[i])) continue;
      isValid = true;
      break;
    }
    return isValid;
  },
};

export default ImageUtils;

import React from 'react';
import FormLogin from 'components/Forms/FormLogin';
import './style.scss';
import { useTranslation } from 'react-i18next';

type EmailCardProps = {
  handleEmail: (email: string) => {};
  emailError: boolean;
};

function EmailCard({ handleEmail, emailError }: EmailCardProps) {
  const { t } = useTranslation('loginEmail');

  async function handleLogin(usr: { email: string }) {
    handleEmail(usr.email);
  }

  return (
    <div className="email-container">
      <div className="text">
        <h1 className="welcome">{t('Email title')}</h1>
        <h2 className="description">{t('Email description')}</h2>
        <h3 className="secondary-description">{t('Email subdescription')}</h3>
      </div>
      <div className="login">
        <FormLogin login={handleLogin} loginError={emailError} />
      </div>
    </div>
  );
}
export default EmailCard;

import React, { useState } from 'react';
import ButtonLink from 'components/Button/ButtonLink';
import { LoginProps } from 'types/common/formAction';
import { useTranslation } from 'react-i18next';
import './style.scss';

function FormLogin({ login, loginError }: LoginProps) {
  const { t } = useTranslation('loginEmail');
  const [email, setEmail] = useState('');

  function handleLogin() {
    login({ email });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleLogin();
  }

  return (
    <section>
      <form className="form-login" onSubmit={handleSubmit}>
        <label htmlFor="email" className="label">
          <input
            id="email-input"
            autoComplete="off"
            type="email"
            name="email"
            placeholder={t('Email placeholder')}
            className={`input-email${loginError ? ' -error' : ''}`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {loginError ? (
            <span className="form-error">
              *E-mail inválido, insira um novo e-mail e tente novamente.
            </span>
          ) : null}
        </label>

        <div className="d-flex justify-content-center">
          <ButtonLink
            componentType="button"
            id="form-login-page"
            onClick={handleLogin}
          >
            {t('Email button text')}
          </ButtonLink>
        </div>
      </form>
    </section>
  );
}

export default FormLogin;

import React from 'react';
import AuthContext from 'contexts/auth';
import { format, parse } from 'date-fns';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { customStylesForDocs } from 'styles/select/customStylesForDocs';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';
import Datepicker from '../Datepicker';

const maxLength = {
  number: 15,
  digit: 2,
};

const type = [
  { value: 'Permanente', label: 'Permanente' },
  { value: 'Provisório', label: 'Provisório' },
  { value: 'Temporário', label: 'Temporário' },
  { value: 'Fronteiriço', label: 'Fronteiriço' },
];

const marriedBrazilian = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não', label: 'Não' },
];

const brazilianSon = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não', label: 'Não' },
];

const foreignStatus = [
  { value: 'Asilado ', label: 'Asilado ' },
  {
    value: 'Beneficiado pelo acordo entre países do Mercosul',
    label: 'Beneficiado pelo acordo entre países do Mercosul',
  },
  {
    value:
      'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brazil e República Portuguesa',
    label:
      'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brazil e República Portuguesa',
  },
  {
    value: 'Com residência provisória e anistiado, em situação irregular',
    label: 'Com residência provisória e anistiado, em situação irregular',
  },
  {
    value: 'Deficiente físico e com mais de 51 anos',
    label: 'Deficiente físico e com mais de 51 anos',
  },
  {
    value:
      'Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício de atividade remunerada no Brasil',
    label:
      'Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício de atividade remunerada no Brasil',
  },
  {
    value: 'Permanência no Brasil em razão de filhos ou cônjuge brasileiros',
    label: 'Permanência no Brasil em razão de filhos ou cônjuge brasileiros',
  },
  {
    value: 'Refugiado',
    label: 'Refugiado',
  },
  {
    value: 'Residente fora do Brasil',
    label: 'Residente fora do Brasil',
  },
  {
    value: 'Solicitante de refúgio',
    label: 'Solicitante de refúgio',
  },
  {
    value: 'Visto permanente',
    label: 'Visto permanente',
  },
  {
    value: 'Visto temporário',
    label: 'Visto temporário',
  },
];

function DocumentRNE({
  documentsDataMethods,
}: {
  documentsDataMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = documentsDataMethods.formState;

  const rneNumberFormValidationProps = getFormValidationProps(
    'RNE.number',
    admissionForm,
    documentsDataMethods
  );

  const rneDigitFormValidationProps = getFormValidationProps(
    'RNE.digit',
    admissionForm,
    documentsDataMethods
  );

  const rneEmitterFormValidationProps = getFormValidationProps(
    'RNE.emitter',
    admissionForm,
    documentsDataMethods
  );

  const rneExpirationDateFormValidationProps = getFormValidationProps(
    'RNE.expirationDate',
    admissionForm,
    documentsDataMethods
  );

  const rneEntryDateFormValidationProps = getFormValidationProps(
    'RNE.entryDate',
    admissionForm,
    documentsDataMethods
  );

  const rneMarriedBrazilianFormValidationProps = getFormValidationProps(
    'RNE.marriedBrazilian',
    admissionForm,
    documentsDataMethods
  );

  const rneBrazilianSonFormValidationProps = getFormValidationProps(
    'RNE.brazilianSon',
    admissionForm,
    documentsDataMethods
  );

  const rneTypeFormValidationProps = getFormValidationProps(
    'RNE.type',
    admissionForm,
    documentsDataMethods
  );

  const foreignStatusFormValidationProps = getFormValidationProps(
    'foreignStatus',
    admissionForm,
    documentsDataMethods
  );

  function errorClass(errorValidation: FieldError | undefined) {
    return errors && errorValidation ? 'field -input-error' : 'field -input';
  }

  function errorViewValidator(errorValidation: FieldError | undefined) {
    return errors && errorValidation?.type === 'required' ? (
      <span className="error-text">Esse campo é obrigatório.</span>
    ) : null;
  }

  function errorCustomValidator(hasCustomError: boolean) {
    return (
      hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )
    );
  }

  return (
    <>
      <div className="sub-form-title">RNE</div>
      <Row>
        <Col xs={12} sm={3}>
          <label id="RNE.number" className="form-field" htmlFor="RNE.number">
            <span className="label">Número*</span>
            <Controller
              control={documentsDataMethods.control}
              name="RNE.number"
              rules={{ required: true, maxLength: maxLength.number }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={errorClass(
                    rneNumberFormValidationProps.hasAnyError
                  )}
                  onChange={(e) => onChange(masks.RNENumber(e.target.value))}
                  value={documentsDataMethods.getValues('RNE.number') || ''}
                  disabled={rneNumberFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.RNE?.number?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.number} caracteres.
              </span>
            )}
            {errorViewValidator(errors.RNE?.number)}
            {errorCustomValidator(rneNumberFormValidationProps.hasCustomError)}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="RNE.digit"
            className="form-field margin-right "
            htmlFor="RNE.digit"
          >
            <span className="label">Dígito*</span>
            <Controller
              control={documentsDataMethods.control}
              name="RNE.digit"
              rules={{ required: true, maxLength: maxLength.digit }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={errorClass(
                    rneDigitFormValidationProps.hasAnyError
                  )}
                  onChange={(e) =>
                    onChange(masks.numberAndLetters(e.target.value))
                  }
                  value={documentsDataMethods.getValues('RNE.digit') || ''}
                  disabled={rneDigitFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.RNE?.digit?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.digit} caracteres.
              </span>
            )}
            {errorViewValidator(errors.RNE?.digit)}
            {errorCustomValidator(rneDigitFormValidationProps.hasCustomError)}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label id="RNE.emmiter" className="form-field" htmlFor="RNE.emmiter">
            <span className="label">Órgão Emissor*</span>
            <Controller
              control={documentsDataMethods.control}
              name="RNE.emmiter"
              rules={{ required: true, maxLength: maxLength.number }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  className={errorClass(
                    rneEmitterFormValidationProps.hasAnyError
                  )}
                  onChange={(e) => onChange(e.target.value)}
                  value={documentsDataMethods.getValues('RNE.emmiter') || ''}
                  disabled={rneEmitterFormValidationProps.shouldDisable}
                />
              )}
            />
            {errors && errors.RNE?.emmiter?.type === 'maxLength' && (
              <span className="error-text">
                Precisa ter até {maxLength.number} caracteres.
              </span>
            )}
            {errorCustomValidator(rneEmitterFormValidationProps.hasCustomError)}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="RNE.expirationDate"
            className="form-field"
            htmlFor="RNE.expirationDate"
          >
            <span className="label">Data de Validade*</span>
            <Controller
              control={documentsDataMethods.control}
              name="RNE.expirationDate"
              render={({ field: { onChange, onBlur, value } }) => {
                const tomorrow = new Date();
                tomorrow.setDate(new Date().getDate() + 1);

                return (
                  <Datepicker
                    startYear={new Date().getFullYear()}
                    endYear={2101}
                    minDate={tomorrow}
                    maxDate={new Date('2101-01-01')}
                    onBlur={onBlur}
                    className={
                      rneExpirationDateFormValidationProps.hasAnyError
                        ? 'field -input-error date'
                        : 'field -input date'
                    }
                    dateValue={
                      documentsDataMethods.getValues('RNE.expirationDate') || ''
                    }
                    selected={
                      documentsDataMethods.watch('RNE.expirationDate')
                        ? parse(
                            documentsDataMethods.watch('RNE.expirationDate'),
                            'dd/MM/yyyy',
                            new Date()
                          )
                        : null
                    }
                    onSelect={(date: Date) => {
                      onChange(format(date!, 'dd/MM/yyyy'));
                      onBlur();
                    }}
                    disabled={
                      rneExpirationDateFormValidationProps.shouldDisable
                    }
                  />
                );
              }}
            />
            {errorViewValidator(errors.RNE?.expirationDate)}
            {errors && errors.RNE?.expirationDate?.type === 'minLength' && (
              <span className="error-text">
                A data é inválida, por favor revise.
              </span>
            )}
            {errorCustomValidator(
              rneExpirationDateFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={3}>
          <label
            id="RNE.entryDate"
            className="form-field"
            htmlFor="RNE.entryDate"
          >
            <span className="label">Data de Entrada no País*</span>
            <Controller
              control={documentsDataMethods.control}
              name="RNE.entryDate"
              render={({ field: { onChange, onBlur } }) => (
                <Datepicker
                  onBlur={onBlur}
                  className={
                    rneEntryDateFormValidationProps.hasAnyError
                      ? 'field -input-error date'
                      : 'field -input date'
                  }
                  dateValue={
                    documentsDataMethods.getValues('RNE.entryDate') || ''
                  }
                  selected={
                    documentsDataMethods.watch('RNE.entryDate')
                      ? parse(
                          documentsDataMethods.watch('RNE.entryDate'),
                          'dd/MM/yyyy',
                          new Date()
                        )
                      : null
                  }
                  onSelect={(date: Date) => {
                    onChange(format(date!, 'dd/MM/yyyy'));
                    onBlur();
                  }}
                  disabled={rneEntryDateFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.RNE?.entryDate)}
            {errors && errors.RNE?.entryDate?.type === 'minLength' && (
              <span className="error-text">
                A data é inválida, por favor revise.
              </span>
            )}
            {errorCustomValidator(
              rneEntryDateFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="RNE.marriedBrazilian"
            className="form-field"
            htmlFor="RNE.marriedBrazilian"
          >
            <span className="label">Casado com Brasileiro*</span>
            <Controller
              name="RNE.marriedBrazilian"
              control={documentsDataMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStylesForDocs(
                    rneMarriedBrazilianFormValidationProps.hasAnyError
                  )}
                  options={marriedBrazilian}
                  name={name}
                  value={marriedBrazilian.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isDisabled={
                    rneMarriedBrazilianFormValidationProps.shouldDisable
                  }
                />
              )}
            />
            {errorViewValidator(errors.RNE?.marriedBrazilian)}
            {errorCustomValidator(
              rneMarriedBrazilianFormValidationProps.hasCustomError
            )}
          </label>
        </Col>

        <Col xs={12} sm={3}>
          <label
            id="RNE.brazilianSon"
            className="form-field"
            htmlFor="RNE.brazilianSon"
          >
            <span className="label">Filhos Brasileiros*</span>
            <Controller
              name="RNE.brazilianSon"
              control={documentsDataMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStylesForDocs(
                    rneBrazilianSonFormValidationProps.hasAnyError
                  )}
                  options={brazilianSon}
                  name={name}
                  value={brazilianSon.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isDisabled={rneBrazilianSonFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.RNE?.brazilianSon)}
            {errorCustomValidator(
              rneBrazilianSonFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={3}>
          <label id="RNE.type" className="form-field" htmlFor="RNE.type">
            <span className="label">Tipo de visto*</span>
            <Controller
              name="RNE.type"
              control={documentsDataMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStylesForDocs(
                    rneTypeFormValidationProps.hasAnyError
                  )}
                  options={type}
                  name={name}
                  value={type.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isDisabled={rneTypeFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.RNE?.type)}
            {errorCustomValidator(rneTypeFormValidationProps.hasCustomError)}
          </label>
        </Col>

        <Col xs={12} sm={9}>
          <label
            id="foreignStatus"
            className="form-field"
            htmlFor="foreignStatus"
          >
            <span className="label">Condição do Estrangeiro no Brasil*</span>
            <Controller
              name="foreignStatus"
              control={documentsDataMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder=""
                  styles={customStylesForDocs(
                    foreignStatusFormValidationProps.hasAnyError,
                    true
                  )}
                  options={foreignStatus}
                  name={name}
                  value={foreignStatus.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isDisabled={foreignStatusFormValidationProps.shouldDisable}
                />
              )}
            />
            {errorViewValidator(errors.foreignStatus)}
            {errorCustomValidator(
              foreignStatusFormValidationProps.hasCustomError
            )}
          </label>
        </Col>
      </Row>
    </>
  );
}

export default DocumentRNE;

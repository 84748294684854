import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import FormAnswerType from 'types/enum/FormAnswerType';
import ProfileName from 'components/Forms/Shared/Profile/ProfileName';
import ProfileEmail from 'components/Forms/Shared/Profile/ProfileEmail';
import ProfilePhoneNumber from 'components/Forms/Shared/Profile/ProfilePhoneNumber';
import ProfileMobileNumber from 'components/Forms/Shared/Profile/ProfileMobileNumber';
import ProfileDateBirth from 'components/Forms/Shared/Profile/ProfileDateBirth';
import ProfilePicture from 'components/Forms/Shared/Profile/ProfilePicture';
import ProfilePreferredName from 'components/Forms/Shared/Profile/ProfilePreferredName';

function ProfileFirstRow({
  profileMethods,
  formAnswerType,
}: {
  profileMethods: UseFormReturn<any>;
  formAnswerType: FormAnswerType;
}) {
  return (
    <Row className="first-row">
      <Col xs={12} sm={3}>
        <ProfilePicture
          profileMethods={profileMethods}
          formAnswerType={formAnswerType}
        />
      </Col>

      <Col xs={12} sm={6}>
        <ProfileName profileMethods={profileMethods} />
        <ProfilePreferredName profileMethods={profileMethods} />
        <ProfileEmail profileMethods={profileMethods} />
      </Col>

      <Col xs={12} sm={3}>
        <ProfilePhoneNumber profileMethods={profileMethods} />
        <ProfileMobileNumber profileMethods={profileMethods} />
        <ProfileDateBirth profileMethods={profileMethods} />
      </Col>
    </Row>
  );
}

export default ProfileFirstRow;

import React, { useContext, useEffect, useState } from 'react';
import Icon, { IconTypes } from 'components/Icon';
import AppModal from 'components/AppModal';
import { Row, Col } from 'react-bootstrap';
import AuthContext from 'contexts/auth';
import ValidationFormType from 'types/enum/ValidationFormType';
import { useTranslation } from 'react-i18next';
import { stageAdmission } from 'store/user/actions';
import Button from '../Button';
import './style.scss';

export type WarningButtonProps = {
  step: string;
  observationText?: string;
};

function WarningButton({ step, observationText }: WarningButtonProps) {
  const { t } = useTranslation('fields');
  const { state, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [changeButtonSize, setChangeButtonSize] = useState(false);

  function getIcon(type: string) {
    const mapIcon: Record<string, string> = {
      PROFILE: 'profileErrorModal',
      RESIDENTIAL: 'geoLocationErrorModal',
      DOCUMENTS: 'documentsErrorModal',
      BANKING: 'bankAccountErrorModal',
      DEPENDANTS: 'familyGroupErrorModal',
      ATTACHMENTS: 'documentErrorModal',
    };
    return mapIcon[type] || '';
  }

  function getStep(st: string) {
    const mapStep: Record<string, string> = {
      1: 'no seu Formulário Admissional',
      2: 'em seus Benefícios',
      3: 'no seu Exame Admissional',
    };
    return mapStep[st] || '';
  }

  useEffect(() => {
    if (state.admissionForm.name === ValidationFormType.NONE) return;

    const stageIdx = state.stageAdmission.findIndex(
      (s) => s.name === 'FORM_ADMISSION'
    );
    const stage = state.stageAdmission[stageIdx];

    const profileIdx = stage.validationProfiles.findIndex(
      (p) => p.type === (state.admissionForm.name as string)
    );
    const profile = stage.validationProfiles[profileIdx];

    if (!profile) return;

    const thisProfiles = [...stage.validationProfiles];
    if (state.admissionForm.validationResults.length === 0) {
      thisProfiles.splice(profileIdx, 1);
    } else {
      thisProfiles[profileIdx] = {
        ...profile,
        validationResults: state.admissionForm.validationResults,
      };
    }

    const thisStage = { ...stage, validationProfiles: thisProfiles };
    const thisStages = [...state.stageAdmission];
    thisStages[stageIdx] = thisStage;

    dispatch(stageAdmission(thisStages));
  }, [state.admissionForm]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const { scrollY } = window;
    if (scrollY > 5) {
      setChangeButtonSize(true);
    } else {
      setChangeButtonSize(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className={
          changeButtonSize === false
            ? 'warning-button'
            : 'warning-button small-warning-button'
        }
      >
        <span className="button-text">Aviso - Passo {`${step}`}</span>
        <Icon name="alertCircle" />
      </Button>
      <AppModal
        buttonText="Fechar"
        open={open}
        close={() => {
          setOpen(false);
        }}
        confirm={() => setOpen(false)}
        className="observation-modal"
        title="Notificação da Gestão"
      >
        <Row className="row-title">
          <Col>
            <h2>Esta etapa precisa de correções!</h2>

            <h6>
              Encontramos alguns dados incorretos {getStep(step)}. Corrija-os
              para prosseguir.
            </h6>
          </Col>
        </Row>
        {step === '1' && (
          <Row className="row-list">
            <Col>
              {state.stageAdmission?.map((stages, idx) =>
                stages.validationProfiles?.map((stage, idy) =>
                  stage.type ? (
                    <div key={`${idx.valueOf()}_${stage.type}`}>
                      <div className="title">
                        <Icon name={getIcon(stage.type) as IconTypes} />
                        <span>{t(`title.${stage.type.toLowerCase()}`)}</span>
                      </div>
                      <div className="items">
                        {stage.validationResults?.map((fields, idz) => {
                          const splits = fields.split('.');

                          if (fields.includes('dependants')) {
                            const field = splits.pop();
                            const dependantNumber = Number(
                              fields.split('.').slice(1, 2)
                            );

                            return (
                              <div
                                className="field"
                                key={`${idx.valueOf()}_${idy.valueOf()}_${idz.valueOf()}`}
                              >
                                Dependente {dependantNumber + 1} -{' '}
                                {t(`field.${field}`)}
                              </div>
                            );
                          }

                          if (fields.includes('.'))
                            return (
                              <div className="field">
                                {t(`group.${splits[0]}`)} -{' '}
                                {t(
                                  `field.${splits[0]} ${fields.split('.')[1]}`
                                )}
                              </div>
                            );

                          return (
                            <div className="field">{t(`field.${fields}`)}</div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null
                )
              )}
            </Col>
          </Row>
        )}
        <Row className="row-observation">
          <Col>
            <div className="title">
              <Icon name="observationNotificationIcon" />
              <span>Observação</span>
            </div>

            <textarea
              disabled
              className="obs-container"
              defaultValue={observationText}
            />
          </Col>
        </Row>
      </AppModal>
    </>
  );
}

export default WarningButton;

import React, { useEffect, useState } from 'react';
import DownloadFileService from 'services/downloadFileService';
import GenericModal from 'components/Forms/Shared/GenericModal';

type EntranceExamModalProps = {
  open: boolean;
  setOpen: (b: boolean) => void;
};

function EntranceExamModal({ open, setOpen }: EntranceExamModalProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState({
    icon: '',
    title: 'Realizando o download...',
    description: 'Aguarde!',
    modalTitle: '',
  });

  useEffect(() => {
    if (open) downloadMedicalExam();
  }, [open]);

  function downloadMedicalExam() {
    DownloadFileService.downloadMedicalExam()
      .then((response) => {
        setLoading(false);
        setData({
          modalTitle: 'Sucesso na operação!',
          icon: 'checkboxMarkIcon',
          title: 'Download disponibilizado com sucesso.',
          description:
            'Assim que você realizar o exame, pedimos que retorne e anexe aqui o documento escaneado para que possamos dar andamento na sua contratação.',
        });

        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });

        const downloadLink = document.createElement('a');
        downloadLink.download = 'exame-medico.pdf';
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.onclick = () => document.body.removeChild(downloadLink);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
      .catch(() => {
        setLoading(false);
        setData({
          modalTitle: 'Falha na operação!',
          icon: 'cancelIconSmaller',
          title: 'Algo deu errado',
          description: 'Tente fazer o download novamente.',
        });
      });
  }

  return (
    <GenericModal
      showFooter={!loading}
      showHeader={!loading}
      open={open}
      data={data}
      loading={loading}
      modalType="savingData"
      handleConfirm={() => {
        setOpen(false);
      }}
      close={() => {
        setOpen(false);
      }}
    />
  );
}

export default EntranceExamModal;

/* eslint-disable import/prefer-default-export */
export const fieldTexts = {
  'pt-BR': {
    'group.profile': 'Perfil do Colaborador',
    'field.photo': 'Foto',
    'field.name': 'Nome Completo',
    'field.preferredName': 'Como quer ser chamado',
    'field.email': 'E-mail Pessoal',
    'field.nationality': 'País de Nascimento',
    'field.phoneNumber': 'Telefone Residencial',
    'field.mobileNumber': 'Celular',
    'field.stateOfBirth': 'Estado de Nascimento',
    'field.countryOfBirth': 'País de Nascimento',
    'field.cityOfBirth': 'Cidade de Nascimento',
    'field.dateOfBirth': 'Data de Nascimento',
    'field.parentage': 'Filiação 1',
    'field.secondParentage': 'Filiação 2',
    'field.civilStatus': 'Estado Civil',
    'field.race': 'Raça/Cor',
    'field.sex': 'Sexo',
    'field.deficiency': 'Deficiente Físico',
    'field.schooling': 'Grau de Instrução',
    'field.educationalInstitution': 'Instituição de Ensino',
    'field.campus': 'Campus',
    'field.course': 'Curso',
    'field.yearCurrentSemester': 'Ano/Semestre Atual',
    'field.registration': 'Matrícula',
    'field.classSchedule': 'Horário das aulas',
    'field.mandatoryInternship': 'Estágio Obrigatório',
    'group.emergencyContact': 'Contato de emergência',
    'field.emergencyContact fullName': 'Nome e sobrenome ',
    'field.emergencyContact phoneNumber': 'Telefone de contato',
    'field.emergencyContact kinship': 'Grau de Parentesco',
    'group.residential': 'Dados Residenciais',
    'field.CEP': 'CEP',
    'field.streetName': 'Rua',
    'field.streetNumber': 'Número',
    'field.complement': 'Complemento',
    'field.neighborhood': 'Bairro',
    'field.state': 'Estado',
    'field.city': 'Cidade',
    'field.professionalEmail': 'E-mail Profissional',
    'group.documents': 'Preenchimento dos Documentos',
    'field.CPF': 'CPF',
    'field.PIS': 'Número do PIS',
    'field.CNPJ': 'CNPJ da Empresa',
    'field.simpleNational': 'Optante pelo Simples Nacional',
    'field.companyName': 'Razão Social da Empresa',
    'group.RG': 'RG',
    'field.RG number': 'Número',
    'field.RG expeditionDate': 'Data de Expedição',
    'field.RG emmiter': 'Órgão Emissor',
    'group.reservist': 'Certificado de Reservista',
    'field.reservist': 'Certificado de Reservista',
    'field.reservist number': 'Número',
    'field.reservist category': 'Categoria',
    'group.voteRegistration': 'Título de Eleitor',
    'field.voteRegistration number': 'Número',
    'field.voteRegistration area': 'Zona',
    'field.voteRegistration section': 'Seção',
    'group.workCard': 'Carteira de Trabalho',
    'field.workCard number': 'Número',
    'field.workCard expeditionDate': 'Data de Expedição',
    'field.workCard series': 'Série',
    'field.workCard UF': 'UF',
    'group.RNE': 'RNE',
    'field.RNE.number': 'Número',
    'field.RNE.digit': 'Dígito',
    'field.RNE.expirationDate': 'Data de Validade',
    'field.RNE.brazilianSon': 'Filhos Brasileiros',
    'field.RNE.type': 'Tipo de Visto',
    'field.RNE.marriedBrazilian': 'Casado com Brasileiro',
    'field.RNE.emmiter': 'Órgão Emissor',
    'field.RNE.entryDate': 'Data de Entrada no País',
    'field.foreignStatus': 'Condição do Estrangeiro no Brasil',
    'group.banking': 'Dados Bancários',
    'field.agency': 'Agência',
    'field.account': 'Conta',
    'field.digit': 'Dígito',
    'field.bank': 'Banco',
    'group.dependants': 'Inclusão de Dependentes',
    'field.kinship': 'Grau de Parentesco',
    'field.includedIR': 'Declarado no imposto de renda',
    'field.urlDocument': 'RG ou certidão de nascimento',
    'group.attachments': 'Documentos anexados',
    'field.RG': 'RG',
    'field.socialContract': 'Contrato Social ou Requerimento',
    'field.RNE': 'RNE',
    'field.CTPS': 'Carteira de Trabalho',
    'field.ID': 'Carteira de Identidade',
    'field.voteRegistration': 'Título de Eleitor',
    'field.registrationQualification': 'Consulta de Qualificação Cadastral',
    'field.address': 'Comprovante de Residência',
    'field.proofOfSchooling': 'Comprovante de Escolaridade',
    'field.courses': 'Certificado de Cursos Externos',
    'field.bankAccount': 'Comprovante de Dados Bancários',
    'field.certification': 'Certidão de Nascimento ou Casamento',
    'field.underAgeBirthCertification':
      'Certidão do nascimento de filho menor de 14 anos',
    'field.underAgeVaccineCertification':
      'Cartão de vacinação de filho menor de 7 anos',
    'field.underAgeSchoolAttendance':
      'Comprovante de frequência escolar do filho maior de 7 e menor de 14 anos',
    'title.profile': 'Erros no perfil do colaborador',
    'title.residential': 'Erros nos dados residenciais',
    'title.documents': 'Erros nos documentos',
    'title.banking': 'Erros nos dados bancários',
    dependant: 'Dependente',
    'title.dependants': 'Erros nos dados de dependentes',
    'title.attachments': 'Documentos solicitados novamente',
    'field.dependants name': 'Nome Completo',
    'field.dependants includedIR': 'Declarado no imposto de renda',
    'field.dependants urlDocument': 'RG ou certidão de nascimento',
    'field.dependants CPF': 'CPF',
    'field.dependants dateOfBirth': 'Data de Nascimento',
    'field.dependants kinship': 'Grau de Parentesco',
  },
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Select from 'react-select';
import { Controller, UseFormReturn } from 'react-hook-form';
import { customStylesForBanking } from 'styles/select/customStylesForBanking';
import { customStyles } from 'styles/select/customStyles';
import AuthContext from 'contexts/auth';
import FormAnswerType from 'types/enum/FormAnswerType';
import { banks, Bank } from './Banks';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../../FormUtils';

function BankingSelect({
  type,
  bankingDataMethods,
  isDisabled,
}: {
  type: FormAnswerType;
  bankingDataMethods: UseFormReturn<any>;
  isDisabled: boolean;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = bankingDataMethods.formState;
  const allowedFormAnswerTypes = [
    FormAnswerType.BANKING_PJ,
    FormAnswerType.BANKING_INTERNSHIP,
  ];

  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'bank',
    admissionForm,
    bankingDataMethods
  );

  const formatOptionLabel = (bank: Bank) => (
    <>
      {bank.code} - {bank.label}
    </>
  );
  const isTypeBankingPJ = type === FormAnswerType.BANKING_PJ;

  return (
    <label id="bank" className="form-field" htmlFor="bank">
      {allowedFormAnswerTypes.find((v) => v === type) && (
        <>
          <span className="label">{`Banco${isTypeBankingPJ ? '*' : ''}`}</span>
          <Controller
            name="bank"
            control={bankingDataMethods.control}
            rules={{ required: isTypeBankingPJ }}
            render={({ field: { onChange, value, name, ref, onBlur } }) => (
              <Select
                placeholder=""
                isDisabled={isDisabled}
                styles={customStyles(hasAnyError, shouldDisable)}
                formatOptionLabel={formatOptionLabel}
                options={banks}
                getOptionLabel={(bank: Bank) => bank.code}
                name={name}
                value={banks.find((r) => r.value === value)}
                onChange={(selectedOption: any) => {
                  onChange(selectedOption.value);
                }}
                onBlur={onBlur}
              />
            )}
          />

          {errors && errors.bank?.type === 'required' && (
            <span className="error-text">Esse campo é obrigatório.</span>
          )}
          {hasCustomError && (
            <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
          )}
        </>
      )}
    </label>
  );
}

export default BankingSelect;

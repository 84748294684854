/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import StepsController from 'components/StateController/StepsController';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileMobileNumber({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'mobileNumber',
    admissionForm,
    profileMethods
  );

  return (
    <label id="mobileNumber" className="form-field" htmlFor="mobileNumber">
      <span className="label">Celular*</span>
      <Controller
        control={profileMethods.control}
        name="mobileNumber"
        rules={{ required: true, minLength: 14, maxLength: 15 }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <input
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            onChange={(e) => {
              e.target.value.length === 15
                ? onChange(masks.mobileEightNumbers(e.target.value))
                : onChange(masks.mobileNineNumbers(e.target.value));
            }}
            value={profileMethods.getValues('mobileNumber') || ''}
            onBlur={onBlur}
            minLength={14}
            maxLength={15}
            readOnly={shouldDisable}
          />
        )}
      />
      {errors && errors.mobileNumber?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {errors && errors.mobileNumber?.type === 'minLength' && (
        <span className="error-text">
          O telefone celular é inválido, por favor revise.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileMobileNumber;

/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfilePreferredName({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const maxLength = 50;
  const { t } = useTranslation('admission');
  const { errors } = profileMethods.formState;
  const [open, setOpen] = useState(false);
  const target = useRef(null);

  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'preferredName',
    admissionForm,
    profileMethods
  );

  return (
    <label id="preferredName" className="form-field" htmlFor="preferredName">
      <span className="label">Como quer ser chamado</span>
      <Controller
        rules={{ maxLength }}
        control={profileMethods.control}
        name="preferredName"
        render={({ field: { onChange, onBlur } }) => (
          <>
            <input
              onMouseLeave={() => setOpen(false)}
              onClick={() => setOpen(true)}
              ref={target}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(masks.onlyLetters(e.target.value));
              }}
              value={profileMethods.getValues('preferredName') || ''}
              type="text"
              className={hasAnyError ? 'field -full-error' : 'field -full'}
              readOnly={shouldDisable}
            />
            <Overlay target={target.current} show={open} placement="top-end">
              {(props) => (
                <Tooltip id="overlay-tooltip" {...props}>
                  {t('Preferred Name Info')}
                </Tooltip>
              )}
            </Overlay>
          </>
        )}
      />
      {errors && errors.preferredName?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}

      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfilePreferredName;

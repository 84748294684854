import { FormCategoriesBenefits } from 'types/common/FormCategories';

/* eslint-disable import/prefer-default-export */
export const BenefitsSteps: FormCategoriesBenefits = [
  {
    name: 'chooseBenefits',
    title: 'Distribuição da cesta de benefícios',
  },
  {
    name: 'fixedBenefits',
    title: 'Mais benefícios',
  },
];

import React, { useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import AuthContext from 'contexts/auth';
import { updateAdmissionForm } from 'store/user/actions';
import ResidentialAnswersResponse from 'types/models/ResidentialAnswersResponse';
import ResidentialData from 'components/Forms/Shared/ResidentialData/ResidentialData';
import {
  checkSaveButtonDisabled,
  formChanged,
} from 'components/Forms/Shared/FormUtils';
import { formStatus } from 'types/common/FormStatus';
import ValidationFormType from 'types/enum/ValidationFormType';
import * as FormModel from '../models';

function Residential({
  handleNext,
  handlePrevious,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  const formMethods: UseFormReturn<FormModel.ResidentialData> =
    useForm<FormModel.ResidentialData>({
      mode: 'onBlur',
      defaultValues: {
        CEP: '',
        streetNumber: '',
      },
    });

  async function loadAnswersResidential(): Promise<void> {
    return FormAnswerService.getAllAnswers<ResidentialAnswersResponse>(
      'RESIDENTIAL_DATA'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;
      if (data) {
        formMethods.reset({
          CEP: data.CEP || '',
          city: data.city || '',
          neighborhood: data.neighborhood || '',
          state: data.state || '',
          streetName: data.streetName || '',
          streetNumber: data.streetNumber || '',
          complement: data.complement || '',
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.RESIDENTIAL,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  useEffect(() => {
    loadAnswersResidential();
  }, []);

  function handleDisable() {
    return checkSaveButtonDisabled(formMethods, admissionForm) || loading;
  }

  return (
    <ResidentialData
      formMethods={formMethods}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      handleDisableNext={handleDisable}
      formChanged={formChanged}
    />
  );
}

export default Residential;

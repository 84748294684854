import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import AuthContext from 'contexts/auth';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DependantService from 'services/dependantsService';
import DependantsAnswersResponse, {
  DependantAnswersResponse,
} from 'types/models/DependantsAnswersResponse';
import './styles.scss';
import DependantData from './DependantData';
import ConfirmationBackModal from '../ConfirmationModal/ConfirmationBackModal';
import GenericModal from '../GenericModal';
import { isErrorStatus, isPendingStatus, isValidStatus } from '../FormUtils';

function DependantsData({
  dependantsDataMethods,
  onclick,
  handlePrevious,
  handleNext,
  handleDisableNext,
  formChanged,
}: {
  dependantsDataMethods: UseFormReturn<any>;
  onclick?: (formData: any) => void;
  handlePrevious?: () => void;
  handleNext: () => void;
  handleDisableNext?: () => boolean;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const { t } = useTranslation('dependants');
  const [excludeActionIndex, setExcludeActionIndex] = useState(-1);
  const { control } = dependantsDataMethods;
  const [modalData, setModalData] = useState({
    status: 'failure',
    icon: 'cancelIcon',
    title: 'Algo deu errado',
    description: 'Por favor, tente novamente em alguns instantes.',
    modalTitle: '',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dependants',
  });

  const checkedIncludeValue = (check: boolean) => {
    const checked = isNotIncludeDependantsChecked();
    if (checked && !check) {
      append({ includedIR: false, CPF: '', dateOfBirth: '' });
    } else if (isStartedForm()) {
      setOpenModal(true);
      setModalData({
        status: 'delete',
        modalTitle: 'Confirmação de exclusão',
        icon: 'deleteIcon',
        title: 'Você deseja realmente confirmar a exclusão?',
        description: 'Todos os dados de dependentes serão removidos.',
      });
    } else {
      dependantsDataMethods.setValue('dependants', []);
    }

    dependantsDataMethods.setValue('includeDependants', !check, {
      shouldValidate: true,
    });
  };

  const isNotIncludeDependantsChecked = (): boolean => {
    const check = dependantsDataMethods.getValues('includeDependants');
    return !check;
  };

  const hasDependants = (): boolean =>
    dependantsDataMethods.getValues('dependants').length > 0;

  const isStartedForm = (): boolean => {
    const values = dependantsDataMethods.getValues().dependants;
    const array = values.filter(
      (value: DependantAnswersResponse) =>
        value.CPF ||
        value.dateOfBirth ||
        value.name ||
        value.kinship ||
        value.id
    );
    return array.length > 0;
  };

  const checkAction = () => {
    dependantsDataMethods.setValue('dependants', [], {
      shouldValidate: true,
    });
  };

  const removeDependantById = async (index: number) => {
    const selectedDependant =
      dependantsDataMethods.getValues().dependants[index];
    if (selectedDependant.id) {
      await DependantService.removeDependantById(selectedDependant.id);
    }
  };

  const handleConfirm = async (
    dependantsRequest: DependantsAnswersResponse
  ) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      status: 'loading',
      icon: '',
      title: 'Salvando seus dados...',
      description: 'Aguarde!',
      modalTitle: '',
    });
    await DependantService.saveDependants(dependantsRequest)
      .then((response) => {
        setLoading(false);
        handleNext();
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          status: 'failure',
          modalTitle: 'Falha na operação!',
          icon: 'cancelIcon',
          title: 'Revise seus dados.',
          description:
            'Algo deu errado ao tentar avançar para o próximo passo.',
        });
      });
  };

  const removeAll = async () => {
    const dependants = [...dependantsDataMethods.getValues().dependants];

    if (dependants.find((param) => param.id !== null)) {
      await DependantService.removeAllDependants();
    }

    openCheck ? checkAction() : remove(excludeActionIndex);
    setOpenDelete(false);
    setOpenCheck(false);
  };

  const isDisabled = (): boolean => {
    if (!handleDisableNext) return false;
    if (isNotIncludeDependantsChecked() && !hasDependants()) return false;
    return handleDisableNext();
  };

  const addNewDependant = () => {
    if (fields.length < 20) {
      append({ includedIR: false, CPF: '', dateOfBirth: '' });
    }
  };

  const handleMinus = (index: number) => {
    const selectedForm = dependantsDataMethods.getValues().dependants[index];
    setExcludeActionIndex(index);

    if (
      selectedForm &&
      (selectedForm.CPF ||
        selectedForm.dateOfBirth ||
        selectedForm.name ||
        selectedForm.kinship ||
        selectedForm.id)
    ) {
      setOpenDelete(true);
      setModalData({
        status: 'delete',
        modalTitle: 'Confirmação de exclusão',
        icon: 'deleteIcon',
        title: 'Você deseja realmente confirmar a exclusão?',
        description: 'Todos os dados de dependentes serão removidos.',
      });
    } else {
      remove(index);
    }
  };

  useEffect(() => {
    setExcludeActionIndex(-1);
  }, [fields]);

  const verifyGoBack = () => {
    if (formChanged(dependantsDataMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious?.();
  };

  function handleButton() {
    if (isErrorStatus(admissionForm.status)) {
      setLoading(false);
      setOpenModal(true);
      setModalData({
        status: 'confirmEditing',
        icon: 'triangleWarning',
        title:
          'Os dados serão enviados e não poderão mais ser alterados. Deseja continuar?',
        description: '',
        modalTitle: 'Atenção!',
      });
    } else {
      dependantsDataMethods.handleSubmit(handleConfirm)();
    }
  }

  return (
    <Container className="dependants-account">
      <Row className="header-form-info">
        <Col>
          <h1>Inclusão de dependentes</h1>
          <p>
            Informe os dados de seus dependentes para serem incluídos nos seus
            benefícios. Você pode adicionar até 20 dependentes.{' '}
            <span>*Obrigatório</span>
          </p>
        </Col>
      </Row>
      <Row className="checkbox-account">
        <Col className="d-flex checkbox">
          <input
            type="hidden"
            {...dependantsDataMethods.register('includeDependants')}
          />
          <Checkbox
            checkedValue={checkedIncludeValue}
            isChecked={isNotIncludeDependantsChecked()}
            disabled={
              isPendingStatus(admissionForm.status) ||
              isErrorStatus(admissionForm.status) ||
              isValidStatus(admissionForm.status)
            }
          />
          <span className="text-checkbox">{t('No Dependants')}</span>
        </Col>
      </Row>
      <div className="field-container">
        <DependantData
          fields={fields}
          dependantsDataMethods={dependantsDataMethods}
          setOpenModal={setOpenModal}
          addNewDependant={addNewDependant}
          handleMinus={handleMinus}
          setModalData={setModalData}
        />
      </div>
      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <Button onClick={verifyGoBack}>Voltar</Button>
          <Button
            disabled={isDisabled()}
            className="ms-3"
            onClick={() => handleButton()}
          >
            Salvar e continuar
          </Button>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />

      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal || openCheck || openDelete}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={() => {
          setOpenModal(false);

          if (modalData.status === 'confirmEditing')
            dependantsDataMethods.handleSubmit(handleConfirm)();

          if (modalData.status === 'delete') {
            if (openDelete) {
              removeDependantById(excludeActionIndex);
              openCheck ? checkAction() : remove(excludeActionIndex);
              setOpenDelete(false);
              setOpenCheck(false);
            } else {
              removeAll();
            }
          }
        }}
        close={() => {
          setOpenModal(false);
          if (modalData.icon === 'deleteIcon') {
            setOpenDelete(false);
            setOpenCheck(false);
            dependantsDataMethods.setValue('includeDependants', true);
          }
        }}
      />
    </Container>
  );
}

export default DependantsData;

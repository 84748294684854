/* eslint-disable import/prefer-default-export */
export const ExamTexts = {
  'pt-BR': {
    'Main Title': 'Exame Admissional',
    'Main Description':
      'O RH marcará o seu exame na clínica mais próxima de você, após isso, faça o upload do resultado.',
    'Go NextPage': 'Ir para apresente-se',
    'Status inactive': 'Não iniciado',
    'Status active': 'Etapa iniciada',
    'Status pending': 'Em validação pelo RH',
    'Status complete': 'Etapa concluída',
    'Status error': 'Há erros nesta etapa',
    'Back Home': 'Voltar para home',
  },
  en: {},
};

import React, { useContext, useEffect, useState } from 'react';
import Checkbox from 'components/Checkbox';
import BankingAccountItau from 'components/Forms/Shared/Banking/BankingAccountItau';
import BankingNoAccount from 'components/Forms/Shared/Banking/BankingNoAccount';
import {
  checkSaveButtonDisabled,
  isErrorStatus,
  isPendingStatus,
  isValidStatus,
} from 'components/Forms/Shared/FormUtils';
import AuthContext from 'contexts/auth';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import FormAnswerService from 'services/formAnswerService';
import { updateAdmissionForm } from 'store/user/actions';
import FormAnswerType from 'types/enum/FormAnswerType';
import BankingAnswersResponse from 'types/models/BankingAnswersResponse';
import './styles.scss';
import { formStatus } from 'types/common/FormStatus';
import Button from 'components/Button';
import ConfirmationBackModal from 'components/Forms/Shared/ConfirmationModal/ConfirmationBackModal';
import GenericModal from 'components/Forms/Shared/GenericModal';
import ValidationFormType from 'types/enum/ValidationFormType';
import { useTranslation } from 'react-i18next';
import * as FormModel from '../models';

function Banking({
  handleNext,
  handlePrevious,
  formChanged,
}: {
  handleNext: () => void;
  handlePrevious: () => void;
  formChanged: (forms: UseFormReturn<any, any>) => boolean;
}) {
  const {
    dispatch,
    state: { admissionForm },
  } = useContext(AuthContext);
  const { t } = useTranslation('banking');
  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({
    icon: 'cancelIcon',
    title: t('something.wrong.title'),
    description: t('try.again.please'),
    modalTitle: '',
  });

  const bankingDataMethods: UseFormReturn<FormModel.Banking> =
    useForm<FormModel.Banking>({
      mode: 'onBlur',
      defaultValues: {
        hasAccount: true,
      },
    });

  const hasAccount = bankingDataMethods.watch('hasAccount');

  useEffect(() => {
    bankingDataMethods.control.register('hasAccount');
    loadAnswersBankingCLT();
  }, []);

  async function loadAnswersBankingCLT(): Promise<void> {
    return FormAnswerService.getAllAnswers<BankingAnswersResponse>(
      'BANKING_CLT'
    ).then((response) => {
      const { data, validationResults, validationStatus } = response.data;

      if (data) {
        bankingDataMethods.reset({
          hasAccount: data.hasAccount,
          account: data.account || '',
          digit: data.digit || '',
          agency: data.agency || '',
          bank: data.bank || '',
        });
      }

      dispatch(
        updateAdmissionForm({
          name: ValidationFormType.BANKING,
          status: validationStatus as formStatus,
          isEditing: !!data,
          validationResults: validationResults || [],
        })
      );

      setLoading(false);
    });
  }

  const handleConfirm = async (dataRequest: FormModel.Banking) => {
    setLoading(true);
    setOpenModal(true);
    setModalData({
      icon: '',
      title: t('saving.data'),
      description: t('wait'),
      modalTitle: '',
    });
    await FormAnswerService.saveForm({
      dataRequest,
      type: FormAnswerType.BANKING_CLT,
    })
      .then(() => {
        setLoading(false);
        handleNext();
      })
      .catch(() => {
        setLoading(false);
        setModalData({
          modalTitle: t('operation.failure'),
          icon: 'cancelIcon',
          title: t('review.data.title'),
          description: t('something.wrong'),
        });
      });
  };

  const isNotHasAccountChecked = (): boolean => {
    const check = bankingDataMethods.getValues('hasAccount');
    return !check;
  };

  const checkedIncludeValue = (check: boolean) => {
    clearFields(check);
  };

  function clearFields(check: boolean) {
    bankingDataMethods.setValue('agency', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('account', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('digit', '', {
      shouldValidate: true,
    });
    bankingDataMethods.setValue('hasAccount', !check, {
      shouldValidate: true,
    });
  }

  function handleDisable() {
    return (
      (checkSaveButtonDisabled(bankingDataMethods, admissionForm) &&
        !isNotHasAccountChecked()) ||
      loading
    );
  }

  const verifyGoBack = () => {
    if (formChanged(bankingDataMethods)) {
      setOpenBackModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    setOpenBackModal(false);
    handlePrevious();
  };

  function handleButton() {
    return isErrorStatus(admissionForm.status)
      ? () => {
          setLoading(false);
          setOpenModal(true);
          setModalData({
            icon: 'triangleWarning',
            title: t('data.sent.cannot.change.want.continue'),
            description: '',
            modalTitle: t('attention'),
          });
        }
      : bankingDataMethods.handleSubmit(handleConfirm);
  }

  return (
    <Container className="banking-container">
      <Row className="header-form-info">
        <Col>
          <h1>{t('banking.data.title')}</h1>
          <p>
            {`${t('inform.bank.details')} `}
            <span>{t('mandatory.bank')}</span>
          </p>
        </Col>
      </Row>

      {hasAccount && (
        <BankingAccountItau bankingDataMethods={bankingDataMethods} />
      )}

      <Row className="checkbox-account">
        <Col className="d-flex checkbox">
          <Checkbox
            checkedValue={checkedIncludeValue}
            isChecked={isNotHasAccountChecked()}
            disabled={
              isPendingStatus(admissionForm.status) ||
              isErrorStatus(admissionForm.status) ||
              isValidStatus(admissionForm.status)
            }
          />
          <span className="text-checkbox">{t('no.account.itau')} </span>
        </Col>
      </Row>

      {!hasAccount && <BankingNoAccount isFormsValid />}

      <Row className="row-form-buttons">
        <Col className="d-flex justify-content-end">
          <Button onClick={verifyGoBack}>{t('back.button')}</Button>
          <Button
            disabled={handleDisable()}
            className="ms-3"
            onClick={handleButton()}
          >
            {t('save.continue.button')}
          </Button>
        </Col>
      </Row>

      <ConfirmationBackModal
        open={openBackModal}
        close={() => {
          handleGoBack();
        }}
        handleContinue={() => {
          setOpenBackModal(false);
        }}
      />

      <GenericModal
        showFooter={!loading}
        showHeader={!loading}
        open={openModal}
        data={modalData}
        loading={loading}
        modalType="savingData"
        handleConfirm={
          modalData.modalTitle === 'Falha na operação!'
            ? () => setOpenModal(false)
            : bankingDataMethods.handleSubmit(handleConfirm)
        }
        close={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
}

export default Banking;

import React from 'react';
import AppModal from 'components/AppModal';
import { Col, Row } from 'react-bootstrap';
import Icon, { IconTypes } from 'components/Icon';

type modalProps = {
  open: boolean;
  loading?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
  data?: {
    icon?: string;
    title?: string;
    modalTitle?: string;
    description?: string;
  };
  close: () => void;
  handleConfirm: () => void;
  modalType?: 'confirmation' | 'savingData';
};

function GenericModal({
  data,
  open,
  close,
  loading,
  modalType,
  showHeader,
  showFooter,
  handleConfirm,
}: modalProps) {
  return (
    <AppModal
      open={open}
      title={data?.modalTitle}
      showHeader={showHeader}
      showFooter={showFooter}
      confirm={() => {
        handleConfirm();
      }}
      close={() => {
        close();
      }}
    >
      {modalType === 'savingData' && (
        <Row className="modal-with-loading">
          {loading ? (
            <div className="loading-container">
              <div className="loading-image" />
            </div>
          ) : (
            <Row className="icon">
              <Icon name={data?.icon as IconTypes} />
            </Row>
          )}

          <Row className="text pe-0">
            <h2 className="title pe-0">{data?.title}</h2>
            <h4
              className={
                data?.title !== ''
                  ? 'description pe-0'
                  : 'description pe-0 mt-0'
              }
            >
              {data?.description}
            </h4>
          </Row>
        </Row>
      )}

      {modalType === 'confirmation' && (
        <div className="modal-with-confirmation">
          <Row className="icon">
            <Col>
              <Icon name={data?.icon as IconTypes} />
            </Col>
          </Row>
          <Row className="text">
            <Col>
              <h2 className="title pe-0">{data?.title}</h2>
              <h4
                className={
                  data?.title !== ''
                    ? 'description pe-0'
                    : 'description pe-0 mt-0'
                }
              >
                {data?.description}
              </h4>
            </Col>
          </Row>
        </div>
      )}
    </AppModal>
  );
}

export default GenericModal;

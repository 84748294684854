/* eslint-disable jsx-a11y/label-has-associated-control */
import * as FormModel from 'components/Forms/FormCLT/models';
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import masks from 'utils/MasksUtils';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

function ProfileEmergencyContactKinship({
  profileMethods,
}: {
  profileMethods: UseFormReturn<FormModel.Profile>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const maxLength = 50;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'emergencyContact.kinship',
    admissionForm,
    profileMethods
  );

  return (
    <label
      id="emergencyContact.kinship"
      className="form-field"
      htmlFor="emergencyContact.kinship"
    >
      <span className="label">Grau de Parentesco*</span>
      <Controller
        rules={{ required: true, maxLength }}
        control={profileMethods.control}
        name="emergencyContact.kinship"
        render={({ field: { onChange, onBlur } }) => (
          <input
            onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
            onBlur={onBlur}
            value={profileMethods.getValues('emergencyContact.kinship') || ''}
            type="text"
            className={hasAnyError ? 'field -full-error' : 'field -full'}
            readOnly={shouldDisable}
          />
        )}
      />
      {errors && errors.emergencyContact?.kinship?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {errors && errors.emergencyContact?.kinship?.type === 'maxLength' && (
        <span className="error-text">
          Precisa ter até {maxLength} caracteres.
        </span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileEmergencyContactKinship;

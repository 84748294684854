import React, { useEffect, useState } from 'react';
import './styles.scss';
import Icon from 'components/Icon';
import Benefit from 'types/models/Benefit';

export type BenefitSelectProps = {
  name: string;
  benefit: Benefit;
  handleClick: (benefit: Benefit) => void;
};

function BenefitSelect({ name, benefit, handleClick }: BenefitSelectProps) {
  const [disable, setDisable] = useState(false);
  const checked = benefit.selected;

  useEffect(() => {
    const selectedFlag = benefit.mandatory;

    if (selectedFlag) {
      setDisable(true);
    }
  }, [name]);

  const handleSelect = () => {
    handleClick(benefit);
  };

  return (
    <div
      className={`benefit-select ${checked ? 'checked' : ''}`}
      onClick={() => {
        if (!disable) handleSelect();
      }}
      onKeyDown={(e) => {
        if (e.code === 'Space' || e.key === 'Enter') {
          if (!disable) handleSelect();
        }
        if (e.key !== 'Tab') {
          e.preventDefault();
        }
      }}
      role="tab"
      tabIndex={0}
    >
      <div className={`column-left ${checked ? 'checked' : ''}`} />
      <div className="column-center">
        <button
          tabIndex={-1}
          disabled={disable}
          id="checkbox"
          type="button"
          className={`checkbox ${checked ? 'checkbox-checked' : ''}`}
        >
          <Icon name="checkIcon" />
        </button>
      </div>
      <div className="column-right">
        <span className="select-title">{name}</span>
      </div>
    </div>
  );
}

export default BenefitSelect;

/* eslint-disable import/prefer-default-export */
export const DependantsTexts = {
  'pt-BR': {
    'No Dependants': 'Não desejo incluir dependentes nos meus benefícios.',
    'Kinship Title': 'Grau de Parentesco*',
    'Kinship Warning': `Só é permitida a inclusão de cônjuge e filhos nos seus planos de assistência médica e odontológica. Você poderá incluí-los na etapa Benefícios.`,
    'Kinship Tax Warning':
      'Declaro para todos os fins e efeitos, especialmente para o abatimento do IMPOSTO DE RENDA RETIDO NA FONTE, sob as penas da Lei e responsabilidade criminal, que são meus dependentes as pessoas indicadas e que não fazem parte da Declaração de Dependentes na Fonte Pagadora do meu cônjuge.',
    'Kinship Tax Label': 'Declarado no imposto de renda',
  },
  en: {},
};

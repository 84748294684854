import React, { useContext } from 'react';
import FormCLT from 'components/Forms/FormCLT';
import './styles.scss';
import FormInternship from 'components/Forms/FormInternship';
import FormForeignCLT from 'components/Forms/FormForeignCLT';
import FormPJ from 'components/Forms/FormPJ/FormPJ';
import AuthContext from 'contexts/auth';
import { ContractType } from 'types/common/ContractType';
import WarningButton from 'components/Button/WarningButton';
import StepsHeader from 'components/Header/StepsHeader';

function AdmissionForm() {
  const { state } = useContext(AuthContext);

  function renderForm(contract: ContractType) {
    const formMap: Record<ContractType, JSX.Element> = {
      CLT: <FormCLT />,
      PJ: <FormPJ />,
      FOREIGN_CLT: <FormForeignCLT />,
      INTERNSHIP: <FormInternship />,
    };

    return formMap[contract] || formMap.CLT;
  }

  function showWarningButton() {
    const stage = state.stageAdmission.find(
      (e) => e.name === 'FORM_ADMISSION' && e.status === 'ERROR'
    );

    const text = stage?.observation;

    return text || '';
  }

  function chooseRoute() {
    if (state.contractType === 'PJ') return 'about';
    return 'beneficios';
  }

  return (
    <div className="admission-container">
      <StepsHeader route={chooseRoute()} type="admission" />
      {showWarningButton().length > 0 && (
        <WarningButton step="1" observationText={showWarningButton()} />
      )}

      {renderForm(state.contractType as ContractType)}
    </div>
  );
}

export default AdmissionForm;

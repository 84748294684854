/* eslint-disable jsx-a11y/label-has-associated-control */
import AuthContext from 'contexts/auth';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { customStyles } from 'styles/select/customStyles';
import { CUSTOM_ERROR_MESSAGE, getFormValidationProps } from '../FormUtils';

const educationLevel = [
  { value: 'Fundamental Completo', label: 'Fundamental Completo' },
  { value: 'Superior Incompleto', label: 'Superior Incompleto' },
  { value: 'Superior Completo', label: 'Superior Completo' },
  { value: 'Pós-graduação', label: 'Pós-graduação' },
  { value: 'Mestrado', label: 'Mestrado' },
];

function ProfileEducationalDegree({
  profileMethods,
}: {
  profileMethods: UseFormReturn<any>;
}) {
  const {
    state: { admissionForm },
  } = React.useContext(AuthContext);

  const { errors } = profileMethods.formState;
  const { shouldDisable, hasCustomError, hasAnyError } = getFormValidationProps(
    'schooling',
    admissionForm,
    profileMethods
  );

  return (
    <label id="educationalDegree" className="form-field" htmlFor="schooling">
      <span className="label">Grau de Instrução*</span>
      <Controller
        name="schooling"
        control={profileMethods.control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name, ref, onBlur } }) => (
          <Select
            placeholder=""
            styles={customStyles(hasAnyError, shouldDisable)}
            options={educationLevel}
            name={name}
            value={educationLevel.find((r) => r.value === value)}
            onChange={(selectedOption: any) => {
              onChange(selectedOption.value);
            }}
            onBlur={onBlur}
            isSearchable={!shouldDisable}
            menuIsOpen={shouldDisable ? false : undefined}
          />
        )}
      />
      {errors && errors.schooling?.type === 'required' && (
        <span className="error-text">Esse campo é obrigatório.</span>
      )}
      {hasCustomError && (
        <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
      )}
    </label>
  );
}

export default ProfileEducationalDegree;

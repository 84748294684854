import { StylesConfig } from 'react-select';

/* eslint-disable import/prefer-default-export */
export const customStylesForDocs = (
  hasError: any,
  wrap: boolean = false
): StylesConfig<any> => ({
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#7C7C80',
    padding: 10,
    backgroundColor: state.isFocused ? 'rgba(18, 18, 18, 0.08)' : 'white',
  }),

  control: () => ({
    width: '100%',
    height: '40px',
    border: hasError ? '1px solid #e25c5a' : '1px solid #e5e5e5',
    borderRadius: '8px',
    display: 'flex',
    paddingRight: 10,
    justifyContent: 'space-between',
    caretColor: 'transparent',

    '&:hover': {
      border: '1px solid #f58220',
    },
  }),

  singleValue: (provided: any, state: any) => ({
    color: '#7C7C80',
    whiteSpace: provided.whiteSpace,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',

    '@media (min-width: 1920px)': {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '120%',
      width: '100%',
    },
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#f58220',
    paddingTop: 10,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    '&:hover': {
      color: '#f58220',
    },
  }),

  indicatorSeparator: (provided: any, state: any) => ({
    background: 'none',
  }),

  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '8px',

    '@media (min-width: 1920px)': {
      ...provided,
      minWidth: '83%',
      width: '100%',
      fontWeight: '400',
      fontSize: '16px',
      whiteSpace: wrap ? 'normal' : 'noWrap',
    },
  }),

  valueContainer: (provided: any, state: any) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    width: '93%',

    '@media (min-width: 1920px)': {
      width: '97.5%',
    },
  }),
});

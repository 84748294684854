import axios from 'axios';

/* eslint-disable import/prefer-default-export */
const BASE_URL = 'https://servicodados.ibge.gov.br/api/v1';

export const fetchStates = () => {
  const url = `${BASE_URL}/localidades/estados`;

  return axios.get(url);
};

export const fetchCitiesForState = (state: any) => {
  const urlCity = `${BASE_URL}/localidades/estados/${state}/municipios`;

  return axios.get(urlCity);
};

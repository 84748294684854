import api from 'configs/api';

async function downloadBankLetter() {
  return api.get('/bank/account', {
    headers: {
      Accept: 'application/octet-stream',
    },
    responseType: 'blob',
  });
}

async function downloadMedicalExam() {
  return api.get('/medicalExam/file', {
    headers: {
      Accept: 'application/octet-stream',
    },
    responseType: 'blob',
  });
}

const DownloadFileService = { downloadBankLetter, downloadMedicalExam };

export default DownloadFileService;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { HOME_PRESENTATION } from 'constants/homeCards';
import './style.scss';
import Icon from 'components/Icon';
import Container from 'components/Container';

function HomeInfo() {
  const { t } = useTranslation('home');

  return (
    <section className="home-cards-container">
      <Container className="home-cards">
        <hr className="home-cards-separator" />
        <h1 className="card-title">Conheça a Iteris</h1>
        <h2 className="card-description">
          Saiba um pouco mais sobre a gente antes de começar as atividades
        </h2>
        <div className="cards-slider">
          {HOME_PRESENTATION.map((card) => (
            <article
              key={card.title}
              className="card-container"
              onClick={() => {
                window.open(card.url, '_blank');
              }}
              role="presentation"
            >
              <span className="link-icon">
                <Icon name="openBlank" />
              </span>
              <h4 className="link-title">{t(card.title)}</h4>
            </article>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default HomeInfo;
